import { styling } from '@kitted/design-system';
import { ToolTypeValues } from '@kitted/models';

import { CardSubType } from '../types';

export const getToolCardStyle = (subType: CardSubType | undefined) =>
  ({
    '--cardSlugTypeColor':
      styling.colors.context.card.footerBackgrounds[subType as ToolTypeValues],
  } as React.CSSProperties);
