import { z } from 'zod';

import { KittedModelBaseSchema } from '../../../base';

export const CustomerModelSchema = KittedModelBaseSchema.merge(
  z.object({
    email: z.string().email(),

    firstName: z.string(),
    lastName: z.string(),
  })
);

export type CustomerModel = z.infer<typeof CustomerModelSchema>;
