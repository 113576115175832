import { z } from 'zod';

import { KittedModelTypeEnum } from '../../../base';
import { notBoth } from '../../../zodHelpers';
import { CMSVideoResourceUnloadedModelSchema } from '../../contentful-service';
import { RvidSlugSchema } from '../../slug-service';
import { ResourceType } from '../Enum';
import {
  ResourceBaseLoadedModelSchema,
  ResourceBaseSaveModelSchema,
  ResourceBaseUnloadedModelSchema,
} from '../ResourceBaseModel';

export const VideoResourceBaseModelSchema = z.object({
  slug: RvidSlugSchema,

  resourceType: z.literal(ResourceType.Video),
  kittedModelType: z.literal(KittedModelTypeEnum.ResourceVideoResource),

  videoUrl: z.string().url(),
  description: z.string().optional(),

  cmsSrcMessage: CMSVideoResourceUnloadedModelSchema.optional(),
});

export const VideoResourceLoadedModelSchema =
  ResourceBaseLoadedModelSchema.merge(VideoResourceBaseModelSchema);
export const VideoResourceUnloadedModelSchema =
  ResourceBaseUnloadedModelSchema.merge(VideoResourceBaseModelSchema);
export const VideoResourceSaveModelSchema =
  VideoResourceUnloadedModelSchema.merge(ResourceBaseSaveModelSchema)
    .merge(
      z.object({
        slug: VideoResourceUnloadedModelSchema.shape.slug.optional(),
        keyImageBlobId: z.string().optional().nullable(),

        // videoUrl: VideoResourceUnloadedModelSchema.shape.videoUrl.optional(),
      })
    )
    .superRefine(notBoth('keyImageBlobId', 'keyImageImageAsset'));

export type VideoResourceLoadedModel = z.infer<
  typeof VideoResourceLoadedModelSchema
>;
export type VideoResourceUnloadedModel = z.infer<
  typeof VideoResourceUnloadedModelSchema
>;
export type VideoResourceSaveModel = z.infer<
  typeof VideoResourceSaveModelSchema
>;

export type VideoResourceModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? VideoResourceLoadedModel : VideoResourceUnloadedModel;
