import { z } from 'zod';

import {
  ContentBlockTextSaveModelSchema,
  ContentBlockVideoSaveModelSchema,
} from '../../x-service';

export const AnyFlowBlockSaveModelSchema = z.union([
  ContentBlockTextSaveModelSchema,
  ContentBlockVideoSaveModelSchema,
]);

export type AnyFlowBlockSaveModel = z.infer<typeof AnyFlowBlockSaveModelSchema>;
