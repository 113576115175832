import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import atlasGroteskBoldWoff from '../../assets/fonts/atlasgrotesk-bold-webfont.woff';
import atlasGroteskBoldWoff2 from '../../assets/fonts/atlasgrotesk-bold-webfont.woff2';
import atlasGroteskLightWoff from '../../assets/fonts/atlasgrotesk-light-webfont.woff';
import atlasGroteskLightWoff2 from '../../assets/fonts/atlasgrotesk-light-webfont.woff2';
import atlasGroteskMediumWoff from '../../assets/fonts/atlasgrotesk-medium-webfont.woff';
import atlasGroteskMediumWoff2 from '../../assets/fonts/atlasgrotesk-medium-webfont.woff2';
import atlasGroteskRegularWoff from '../../assets/fonts/atlasgrotesk-regular-webfont.woff';
import atlasGroteskRegularWoff2 from '../../assets/fonts/atlasgrotesk-regular-webfont.woff2';

const styles = createUseStyles({
  '@font-face': [
    {
      fontFamily: 'Atlas Grotesk',
      src: `url(${atlasGroteskBoldWoff2}) format('woff2'),
           url(${atlasGroteskBoldWoff}) format('woff')`,
      fontWeight: 700,
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Atlas Grotesk',
      src: `url(${atlasGroteskMediumWoff2}) format('woff2'),
           url(${atlasGroteskMediumWoff}) format('woff')`,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Atlas Grotesk',
      src: `url(${atlasGroteskRegularWoff2}) format('woff2'),
           url(${atlasGroteskRegularWoff}) format('woff')`,
      fontWeight: 400,
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Atlas Grotesk',
      src: `url(${atlasGroteskLightWoff2}) format('woff2'),
           url(${atlasGroteskLightWoff}) format('woff')`,
      fontWeight: 300,
      fontStyle: 'normal',
    },
  ],
  '@global': {
    '*': {
      boxSizing: 'border-box',
      // Firefox
      '&::-moz-placeholder': {
        color: styling.colors.theme.primary200,
        opacity: 1, // Override Firefox's unusual default opacity, see https://github.com/twbs/bootstrap/pull/11526
      },
      '&:-moz-placeholder': {
        color: styling.colors.theme.primary200,
        opacity: 1, // Override Firefox's unusual default opacity, see https://github.com/twbs/bootstrap/pull/11526
      },
      '&:-ms-input-placeholder': {
        color: styling.colors.theme.primary200,
      }, // Internet Explorer 10+
      '&::-webkit-input-placeholder': {
        color: styling.colors.theme.primary200,
      }, // Safari and Chrome
      '&::placeholder': {
        color: styling.colors.theme.primary200,
      },
    },
    html: {
      fontSize: '62.5%',
      height: '-webkit-fill-available',
    },
    body: {
      fontFamily: styling.fonts.theme.atlasGrotesk.fontFamily,
      fontWeight: styling.fonts.theme.atlasGrotesk.weights.regular,
      backgroundColor: styling.colors.context.background,
      color: styling.colors.context.color,
      minWidth: styling.sizes.context.cardFeature.width,
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      '&::-webkit-scrollbar': {
        WebkitAppearance: 'none',
      },
      '&::-webkit-scrollbar:vertical': {
        backgroundColor: '#fafafa',
        width: 'var(--scrollbarWidth)',
        borderLeft: '1px solid #e7e7e7',
      },

      '&::-webkit-scrollbar:horizontal': {
        backgroundColor: '#fafafa',
        height: 'var(--scrollbarHeight)',
        borderTop: '1px solid #e7e7e7',
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        border:
          '3px solid #fafafa' /* should match background, can't be transparent */,
        backgroundColor: '#c7c7c7',
      },

      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#878787',
      },
    },
    '.lock-scroll-modal,.lock-scroll-mobileMenu,.lock-scroll-topBar,.lock-scroll-flow,.lock-scroll-edit,.lock-scroll-alert':
      {
        overflow: 'hidden',
      },
    '.no-animations': {
      '& *': {
        animation: [['none'], '!important'],
        transition: [['none'], '!important'],
      },
    },
    a: {
      color: 'inherit',
    },
    p: {
      margin: 0,
    },
    ul: {
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },
  },
});

export default styles;
