export enum CardType {
  Deck = 'deck',
  Tool = 'tool',
  Kit = 'kit',
  Resource = 'resource',
  Flow = 'flow',
  Author = 'author',
  Article = 'article',
  Subject = 'subject',
  Display = 'display',
}

export type CardTypeValues = `${CardType}`;
