import { z } from 'zod';

import { KittedModelBaseSchema } from '../../../base';

export const FavoriteModelSchema = KittedModelBaseSchema.merge(
  z.object({
    favoritedOn: z.string().optional(),
    unfavoritedOn: z.string().optional(),

    isFavorite: z.boolean(),
  })
);

export type FavoriteModel = z.infer<typeof FavoriteModelSchema>;
