export enum KittedServiceEnum {
  Admin = 'admin',
  Account = 'account',
  Article = 'article',
  Asset = 'asset',
  Auth = 'auth',
  Author = 'author',
  BlobStorage = 'blob-storage',
  Catalogue = 'catalogue',
  Card = 'card',
  Code = 'code',
  Communication = 'communication',
  Contact = 'contact',
  Contentful = 'contentful',
  Extract = 'extract',
  Flow = 'flow',
  GlobalConfig = 'global-config',
  Image = 'image',
  ImageRender = 'image-render',
  Kit = 'kit',
  ModelSync = 'model-sync',
  Page = 'page',
  Policy = 'policy',
  Resource = 'resource',
  Shell = 'shell',
  Shop = 'shop',
  Shopify = 'shopify',
  Slug = 'slug',
  Websocket = 'websocket',
  MentalModelMondays = 'mmm',
  MentalModelMondaysRedirect = 'mmm-redirect',
  Membership = 'membership',
  Invite = 'invite',
}

export type KittedServiceEnumValues = `${KittedServiceEnum}`;
