import { EMPTY_OBJ } from '@kitted/utils';

import typographyConstants from './constants';
import { TypographyProps } from './types';

export const getTypographyStyles = (
  maxLines: TypographyProps['maxLines'],
  isTight: TypographyProps['isTight'],
  variant: TypographyProps['variant'] = 'body',
  style: TypographyProps['style'] = EMPTY_OBJ
) => {
  let lineHeight = '';
  if (maxLines) {
    if (isTight) {
      lineHeight = '1.055em';
    } else {
      lineHeight = typographyConstants[variant]!.lineHeight as string;
    }
  }
  return {
    ...style,
    '--typographyMaxLines': maxLines || '',
    '--typographyLineHeight': lineHeight,
  } as React.CSSProperties;
};

type TypographyVariants = {
  [key in `variant-${keyof typeof typographyConstants}`]: (typeof typographyConstants)[keyof typeof typographyConstants];
};

export const getVariants = () => {
  const variants = Object.keys(typographyConstants).reduce(
    (acc, variantKey) => {
      const key = variantKey as keyof typeof typographyConstants;
      return {
        ...acc,
        [`variant-${key}`]: typographyConstants[key],
      };
    },
    {} as TypographyVariants
  );

  return variants;
};
