import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0026: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 130"
		height={130}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M48.28 122.61c0-3.16 2.56-5.37 5.72-5.37s5.72 2.56 5.72 5.72-2.56 5.72-5.72 5.72-5.72-2.91-5.72-6.07ZM54 117.26v-40.1" /><circle vectorEffect="non-scaling-stroke" cx="73.54" cy="116.19" r="5.72" /><path vectorEffect="non-scaling-stroke" d="m69.44 112.09-6.96-6.95V85.33" /><circle vectorEffect="non-scaling-stroke" cx="34.46" cy="116.19" r="5.72" /><path vectorEffect="non-scaling-stroke" d="m38.56 112.09 6.96-6.95V85.33M45.52 81.23v-6.19M54 72.76v-6.19M62.48 81.23v-6.19" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M37.1 104.77C16.06 97.71.9 77.83.9 54.42.9 25.09 24.67 1.31 54 1.31s53.1 23.78 53.1 53.1c0 23.11-14.76 42.77-35.37 50.07" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M71.01 26.6C86.14 32 96.97 46.46 96.97 63.44" />
    </g>
  </CardIcon>
);

export default memo(Sn0026);
