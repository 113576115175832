export enum OrderStatus {
  Refunded = 'REFUNDED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Shipped = 'SHIPPED',
  Paid = 'PAID',
  // Delivered = 'DELIVERED',
}

export type OrderStatusValues = `${OrderStatus}`;
