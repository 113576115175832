import { z } from 'zod';

import { KittedModelBaseSchema } from '../../../../base';
import { DateISOSchema } from '../../../../Date';
import { CMSContentBlockTheme } from './Enum';

export const CMSContentBlockQuoteModelSchema = KittedModelBaseSchema.merge(
  z.object({
    theme: z.nativeEnum(CMSContentBlockTheme),
    content: z.string(),

    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,
  })
);

export type CMSContentBlockQuoteModel = z.infer<
  typeof CMSContentBlockQuoteModelSchema
>;
