import { z } from 'zod';

import { makeKittedModelReferenceSchema } from '../../../../base';
import { DateISOSchema } from '../../../../Date';
import { PublishSettingsSchema } from '../../PublishSettings';
import {
  CardLoadedModelSchema,
  CardType,
  CardUnloadedModelSchema,
} from '../Card';

export const KitCardBaseModelSchema = z.object({
  cardType: z.literal(CardType.Kit),
  title: z.string(),
  description: z.string(),

  relatedCards: makeKittedModelReferenceSchema(
    CardLoadedModelSchema,
    true
  ).array(),

  publishedAt: DateISOSchema.optional(),
  publishSettings: PublishSettingsSchema,
});

export const KitCardLoadedModelSchema = CardLoadedModelSchema.merge(
  KitCardBaseModelSchema
);
export const KitCardUnloadedModelSchema = CardUnloadedModelSchema.merge(
  KitCardBaseModelSchema.merge(
    z.object({
      relatedCards: makeKittedModelReferenceSchema(
        CardUnloadedModelSchema,
        false
      ).array(),
    })
  )
);

export type KitCardLoadedModel = z.infer<typeof KitCardLoadedModelSchema>;
export type KitCardUnloadedModel = z.infer<typeof KitCardUnloadedModelSchema>;

export type KitCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? KitCardLoadedModel : KitCardUnloadedModel;
