import { z } from 'zod';

import { makeKittedModelReferenceSchema } from '../../../../base';
import { ImageAssetModelSchema } from '../../../asset-service';
import {
  CardLoadedModelSchema,
  CardType,
  CardUnloadedModelSchema,
} from '../Card';

const DisplayBaseModelSchema = z.object({
  cardType: z.literal(CardType.Display),
  title: z.string(),
  description: z.string(),
  videoUrl: z.string().optional(),
  buttonUrl: z.string(),
  actionButtonTitle: z.string(),
  autoPlay: z.boolean().optional(),
});

export const DisplayLoadedImageAssetModelSchema = DisplayBaseModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ),
  })
);

export const DisplayUnloadedImageAssetModelSchema =
  DisplayBaseModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ),
    })
  );

export const DisplayLoadedModelSchema = CardLoadedModelSchema.merge(
  DisplayLoadedImageAssetModelSchema
);
export const DisplayUnloadedModelSchema = CardUnloadedModelSchema.merge(
  DisplayUnloadedImageAssetModelSchema
);

export type DisplayLoadedModel = z.infer<typeof DisplayLoadedModelSchema>;
export type DisplayUnloadedModel = z.infer<typeof DisplayUnloadedModelSchema>;

export type DisplayModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? DisplayLoadedModel : DisplayUnloadedModel;
