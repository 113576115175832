import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import { DateISOSchema } from '../../../../Date';
import { ImageAssetModelSchema } from '../../../asset-service';
import { CMSContentBlockTheme } from './Enum';

export const CMSContentBlockVideoLoadedModelSchema =
  KittedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(CMSContentBlockTheme),
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        true
      ).optional(),
      videoUrl: z.string().url(),
      isFullbleed: z.boolean(),
      caption: z.string().optional(),

      createdByAuthorId: z.string(),
      createdByUserId: z.string(),

      createdAt: DateISOSchema,
      updatedAt: DateISOSchema,
    })
  );

export const CMSContentBlockVideoUnloadedModelSchema =
  CMSContentBlockVideoLoadedModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
    })
  );

export type CMSContentBlockVideoLoadedModel = z.infer<
  typeof CMSContentBlockVideoLoadedModelSchema
>;
export type CMSContentBlockVideoUnloadedModel = z.infer<
  typeof CMSContentBlockVideoUnloadedModelSchema
>;

export type CMSContentBlockVideoModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? CMSContentBlockVideoLoadedModel
    : CMSContentBlockVideoUnloadedModel;
