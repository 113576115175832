import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      display: 'inline-flex',
      gap: 8,
      height: 48,
      alignItems: 'center',
      borderRadius: styling.sizes.context.card.radius,
      backgroundColor: 'var(--pillBgColor)',
      paddingInline: styling.sizes.theme.spacing.xs,
      color: 'var(--pillColor)',
    },
    iconOnly: {
      justifyContent: 'center',
      paddingInline: 0,
      '&$size-sm': {
        height: 36,
        width: 36,
      },
      '&$size-md': {
        height: 40,
        width: 40,
      },
      '&$size-lg': {
        height: 44,
        width: 44,
      },
    },
    'color-secondary': {
      '--pillBgColor': styling.colors.theme.secondary300_30,
      '--pillButtonBgColor': styling.colors.theme.secondary,
    },
    'color-primary': {
      '--pillBgColor': styling.colors.theme.primary600_30,
      '--pillButtonBgColor': styling.colors.theme.primary,
    },
    'color-action': {
      '--pillBgColor': styling.colors.theme.action500_30,
      '--pillButtonBgColor': styling.colors.theme.action600,
    },
    'color-frame': {
      '--pillBgColor': styling.colors.theme.frame500_30,
      '--pillButtonBgColor': styling.colors.theme.frame,
    },
    'color-snare': {
      '--pillBgColor': styling.colors.theme.snare500_30,
      '--pillButtonBgColor': styling.colors.theme.snare,
    },
    'color-insight': {
      '--pillBgColor': styling.colors.theme.insight500_30,
      '--pillButtonBgColor': styling.colors.theme.insight,
    },
    'color-danger': {
      '--pillBgColor': styling.colors.theme.danger700_30,
      '--pillButtonBgColor': styling.colors.theme.danger,
    },
    'theme-secondary': {
      '--pillColor': styling.colors.context.color,
      '--pillButtonColor': styling.colors.context.color,
    },
    'theme-primary': {
      '--pillColor': styling.colors.context.inverted.color,
      '--pillButtonColor': styling.colors.context.inverted.color,
    },
    'size-sm': {
      ...styling.typography.theme.atlasGrotesk.regular['3xs'],
      '& $icon': {
        fontSize: '2rem',
      },
      '& $button': {
        fontWeight: styling.fonts.theme.atlasGrotesk.weights.medium,
      },
    },
    'size-md': {
      ...styling.typography.theme.atlasGrotesk.regular['2xs'],
      '& $icon': {
        fontSize: '2.4rem',
      },
      '& $button': {
        fontWeight: styling.fonts.theme.atlasGrotesk.weights.medium,
      },
    },
    'size-lg': {
      ...styling.typography.theme.atlasGrotesk.regular.xs,
      '& $icon': {
        fontSize: '2.8rem',
      },
      '& $button': {
        fontWeight: styling.fonts.theme.atlasGrotesk.weights.medium,
      },
    },
    icon: {
      color: styling.colors.context.inverted.color,
    },
    button: {
      height: 32,
      backgroundColor: 'var(--pillButtonBgColor)',
      color: 'var(--pillButtonColor)',
    },
    close: {
      ...styling.utils.buttonReset(),
      color: styling.colors.context.inverted.color,
      fontSize: '1.6rem',
    },
  },
  { name: 'pill' }
);
