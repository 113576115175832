import { z } from 'zod';

import { KittedModelBaseSchema } from '../../../../base';
import { EmailBlockType } from './Enum/EmailBlockType';

export const EmailButtonModelSchema = KittedModelBaseSchema.merge(
  z.object({
    buttonText: z.string().max(128),
    linkUrl: z.string().url(),
    type: z.literal(EmailBlockType.Button),
  })
);

export type EmailButtonModel = z.infer<typeof EmailButtonModelSchema>;
