import { z } from 'zod';

import { makeKittedModelReferenceSchema } from '../../../../../base';
import { DateISOSchema } from '../../../../../Date';
import { ImageAssetModelSchema } from '../../../../asset-service';
import { RimgSlugSchema } from '../../../../slug-service';
import {
  CMSResourceBaseLoadedModelSchema,
  CMSResourceBaseUnloadedModelSchema,
} from '../CMSResourceBaseModel';

const CMSImageResourceLoadedBaseModelSchema = z.object({
  slug: RimgSlugSchema,
  keyImageImageAsset: makeKittedModelReferenceSchema(
    ImageAssetModelSchema,
    true
  ),
  showTitle: z.boolean().optional(),
  references: z.string().optional(),

  createdAt: DateISOSchema,
  updatedAt: DateISOSchema,
});

export const CMSImageResourceLoadedModelSchema =
  CMSResourceBaseLoadedModelSchema.merge(CMSImageResourceLoadedBaseModelSchema);

export const CMSImageResourceUnloadedModelSchema =
  CMSResourceBaseUnloadedModelSchema.merge(
    CMSImageResourceLoadedBaseModelSchema.merge(
      z.object({
        keyImageImageAsset: makeKittedModelReferenceSchema(
          ImageAssetModelSchema,
          false
        ),
      })
    )
  );

export type CMSImageResourceLoadedModel = z.infer<
  typeof CMSImageResourceLoadedModelSchema
>;
export type CMSImageResourceUnloadedModel = z.infer<
  typeof CMSImageResourceUnloadedModelSchema
>;

export type CMSImageResourceModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? CMSImageResourceLoadedModel
    : CMSImageResourceUnloadedModel;
