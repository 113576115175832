import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0052: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 130 90"
		height={90}
		width={130}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M33.38 49.91v-4.38c0-3.64 2.74-4.99 6.26-4.99l4.33 4.59 4.02-4.59c3.52 0 6.56 1.34 6.56 4.99v4.38M48.86 31.78c0 2.64-2.21 4.79-4.9 4.79s-4.9-2.15-4.9-4.79v-1.77c0-2.63 2.2-4.79 4.9-4.79s4.9 2.15 4.9 4.79v1.77ZM12.21 49.91v-4.38c0-3.64 2.74-4.99 6.26-4.99l4.33 4.59 4.02-4.59c1.36 0 2.66.2 3.73.66M27.69 31.78c0 2.64-2.2 4.79-4.9 4.79s-4.9-2.15-4.9-4.79v-1.77c0-2.64 2.2-4.79 4.9-4.79s4.9 2.15 4.9 4.79v1.77ZM75.71 49.91v-4.38c0-3.64-2.74-4.99-6.26-4.99l-4.33 4.59-4.02-4.59c-1.37 0-2.66.2-3.73.66M60.23 31.78c0 2.64 2.2 4.79 4.9 4.79s4.89-2.15 4.89-4.79v-1.77c0-2.64-2.2-4.79-4.89-4.79s-4.9 2.15-4.9 4.79v1.77Z" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M65.74 79.28h36.82c16.65 0 12.81-10.68 10.93-38.99-1.43-21.4-19.9-38.99-43.4-38.99H43.9C20.43 1.3 1.14 18.85 1.14 40.29s1.51 38.99 28.32 38.99h2.98" /><path vectorEffect="non-scaling-stroke" d="M114.94 72.16h13.92v-5.63" /><circle vectorEffect="non-scaling-stroke" cx="49.1" cy="80.23" r="8.48" /><path vectorEffect="non-scaling-stroke" d="M80.8 70.96V29.75c0-3.46 2.83-6.29 6.29-6.29h9.67c3.47 0 6.29 2.83 6.29 6.29v41.21M12.21 56.51h63.5M94.54 47.56v5.22" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0052);
