const htmlDecode = (input: string, isServer = false) => {
  if (isServer) {
    return input;
  }

  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

export default htmlDecode;
