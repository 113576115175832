import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import {
  AnyCardLoadedModel,
  AnyCardUnloadedModelSchema,
} from '../../../cards-service';
import { EmailBlockType } from './Enum/EmailBlockType';

export const EmailCardGridLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    type: z.literal(EmailBlockType.CardGrid),
    // getting an error that the inferred type will be too large for
    // serialization.. so we'll add the types back in native TS
    cards: z.any().array(),
    // cards: makeKittedModelReferenceSchema(
    //   AnyCardLoadedModelSchema,
    //   true
    // ).array(),
  })
);

export const EmailCardGridUnloadedModelSchema =
  EmailCardGridLoadedModelSchema.merge(
    z.object({
      cards: makeKittedModelReferenceSchema(
        AnyCardUnloadedModelSchema,
        false
      ).array(),
    })
  );

export type EmailCardGridLoadedModel = Omit<
  z.infer<typeof EmailCardGridLoadedModelSchema>,
  'cards'
> & {
  cards: AnyCardLoadedModel[];
};

export type EmailCardGridUnloadedModel = z.infer<
  typeof EmailCardGridUnloadedModelSchema
>;

export type EmailCardGridModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? EmailCardGridLoadedModel : EmailCardGridUnloadedModel;
