import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0055: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 126 86"
		height={86}
		width={126}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M42.24 1.47V23.1M42.24 62.9v21.63M83.77 43H62.14M22.33 43H.7" /><circle vectorEffect="non-scaling-stroke" cx="42.24" cy="43" r="19.33" /><path vectorEffect="non-scaling-stroke" d="M37.62 75.59C21.63 73.35 9.32 59.61 9.32 43M9.64 38.38C11.67 23.9 23.13 12.44 37.62 10.4M50.63 43c0 4.64-3.76 8.4-8.4 8.4s-8.4-3.76-8.4-8.4 3.76-8.4 8.4-8.4 8.4 3.76 8.4 8.4ZM75.15 43c0 18.18-14.74 32.92-32.91 32.92M42.24 10.08c16.63 0 30.37 12.33 32.6 28.35" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M83.77 1.47V23.1M83.77 62.9v21.63M125.3 43h-21.63M75.37 25.59c2.54-1.23 5.39-1.92 8.4-1.92 10.67 0 19.33 8.65 19.33 19.33s-8.65 19.33-19.33 19.33c-3.01 0-5.86-.69-8.4-1.92M79.15 75.59c-4-.56-7.77-1.84-11.17-3.7M67.85 14.18c3.44-1.9 7.25-3.21 11.3-3.77" /><path vectorEffect="non-scaling-stroke" d="M79.15 35.98a8.373 8.373 0 0 1 4.62-1.38 8.399 8.399 0 1 1 0 16.8c-1.71 0-3.29-.51-4.62-1.38M116.68 43c0 18.18-14.74 32.92-32.91 32.92M83.77 10.08c16.63 0 30.37 12.33 32.6 28.35" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0055);
