import { z } from 'zod';

import {
  ContentBlockTextModelSchema,
  ContentBlockVideoLoadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
} from '../../x-service';

export const AnyFlowBlockLoadedModelSchema = z.union([
  ContentBlockTextModelSchema,
  ContentBlockVideoLoadedModelSchema,
]);

export const AnyFlowBlockUnloadedModelSchema = z.union([
  ContentBlockTextModelSchema,
  ContentBlockVideoUnloadedModelSchema,
]);

export type AnyFlowBlockLoadedModel = z.infer<
  typeof AnyFlowBlockLoadedModelSchema
>;
export type AnyFlowBlockUnloadedModel = z.infer<
  typeof AnyFlowBlockUnloadedModelSchema
>;

export type AnyFlowBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AnyFlowBlockLoadedModel : AnyFlowBlockUnloadedModel;
