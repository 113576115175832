import { z } from 'zod';

import { DateISOSchema } from '../../../../Date';
import { PublishSettingsSchema } from '../../PublishSettings';
import {
  CardLoadedModelSchema,
  CardType,
  CardUnloadedModelSchema,
} from '../Card';

const ArticleCardBaseModelSchema = z.object({
  cardType: z.literal(CardType.Article),
  title: z.string(),
  description: z.string(),

  publishedAt: DateISOSchema.optional(),
  publishSettings: PublishSettingsSchema,
});

export const ArticleCardLoadedModelSchema = CardLoadedModelSchema.merge(
  ArticleCardBaseModelSchema
);
export const ArticleCardUnloadedModelSchema = CardUnloadedModelSchema.merge(
  ArticleCardBaseModelSchema
);

export type ArticleCardLoadedModel = z.infer<
  typeof ArticleCardLoadedModelSchema
>;
export type ArticleCardUnloadedModel = z.infer<
  typeof ArticleCardUnloadedModelSchema
>;

export type ArticleCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? ArticleCardLoadedModel : ArticleCardUnloadedModel;
