import { z } from 'zod';

export const EmailTemplateRenderResponseSchema = z.object({
  slug: z.string().max(128),
  subject: z.string(),
  html: z.string(),
});

export type EmailTemplateRenderResponse = z.infer<
  typeof EmailTemplateRenderResponseSchema
>;
