import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0052: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 90 116"
		height={116}
		width={90}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={color} d="m23.41 8.7-.94 4.5M24.51 1.01c2.07.14 3.64 1.94 3.5 4.01A3.762 3.762 0 0 1 24 8.52a3.763 3.763 0 1 1 .51-7.51ZM35.35 52.2l.09-11.59 6.97.05" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m10.5 23.1 28.1.2M10.45 30.92l28.09.21" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M16.27 7 1.99 6.89l-.33 45.2 34.9.25 10.52-8.97.27-36.14-15.13-.12" /></g><g ><path vectorEffect="non-scaling-stroke" stroke={color} d="m64.41 71.4-.93 4.5M65.48 63.7c2.07.13 3.64 1.92 3.51 4a3.76 3.76 0 0 1-4 3.51 3.756 3.756 0 0 1-3.51-4 3.774 3.774 0 0 1 4-3.51ZM76.49 114.86l.05-11.59 6.97.03" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m51.55 85.84 28.09.12M51.51 93.66l28.1.12" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m57.26 69.72-14.27-.06-.19 45.2 34.9.14 10.49-9 .15-36.15-15.13-.06" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0052);
