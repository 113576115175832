import { z } from 'zod';

import { DateISOSchema } from '../../../Date';
import { AccountLoadedModelSchema } from '../../account-service';
import { MembershipSummaryStatus } from './Enum';

// Account is for things related to the USER
// ie, what they favourite, what their preferences are
// is it NOT the things they create
export const MembershipSummaryModelSchema = z.object({
  status: z.nativeEnum(MembershipSummaryStatus),

  accountId: AccountLoadedModelSchema.shape.id,

  expiresAt: DateISOSchema.optional(),
});

export type MembershipSummaryModel = z.infer<
  typeof MembershipSummaryModelSchema
>;
