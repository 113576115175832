/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react';
import { EMPTY_ARR, EMPTY_OBJ } from '@kitted/utils';

import { playerTypeToComponentMap } from './constants';
import { MediaPlayerProps, MediaPlayerType } from './types';

export const generateConfigFromTracks = (
  suppliedTracks: MediaPlayerProps['tracks'] = EMPTY_ARR,
  additionalConfig: MediaPlayerProps['additionalConfig'] = EMPTY_OBJ
) => ({
  ...additionalConfig,
  file: {
    attributes: { crossOrigin: 'anonymous' },
    tracks: suppliedTracks,
  },
});

export const handleTogglePlaying = (
  playState: boolean,
  setPlayState: Dispatch<SetStateAction<boolean>>
) => {
  setPlayState(!playState);
};

export const handleAutoPlay = async ({
  autoPlay,
  playerRef,
  setPlaying,
  setMuted,
  playerType,
}: {
  autoPlay: boolean;
  playerRef: any;
  setPlaying: Dispatch<SetStateAction<boolean>>;
  setMuted: Dispatch<SetStateAction<boolean>>;
  playerType: MediaPlayerType;
}) => {
  const autoPlayDesired = autoPlay ?? true;
  if (playerRef && autoPlayDesired && playerType === 'video') {
    const videoInstance = playerRef.getInternalPlayer() || EMPTY_OBJ;
    // if we are wanting to autoplay, we need to be muted
    try {
      // Autoplay started!
      await videoInstance?.play();
      setPlaying(true);
    } catch (e) {
      console.info('default auto play failed', videoInstance?.muted);
      // Autoplay not allowed!
      // Mute video and try to play again
      videoInstance.muted = true;
      setMuted(true);
      try {
        console.info('auto play after muting');
        await videoInstance?.play();
        setPlaying(true);
      } catch (e2) {
        console.info('even muted autoplay isnt working');
      }
    }
  }
};

export const getPlayerComponentByType = (
  playerType: MediaPlayerType
): React.ElementType | undefined => playerTypeToComponentMap[playerType];
