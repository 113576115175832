import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../base';
import { notBoth } from '../../../zodHelpers';
import { ImageAssetModelSchema } from '../../asset-service';
import { CMSKitUnloadedModelSchema } from '../../contentful-service';
import { KitSlugSchema } from '../../slug-service';
import {
  AnyKitBlockLoadedModelSchema,
  AnyKitBlockUnloadedModelSchema,
} from '../KitBlocks';

export const KitLoadedModelSchema = KittedVersionedModelBaseSchema.merge(
  z.object({
    slug: KitSlugSchema,

    title: z.string(),
    description: z.string(),

    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),

    kitBlocks: makeKittedModelReferenceSchema(
      AnyKitBlockLoadedModelSchema,
      true
    ).array(),

    cmsSrcMessage: CMSKitUnloadedModelSchema.optional(),
  })
);

export const KitUnloadedModelSchema = KitLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),
    kitBlocks: makeKittedModelReferenceSchema(
      AnyKitBlockUnloadedModelSchema,
      false
    ).array(),
  })
);

export const KitSaveModelSchema = KitUnloadedModelSchema.merge(
  KittedSaveModelBaseSchema
)
  .merge(
    z.object({
      slug: KitUnloadedModelSchema.shape.slug.optional(),

      title: KitUnloadedModelSchema.shape.title.optional(),
      description: KitUnloadedModelSchema.shape.description.optional(),

      kitBlocks: KitUnloadedModelSchema.shape.kitBlocks.optional(),
      keyImageBlobId: z.string().optional().nullable(),
    })
  )
  .superRefine(notBoth('keyImageBlobId', 'keyImageImageAsset'));

export type KitLoadedModel = z.infer<typeof KitLoadedModelSchema>;
export type KitUnloadedModel = z.infer<typeof KitUnloadedModelSchema>;
export type KitSaveModel = z.infer<typeof KitSaveModelSchema>;

export type KitModel<IsLoaded extends boolean = false> = IsLoaded extends true
  ? KitLoadedModel
  : KitUnloadedModel;
