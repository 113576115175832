import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import { DateISOSchema } from '../../../../Date';
import { ImageAssetModelSchema } from '../../../asset-service';
import { CMSContentBlockTheme } from './Enum';

export const CMSContentBlockImageLoadedModelSchema =
  KittedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(CMSContentBlockTheme),
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        true
      ),
      linkUrl: z.string().url().optional(),
      isFullbleed: z.boolean(),
      caption: z.string().optional(),

      createdByAuthorId: z.string(),
      createdByUserId: z.string(),

      createdAt: DateISOSchema,
      updatedAt: DateISOSchema,
    })
  );

export const CMSContentBlockImageUnloadedModelSchema =
  CMSContentBlockImageLoadedModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ),
    })
  );

export type CMSContentBlockImageLoadedModel = z.infer<
  typeof CMSContentBlockImageLoadedModelSchema
>;
export type CMSContentBlockImageUnloadedModel = z.infer<
  typeof CMSContentBlockImageUnloadedModelSchema
>;

export type CMSContentBlockImageModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? CMSContentBlockImageLoadedModel
    : CMSContentBlockImageUnloadedModel;
