import {
  CardType,
  KittedModelTypeEnum,
  KittedVersionStatusTypeEnum,
  LinkResourceCardModel,
  LinkResourceModel,
  ResourceCardType,
} from '../../../../models';

export const convertLinkResourceModelToLinkResourceCardModel = (
  id: LinkResourceModel['id'],
  linkResource: Omit<LinkResourceModel, 'id'>,
  oldCard: Partial<LinkResourceCardModel> = {}
): LinkResourceCardModel => ({
  ...oldCard,

  id,
  slug: linkResource.slug,

  cardType: CardType.Resource,
  resourceType: ResourceCardType.Link,
  kittedModelType: KittedModelTypeEnum.CardLinkResource,

  createdAt: oldCard?.createdAt || linkResource.createdAt,
  updatedAt: linkResource.updatedAt,

  createdByAuthorId: linkResource.createdByAuthorId,
  keyImageImageAsset: linkResource.keyImageImageAsset,
  label: linkResource.label,
  title: linkResource.title,

  linkUrl: linkResource.linkUrl,
  description: linkResource.description,

  excludeFromDiscovery:
    oldCard?.excludeFromDiscovery ??
    linkResource.cmsSrcMessage?.excludeFromDiscovery ??
    true,
  live: oldCard?.live ?? linkResource.cmsSrcMessage?.live ?? true,
  versionStatus: linkResource.versionStatus,
  publishedAt:
    linkResource.versionStatus === KittedVersionStatusTypeEnum.Published
      ? linkResource.updatedAt
      : undefined,
});
