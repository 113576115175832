import { z } from 'zod';

import {
  ContentBlockCardGridLoadedModelSchema,
  ContentBlockCardGridUnloadedModelSchema,
  ContentBlockCarouselItemLoadedModelSchema,
  ContentBlockCarouselItemUnloadedModelSchema,
  ContentBlockCarouselLoadedModelSchema,
  ContentBlockCarouselUnloadedModelSchema,
  ContentBlockImageLoadedModelSchema,
  ContentBlockImageUnloadedModelSchema,
  ContentBlockQuoteModelSchema,
  ContentBlockTextModelSchema,
  ContentBlockVideoLoadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
} from '../../x-service';
import { ArticleBlockExecutiveBoardModelSchema } from './ArticleBlockExecutiveBoardModel';

export const AnyArticleBlockLoadedModelSchema = z.union([
  ArticleBlockExecutiveBoardModelSchema,
  ContentBlockImageLoadedModelSchema,
  ContentBlockCardGridLoadedModelSchema,
  ContentBlockCarouselLoadedModelSchema,
  ContentBlockCarouselItemLoadedModelSchema,
  ContentBlockVideoLoadedModelSchema,
  ContentBlockQuoteModelSchema,
  ContentBlockTextModelSchema,
]);

export const AnyArticleBlockUnloadedModelSchema = z.union([
  ArticleBlockExecutiveBoardModelSchema,
  ContentBlockImageUnloadedModelSchema,
  ContentBlockCardGridUnloadedModelSchema,
  ContentBlockCarouselUnloadedModelSchema,
  ContentBlockCarouselItemUnloadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
  ContentBlockQuoteModelSchema,
  ContentBlockTextModelSchema,
]);

export type AnyArticleBlockLoadedModel = z.infer<
  typeof AnyArticleBlockLoadedModelSchema
>;
export type AnyArticleBlockUnloadedModel = z.infer<
  typeof AnyArticleBlockUnloadedModelSchema
>;

export type AnyArticleBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyArticleBlockLoadedModel
    : AnyArticleBlockUnloadedModel;
