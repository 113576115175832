import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0049: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 110"
		height={110}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M52 100.8c1.77-5.47 4.71-10.05 6.24-11 2.23-1.39 5.81-2.63 10.61-2.51 4.44.11 5.67 2.26 13.85 2.79 7.98.51 15.06.68 15.06.68l-.74 12.1M52 100.8c-1.77-5.47-4.71-10.05-6.24-11-2.23-1.39-5.81-2.63-10.61-2.51-4.44.11-5.67 2.26-13.85 2.79-7.98.51-15.06.68-15.06.68l.74 12.1" /><path vectorEffect="non-scaling-stroke" d="M52 99.87c1.54-8.78 6.85-15.89 10.47-18.21 11.55-7.39 25.39-3.02 30.78-1.17l-2.35 5.27M52 99.87c-1.54-8.78-6.85-15.89-10.47-18.21-11.55-7.39-25.38-3.02-30.78-1.17l2.35 5.27" /><path vectorEffect="non-scaling-stroke" d="M52 98.02c0-17.71 7.1-26.34 10.63-28.8 6.62-4.62 15.37-5.64 26.46-4.87l-3.9 8.11M52 98.02c0-17.71-7.1-26.34-10.63-28.8-6.62-4.62-15.37-5.64-26.46-4.87l3.89 8.11M102.19 103.7s-34.27-2.63-36.23-2.63-3.61.21-4.74 3.39c-1.13 3.18-.38 4.36-2.68 4.36H45.47c-2.31 0-1.55-1.18-2.68-4.36-1.13-3.18-2.79-3.39-4.74-3.39S1.82 103.7 1.82 103.7" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M70.58 37.11c0 .51.42.93.93.93s.93-.42.93-.93-.42-.93-.93-.93-.93.42-.93.93ZM49.71 30.45c0 .51.42.93.93.93s.93-.42.93-.93-.42-.93-.93-.93-.93.42-.93.93ZM46.62.8v9.51M51.37 5.56h-9.51M60.17 13.02v13.23M66.79 19.64H53.56M30.03 46.21c-3.56-8.08-2.74-7.26-10.82-10.82 8.08-3.56 7.26-2.74 10.82-10.82 3.56 8.08 2.74 7.26 10.82 10.82-8.08 3.56-7.26 2.74-10.82 10.82ZM54.86 66.24c-4.41-10-3.4-8.99-13.4-13.4 10.01-4.41 8.99-3.4 13.4-13.4 4.41 10 3.4 8.99 13.4 13.4-10 4.41-8.99 3.4-13.4 13.4Z" /></g>
    </g>
  </CardIcon>
);

export default memo(In0049);
