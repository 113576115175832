import {
  AuthorCardModel,
  AuthorModel,
  CardType,
  KittedModelTypeEnum,
  KittedVersionStatusTypeEnum,
} from '../../../../models';

export const convertAuthorModelToAuthorCardModel = (
  id: AuthorModel['id'],
  author: Omit<AuthorModel, 'id'>,
  oldCard: Partial<AuthorCardModel> = {}
): AuthorCardModel => ({
  ...oldCard,

  id,
  slug: author.slug,

  kittedModelType: KittedModelTypeEnum.CardAuthor,

  cardType: CardType.Author,
  createdAt: oldCard?.createdAt || author.createdAt,
  updatedAt: author.updatedAt,
  description: author.description || '',
  keyImageImageAsset: author.keyImageImageAsset,
  createdByAuthorId: id,
  firstName: author.firstName,
  lastName: author.lastName,
  relatedCards: oldCard.relatedCards || [],

  excludeFromDiscovery: true,
  live: true,
  versionStatus: author.versionStatus,
  publishedAt:
    author.versionStatus === KittedVersionStatusTypeEnum.Published
      ? author.updatedAt
      : undefined,
});
