import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0025: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 76 102"
		height={102}
		width={76}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M30.87 24.24V18.8M30.87 11.07h-8.1c-1.58 0-2.87 1.29-2.87 2.87v10.3" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M46.17 34.26c-1.39-5.54-6.05-8.15-10.75-9.63H15.19c-4.65 1.47-9.21 4.09-10.6 9.63M47.14 41.47v53.65c0 3.16-2.58 5.75-5.75 5.75H9.37c-3.16 0-5.75-2.59-5.75-5.75V41.47M1.25 41.33h48.27" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M47.11 54.05H26.39v28.28h20.72" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M44.39 15.4a.894.894 0 1 1-1.79 0c0-.5.4-.9.9-.9s.89.4.89.9ZM54.32 10.47a.894.894 0 1 1-1.79 0c0-.5.4-.9.9-.9s.89.4.89.9ZM54.32 20.33a.894.894 0 1 1-1.79 0c0-.5.4-.9.9-.9s.89.4.89.9ZM64.2 5.83a.894.894 0 1 1-1.79 0c0-.5.4-.9.9-.9s.89.4.89.9ZM64.2 15.4a.894.894 0 1 1-1.79 0c0-.5.4-.9.9-.9s.89.4.89.9ZM64.2 24.97a.894.894 0 1 1-1.79 0c0-.5.4-.9.9-.9s.89.4.89.9ZM74.38 2.02a.894.894 0 1 1-1.79 0c0-.5.4-.9.9-.9s.89.4.89.9ZM74.38 11.59a.894.894 0 1 1-1.79 0c0-.5.4-.9.9-.9s.89.4.89.9ZM74.38 21.16a.894.894 0 1 1-1.79 0c0-.5.4-.9.9-.9s.89.4.89.9ZM74.38 30.73a.894.894 0 1 1-1.79 0c0-.5.4-.9.9-.9s.89.4.89.9Z" />
    </g>
  </CardIcon>
);

export default memo(Fr0025);
