import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0042: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 116 100"
		height={100}
		width={116}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m78.44 37.05 3.51-8.43c2.08-5.05 8.72-6.18 12.37-2.13 3.8 4.22 9.43 6.19 9.43 6.19l-7.12 2.12a3.58 3.58 0 0 0-2.53 3.92c.23 1.74.47 3.72.47 4.5 0 5.21-1.92 10.35-5.62 14.16a24.666 24.666 0 0 1-18.21 7.59h-.54c-17.88-.72-32.31 13.41-32.31 13.41-8.96-7.05-10.63-16.11-6.76-26.7 6.42 4 15.15 4.16 19.47-.47" /><path vectorEffect="non-scaling-stroke" d="M81.53 46.32C79.32 30.73 63.28 18.15 41.94 6.73c-3.03 11.03.51 19.09 9.12 24.85" /><path vectorEffect="non-scaling-stroke" d="M43.68 24.22c-2.19 8.41 1.65 15.34 10.13 20.1" /><path vectorEffect="non-scaling-stroke" d="M64.28 57.04c-13.05-2.62-18.91-8.48-17.89-18.83M61.27 18.18c.35-5.66 2.21-10.67 6.53-14.61 7.34 7.25 12.87 14.83 15.43 22.93M29.24 60.54c3.75 1.13 7.49 1.51 11.24 1.13M31.39 70.59c2.39.09 4.62-.54 6.72-1.76" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M114.93 88.02H12.28V.96M.69 23.82h11.49M.69 49.89h11.49M.69 75.96h11.49M24.4 99.41V87.92M61.57 99.41V87.92M98.75 99.41V87.92" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0042);
