export const rollbarEnvironment = () => {
  const { hostname } = window.location;
  const subdomain = hostname.split('.')[0];

  if (subdomain === 'kitted') {
    return 'production';
  }
  if (subdomain === 'localhost' || subdomain === '192') {
    return 'development';
  }
  return subdomain;
};
