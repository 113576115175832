import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Scales: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 30 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M26.78 3.21c.03-.15-.11 2.08-3.34 1.69l-.48-.05c-2.22-.37-4.65-.39-7.95.36h-.03c-3.3.75-5.49 1.81-7.33 3.1l-.41.25c-2.75 1.74-3.83-.21-3.74-.09M15 20.12V5.21" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M28 12.86c0 1.53-1.72 2.77-3.85 2.77s-3.85-1.24-3.85-2.77S24.15 5 24.15 5 28 11.33 28 12.86ZM20.66 12.22h6.97" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M9.7 17.15c0 1.53-1.72 2.77-3.85 2.77S2 18.68 2 17.15s3.85-7.86 3.85-7.86 3.85 6.33 3.85 7.86ZM2.37 16.51h6.97" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M15.37 2.13a.37.37 0 1 1-.738.002.37.37 0 0 1 .738-.002ZM15.01 22.3c-3 0-5.63 1.57-7.11 3.94l14.21-.02a8.391 8.391 0 0 0-7.1-3.91Z" />
    </g>
  </WebsiteIcon>
);

export default memo(Scales);
