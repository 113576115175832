import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0003: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 110"
		height={110}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <ellipse cx="52" cy="55" stroke={color} rx="20.21" ry="53.29" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M41.91 37.22C15.09 51.4-2.14 70.86 3.44 80.68c5.57 9.82 31.83 6.28 58.65-7.9s44.04-33.64 38.47-43.46-31.83-6.28-58.65 7.9Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M62.09 37.22c26.82 14.18 44.04 33.64 38.47 43.46s-31.83 6.28-58.65-7.9S-2.14 39.14 3.44 29.32c5.57-9.82 31.83-6.28 58.65 7.9Z" /><circle vectorEffect="non-scaling-stroke" cx="52" cy="55" r="7.35" stroke={spot} />
    </g>
  </CardIcon>
);

export default memo(In0003);
