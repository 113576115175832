import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const BrowserGear: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M5.47 18.48v-2.19l2.29-.38c.15-.6.39-1.17.7-1.68l-1.34-1.89 1.72-1.72 1.89 1.34c.52-.31 1.08-.54 1.67-.69l.39-2.29h2.44l.39 2.29c.6.15 1.16.39 1.68.7l1.88-1.35 1.72 1.72-1.35 1.88c.31.52.55 1.08.7 1.68l2.29.39v2.19" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M10.43 15.35a4.163 4.163 0 0 0-.54 2.85M18.11 18.22a4.174 4.174 0 0 0-6.2-4.32M3.15 6.38h21.7M4.94 4.09c0 .17-.14.31-.3.31s-.31-.14-.31-.31.14-.31.31-.31.3.14.3.31ZM7.86 4.09c0 .17-.14.31-.31.31s-.31-.14-.31-.31.14-.31.31-.31.31.14.31.31ZM10.78 4.09c0 .17-.14.31-.31.31s-.31-.14-.31-.31.14-.31.31-.31.31.14.31.31Z" /><rect vectorEffect="non-scaling-stroke" width="25.24" height="18.54" x="1.38" y="1.73" stroke={color} rx="1.87" ry="1.87" />
    </g>
  </WebsiteIcon>
);

export default memo(BrowserGear);
