import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../base';
import { notBoth } from '../../../zodHelpers';
import { ImageAssetModelSchema } from '../../asset-service';
import {
  CMSArticleLoadedModelSchema,
  CMSArticleUnloadedModelSchema,
} from '../../contentful-service';
import { ArticleSlugSchema } from '../../slug-service';
import {
  AnyArticleBlockLoadedModelSchema,
  AnyArticleBlockUnloadedModelSchema,
} from '../ArticleBlocks';

export const ArticleLoadedModelSchema = KittedVersionedModelBaseSchema.merge(
  z.object({
    slug: ArticleSlugSchema,

    title: z.string(),
    description: z.string(),
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    articleBlocks: makeKittedModelReferenceSchema(
      AnyArticleBlockLoadedModelSchema,
      true
    ).array(),

    cmsSrcMessage: z
      .union([CMSArticleLoadedModelSchema, CMSArticleUnloadedModelSchema])
      .optional(),
  })
);

export const ArticleUnloadedModelSchema = ArticleLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),
    articleBlocks: makeKittedModelReferenceSchema(
      AnyArticleBlockUnloadedModelSchema,
      false
    ).array(),
  })
);

export const ArticleSaveModelSchema = ArticleUnloadedModelSchema.merge(
  KittedSaveModelBaseSchema
)
  .merge(
    z.object({
      slug: ArticleUnloadedModelSchema.shape.slug.optional(),

      title: z.string().optional(),
      description: z.string().optional(),
      articleBlocks: ArticleUnloadedModelSchema.shape.articleBlocks.optional(),
      keyImageBlobId: z.string().optional().nullable(),
    })
  )
  .superRefine(notBoth('keyImageBlobId', 'keyImageImageAsset'));

export type ArticleLoadedModel = z.infer<typeof ArticleLoadedModelSchema>;
export type ArticleUnloadedModel = z.infer<typeof ArticleUnloadedModelSchema>;
export type ArticleSaveModel = z.infer<typeof ArticleSaveModelSchema>;

export type ArticleModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? ArticleLoadedModel : ArticleUnloadedModel;
