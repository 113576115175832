import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0024: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 128 90"
		height={90}
		width={128}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M39.78 42.39h37.9M39.98 54.22h32.55" /><g stroke={color}><path vectorEffect="non-scaling-stroke" d="m76.9 70.55-4.28 4.27M81.1 58.06c9.11-15.81 22.31-28.33 38.62-36.59 1.42-.63 3.55-.17 4.7.99l.6.6c1.15 1.15 1.62 3.28.99 4.69-8.24 16.23-20.86 29.52-36.6 38.62l-8.31-8.31ZM77.21 63.72l-1.52 4.83 3.19 3.19 10.29-5.25M113.44 24.6l-5.2-5.87M90.98 32.47c7.49-7.66 17.2-13.67 17.2-13.67M106.3 37.72l7.86 7.86" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M39.73 73.93s4.67-5.53 6.61-6.16c1.95-.63 1.86 7.3 1.86 7.3s4.86-6.28 8.02-2.71c3.73 4.2 2.77 3.96 8.71 3.17" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M92.27 74.99v13.88H25.19V22.8h67.08" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M18.48 68.2H1.71V1.13h67.08V17.9" />
    </g>
  </CardIcon>
);

export default memo(Ac0024);
