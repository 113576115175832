import { useMemo } from 'react';
import { CardTypeValues } from '@kitted/models';

import { getMaxLinesByType, getVariantByType } from '../../logic';

const useCardBody = (
  type: CardTypeValues,
  isHover = false,
  isFeatured = false,
  useDefaultMaxLines = false
) => {
  const maxLines = useMemo(
    () => getMaxLinesByType(type, isFeatured, useDefaultMaxLines),
    [type, isFeatured, useDefaultMaxLines]
  );

  const variant = useMemo(
    () => getVariantByType(isHover, isFeatured),
    [isHover, isFeatured]
  );

  return {
    maxLines,
    variant,
  };
};

export default useCardBody;
