import { z } from 'zod';

import { UserModelSchema } from './UserModel';

export const UserAttributesRequestSchema = UserModelSchema.omit({
  id: true,
  emailVerified: true,
  status: true,
  kittedModelType: true,
});

export type UserAttributesRequest = z.infer<typeof UserAttributesRequestSchema>;

export const UserAttributesResponseSchema = UserModelSchema;

export type UserAttributesResponse = z.infer<
  typeof UserAttributesResponseSchema
>;
