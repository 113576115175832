import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0005: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 112 106"
		height={106}
		width={112}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M40.3 50.64a31.153 31.153 0 0 1-5.55-17.8c0-17.29 14.02-31.31 31.31-31.31s31.32 14.01 31.32 31.31c0 7-2.3 13.46-6.18 18.68" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m38.31 19.39 8.71 8.57-1.41 7.41 5.12 3 3.59 12.59L64.5 32.37 53.91 26.9l-6.89.53-.7-5.65 8.82-6-.35-11.47M97.21 28.45l-7.87 11.49-3.18 4.67-3.1-12.94-3.56-.18s-8.65-3.18-1.24-9.35l9-1.06 3.53-6.88-11.47 1.24-3.15-1.94 3.08-8.66" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m84.16 63.19 17.17-8.77c3.32-1.78 7.21-1.07 8.81 1.92 1.6 2.99.2 6.89-3.12 8.67L71.4 84.1l-1.88.47-33.22.9s-3.99 1.12-8.89 6.43" /><path vectorEffect="non-scaling-stroke" d="m15.09 75.02 15.07-13.87c7.36-4.97 16.1-2.23 16.1-2.23 4.23 1.11 12.08 1.36 12.08 1.36h13.04c3.88 0 7.06 2.77 7.06 6.16s-3.18 6.16-7.06 6.16H53.35" /></g><rect vectorEffect="non-scaling-stroke" width="33.94" height="12.88" x="-.89" y="80.85" stroke={color} rx=".83" ry=".83" transform="rotate(53.84 16.084 87.291)" />
    </g>
  </CardIcon>
);

export default memo(Fr0005);
