import { useMemo } from 'react';
import { CardTypeValues } from '@kitted/models';

import { getCardContentsStyle } from '../../logic';

const useCardContentsForHoverableCard = (
  type: CardTypeValues,
  isFeatured = false
) => {
  const style = useMemo(
    () => getCardContentsStyle(type, isFeatured),
    [type, isFeatured]
  );

  return {
    style,
  };
};

export default useCardContentsForHoverableCard;
