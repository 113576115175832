import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    divider: {
      margin: ['var(--dividerSpacing)', 'auto'],
      width: '100%',
      position: 'relative',
      borderColor: [['var(--dividerBackground)'], '!important'],
      borderRight: [['none'], '!important'],
      borderLeft: [['none'], '!important'],
      borderBottom: [['none'], '!important'],
    },
    subtle: {
      opacity: 0.2,
    },
    'theme-secondary': {
      '--dividerBackground': styling.colors.theme.secondary,
    },
    'theme-primary': {
      '--dividerBackground': styling.colors.theme.primary600,
    },
    'spacing-sm': {
      '--dividerSpacing': '18px',
    },
    'spacing-md': {
      '--dividerSpacing': '27px',
    },
    'spacing-lg': {
      '--dividerSpacing': '36px',
    },
    'spacing-xl': {
      '--dividerSpacing': '48px',
    },
    'spacing-2xl': {
      '--dividerSpacing': '64px',
    },
  },
  { name: 'divider' }
);
