import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0048: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 74 74"
		height={74}
		width={74}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M72.92 1.08 1.08 72.92" /><circle vectorEffect="non-scaling-stroke" cx="13.48" cy="13.58" r="12.4" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="60.52" cy="60.52" r="12.4" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M17.23 63.84c5.54 4.1 12.39 6.53 19.82 6.53 18.4 0 33.32-14.92 33.32-33.32 0-7.42-2.43-14.28-6.53-19.82M56.75 10.18a33.191 33.191 0 0 0-19.7-6.45c-18.4 0-33.32 14.92-33.32 33.32 0 7.37 2.4 14.18 6.45 19.7" />
    </g>
  </CardIcon>
);

export default memo(Sn0048);
