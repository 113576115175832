import { z } from 'zod';

import { makeKittedModelReferenceSchema } from '../../../../base';
import { CardLoadedModelSchema, CardUnloadedModelSchema } from '../Card/Card';

export const ToolBaseCardBaseModelSchema = z.object({
  title: z.string(),
  description: z.string(),

  exposition: z.string(),
  references: z.string().optional(),
  inDecks: makeKittedModelReferenceSchema(CardLoadedModelSchema, true).array(),
  exampleCards: makeKittedModelReferenceSchema(
    CardLoadedModelSchema,
    true
  ).array(),
  resourceCards: makeKittedModelReferenceSchema(
    CardLoadedModelSchema,
    true
  ).array(),
  relatedCards: makeKittedModelReferenceSchema(
    CardLoadedModelSchema,
    true
  ).array(),
});

export const ToolBaseCardLoadedModelSchema = CardLoadedModelSchema.merge(
  ToolBaseCardBaseModelSchema
);
export const ToolBaseCardUnloadedModelSchema = CardUnloadedModelSchema.merge(
  ToolBaseCardBaseModelSchema.merge(
    z.object({
      inDecks: makeKittedModelReferenceSchema(
        CardUnloadedModelSchema,
        false
      ).array(),
      exampleCards: makeKittedModelReferenceSchema(
        CardUnloadedModelSchema,
        false
      ).array(),
      resourceCards: makeKittedModelReferenceSchema(
        CardUnloadedModelSchema,
        false
      ).array(),
      relatedCards: makeKittedModelReferenceSchema(
        CardUnloadedModelSchema,
        false
      ).array(),
    })
  )
);

export type ToolBaseCardLoadedModel = z.infer<
  typeof ToolBaseCardLoadedModelSchema
>;
export type ToolBaseCardUnloadedModel = z.infer<
  typeof ToolBaseCardUnloadedModelSchema
>;

export type ToolBaseCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? ToolBaseCardLoadedModel : ToolBaseCardUnloadedModel;
