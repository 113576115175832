import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0043: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 90 108"
		height={108}
		width={90}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M64.91 84.55c14.16-7.26 23.88-22.01 23.88-38.99C88.79 21.42 69.14 1.78 45 1.78S1.21 21.42 1.21 45.56c0 17.06 9.81 31.87 24.08 39.09M25.21 84.82h38.07a8.89 8.89 0 0 1 8.89 8.89v1.81H16.32v-1.81a8.89 8.89 0 0 1 8.89-8.89ZM16.54 95.52h55.41a8.89 8.89 0 0 1 8.89 8.89v1.81H7.65v-1.81a8.89 8.89 0 0 1 8.89-8.89Z" /></g><g ><path vectorEffect="non-scaling-stroke" stroke={spot} d="M37.46 52.91c0 3.88 3.38 7.02 7.56 7.02s7.56-3.14 7.56-7.02-4.03-5.58-7.57-7.38c-3.54-1.8-7.57-3.5-7.57-7.38s3.38-7.03 7.56-7.03 7.56 3.15 7.56 7.03M44.98 31.1v-4.66M44.98 64.62v-4.65" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M25.1 84.52C10.93 77.28 1.23 62.54 1.23 45.54c0-15.86 8.43-29.74 21.06-37.42A43.52 43.52 0 0 1 45 1.77c24.18 0 43.78 19.59 43.78 43.76 0 17.12-9.83 31.93-24.15 39.12" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M32.05 17.48c3.94-1.82 8.33-2.84 12.95-2.84 17.07 0 30.9 13.83 30.9 30.89S62.07 76.42 45 76.42 14.11 62.6 14.11 45.53c0-8.55 3.48-16.29 9.09-21.89M45 7.76V1.92M45 84.82v-1.51M30.55 10.64l-2.24-5.4M60.96 84.06l-1.51-3.63M59.45 10.64l2.24-5.4M29.04 84.06l1.5-3.63M10.1 31.08l-5.4-2.24M85.3 62.23l-5.4-2.24M10.1 59.99l-5.4 2.24M85.3 28.84l-5.4 2.24M71.71 18.82l4.13-4.13M14.16 76.38l4.13-4.14M18.29 18.82l-4.13-4.13M75.84 76.38l-4.13-4.14M82.77 45.53h5.85M1.38 45.53h5.85" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M26.8 45.53c0 .61-.49 1.1-1.1 1.1s-1.1-.49-1.1-1.1.49-1.1 1.1-1.1 1.1.49 1.1 1.1ZM65.4 45.53c0 .61-.49 1.1-1.1 1.1s-1.1-.49-1.1-1.1.49-1.1 1.1-1.1 1.1.49 1.1 1.1Z" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0043);
