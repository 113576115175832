import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Heart: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M22.58 11.42c2.29-2.29 2.33-6 .08-8.25s-5.96-2.21-8.25.08l-1.42 1.41-1.41-1.41C9.29.96 5.58.92 3.33 3.17s-2.21 5.96.08 8.25l8.73 8.72c.47.47 1.24.47 1.71 0l8.73-8.72Z" />
    </g>
  </WebsiteIcon>
);

export default memo(Heart);
