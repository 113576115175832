import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const PlayLeft: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 18 20"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" fill={color} d="m2.33 11.73 11.64 6.72c1.33.77 3-.19 3-1.73V3.28c0-1.54-1.67-2.5-3-1.73L2.33 8.27c-1.33.77-1.33 2.69 0 3.46Z" />
    </g>
  </WebsiteIcon>
);

export default memo(PlayLeft);
