import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0059: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 84"
		height={84}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M76.6 75.25H5.51c-2.23 0-4.06-1.83-4.06-4.06V26.38a4.07 4.07 0 0 1 4.06-4.06h24.77M54.24 22.32h7.88M47.22 40.33h18.5M47.22 50.44h19.77M47.22 60.56h21.03" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M10.26 68.78v-3.42c0-5.33 3.66-8.45 8.63-8.45l6.1 6.72 5.66-6.72c4.96 0 9.06 3.12 9.06 8.45v3.42M32.25 44.08c0 4.06-3.27 7.39-7.28 7.39s-7.27-3.32-7.27-7.39V42.5c0-4.06 3.27-7.39 7.27-7.39s7.28 3.32 7.28 7.39v1.58Z" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M36.58 28.76V10.23h11v18.53M31.53 29.02h21.09" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M73.7 29.43a15.761 15.761 0 0 1-5.62-12.25c.09-8.69 7.21-15.67 15.9-15.58 8.69.09 15.67 7.2 15.58 15.9-.05 4.78-2.21 9.05-5.6 11.91" /><path vectorEffect="non-scaling-stroke" d="M86.13 63.93c3.19 0 5.92-1.44 6.19-4.61l2.74-30.15H72.62l2.74 30.15c.27 3.18 3 4.61 6.19 4.61h4.57ZM76.74 20.44h22.67M83.85 45.23v5.71M106.55 60.65c-4.17.02-7.58 3.59-7.56 7.94v5.88c.04 4.35-3.36 7.92-7.52 7.93-4.17.02-7.59-3.52-7.61-7.87l-.02-3.66" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0059);
