import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0007: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 132 114"
		height={114}
		width={132}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M37.59 9.94c-15.91 9.64-26.54 27.13-26.54 47.09h-8.9L18.3 81.98l16.17-24.95h-9.23c0-22.51 18.25-40.81 40.76-40.81 13.82 0 26.03 6.88 33.4 17.4M69.5 2.13c-1.16-.07-2.33-.11-3.5-.11-8.04 0-15.67 1.73-22.55 4.83M92.2 8.65c-4.8-2.6-10.03-4.52-15.56-5.6M106.77 20.12a55.556 55.556 0 0 0-9.23-8.16M94.42 104.06c15.91-9.64 26.54-27.13 26.54-47.09h8.89L113.7 32.02 97.53 56.97h9.23c0 22.51-18.26 40.81-40.76 40.81-13.82 0-26.03-6.88-33.41-17.4M62.5 111.87c1.16.07 2.32.11 3.5.11 8.03 0 15.67-1.73 22.55-4.83M39.8 105.35c4.8 2.6 10.03 4.52 15.56 5.6M25.23 93.87c2.76 3.05 5.86 5.79 9.23 8.16" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M68.49 45.72c4.34-4.34 11.38-4.41 15.63-.16 4.26 4.26 4.19 11.29-.15 15.63L65.81 79.32 47.65 61.19c-4.34-4.34-4.41-11.38-.16-15.63 4.26-4.26 11.29-4.19 15.63.16l2.67 2.67 2.69-2.67Z" />
    </g>
  </CardIcon>
);

export default memo(In0007);
