import { CardTypeValues } from '@kitted/models';

import typographyConstants from '../../Typography/constants';
import { TypographyMaxLines } from '../../Typography/types';
import { cardTypeToMaxLinesMap } from './constants';

export const getMaxLinesByType = (
  type: CardTypeValues,
  isFeatured: boolean,
  useDefaultMaxLines: boolean
): TypographyMaxLines => {
  if (cardTypeToMaxLinesMap[type]) {
    return cardTypeToMaxLinesMap[type][
      isFeatured && !useDefaultMaxLines ? 'featured' : 'default'
    ];
  }
  return 3;
};

export const getVariantByType = (
  isHover: boolean,
  isFeatured: boolean
): keyof typeof typographyConstants => {
  if (isFeatured) {
    return 'cardBodyFeatured';
  }
  return `cardBody${isHover ? 'Hover' : ''}`;
};
