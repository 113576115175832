import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../base';
import { notBoth } from '../../../zodHelpers';
import { ImageAssetModelSchema } from '../../asset-service';
import {
  AnyPageBlockLoadedModelSchema,
  AnyPageBlockUnloadedModelSchema,
} from '../PageBlocks';
import { PageSlugSchema } from './PageSlug';

export const PageLoadedModelSchema = KittedVersionedModelBaseSchema.merge(
  z.object({
    slug: PageSlugSchema,
    content: z.string(),

    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    pageBlocks: makeKittedModelReferenceSchema(
      AnyPageBlockLoadedModelSchema,
      true
    ).array(),
  })
);

export const PageUnloadedModelSchema = PageLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),
    pageBlocks: makeKittedModelReferenceSchema(
      AnyPageBlockUnloadedModelSchema,
      false
    ).array(),
  })
);

export const PageSaveModelSchema = PageUnloadedModelSchema.merge(
  KittedSaveModelBaseSchema
)
  .merge(
    z.object({
      pageBlocks: PageUnloadedModelSchema.shape.pageBlocks.optional(),
      keyImageBlobId: z.string().optional().nullable(),
    })
  )
  .superRefine(notBoth('keyImageBlobId', 'keyImageImageAsset'));

export type PageLoadedModel = z.infer<typeof PageLoadedModelSchema>;
export type PageUnloadedModel = z.infer<typeof PageUnloadedModelSchema>;
export type PageSaveModel = z.infer<typeof PageSaveModelSchema>;

export type PageModel<IsLoaded extends boolean = false> = IsLoaded extends true
  ? PageLoadedModel
  : PageUnloadedModel;
