import { EMPTY_OBJ } from '@kitted/utils';

import { CardSubType } from '../types';
import { cardTypeToComponentMap } from './constants';
import { CardFooterProps } from './types';

export const getCardFooterComponentByType = (
  type: CardFooterProps['type'],
  subType: CardSubType | undefined,
  isFeatured: boolean
) => {
  if (cardTypeToComponentMap[type]) {
    const { component, featuredComponent, componentProps } =
      cardTypeToComponentMap[type];
    return {
      Component: isFeatured ? featuredComponent : component,
      componentProps: {
        background: componentProps[subType || 'background'],
      },
    };
  }
  return {
    Component: null,
    componentProps: EMPTY_OBJ,
  };
};
