import { z } from 'zod';

import {
  KittedModelBaseSchema,
  KittedSaveModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import { DateISOSchema } from '../../../../Date';
import {
  AnyCMSContentBlockCarouselItemLoadedModelSchema,
  AnyCMSContentBlockCarouselItemUnloadedModelSchema,
} from './AnyCMSContentBlockCarouselItemModel';
import { CMSContentBlockTheme } from './Enum';

export const CMSContentBlockCarouselLoadedModelSchema =
  KittedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(CMSContentBlockTheme),
      items: makeKittedModelReferenceSchema(
        AnyCMSContentBlockCarouselItemLoadedModelSchema,
        true
      ).array(),

      createdByAuthorId: z.string(),
      createdByUserId: z.string(),

      createdAt: DateISOSchema,
      updatedAt: DateISOSchema,
    })
  );

export const CMSContentBlockCarouselUnloadedModelSchema =
  CMSContentBlockCarouselLoadedModelSchema.merge(
    z.object({
      items: makeKittedModelReferenceSchema(
        AnyCMSContentBlockCarouselItemUnloadedModelSchema,
        false
      ).array(),
    })
  );

export const CMSContentBlockCarouselSaveModelSchema =
  CMSContentBlockCarouselUnloadedModelSchema.merge(KittedSaveModelBaseSchema);

export type CMSContentBlockCarouselLoadedModel = z.infer<
  typeof CMSContentBlockCarouselLoadedModelSchema
>;
export type CMSContentBlockCarouselUnloadedModel = z.infer<
  typeof CMSContentBlockCarouselUnloadedModelSchema
>;
export type CMSContentBlockCarouselSaveModel = z.infer<
  typeof CMSContentBlockCarouselSaveModelSchema
>;

export type CMSContentBlockCarouselModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? CMSContentBlockCarouselLoadedModel
    : CMSContentBlockCarouselUnloadedModel;
