import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0031: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 116 78"
		height={78}
		width={116}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M32.5 25.84v35.88M49.5 25.84v35.88M66.5 25.84v35.88M83.49 25.84v35.88" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M114.58 76.66H1.42" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M23.95 25.29h68.14v-7.37L58.03 1.71 23.95 17.9v7.39z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M108.59 68.45H7.41" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M15.53 48.37v13.35M25.45 38.91H9.12v8.69h16.33M100.51 48.37v13.35M90.55 38.91h16.32v8.69H90.55" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0031);
