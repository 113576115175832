import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ClockArrowBack: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 32 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m14.82 15.02-3.28 2.79M15.91 6.49v6.13M15.11 26.08c.26.02.51.02.77.02 1.73 0 3.4-.36 4.96-1.06M10.12 24.64c1.07.58 2.23 1 3.42 1.23M6.91 22.12a12.4 12.4 0 0 0 2.03 1.8M17.29 14.03c0 .77-.63 1.4-1.4 1.4s-1.4-.63-1.4-1.4.63-1.4 1.4-1.4 1.4.63 1.4 1.4Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M22.14 24.37c3.51-2.12 5.85-5.97 5.85-10.37 0-6.69-5.42-12.11-12.11-12.11S3.78 7.31 3.78 14v.46H1.81l3.2 4.78 3.2-4.78H6.22V14.01c0-5.34 4.33-9.67 9.67-9.67s9.67 4.33 9.67 9.67-4.33 9.67-9.67 9.67c-3.28 0-6.17-1.63-7.92-4.12" />
    </g>
  </WebsiteIcon>
);

export default memo(ClockArrowBack);
