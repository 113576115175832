import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import typographyConstants from '../../Typography/constants';
import { cardTypeToMaxLinesMap } from '../CardBody/constants';
import { overlayBorderStyles } from '../styles';

const cardBodyPaddingTop = 14;

export default createUseStyles(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      '&:after': {
        ...overlayBorderStyles(),
      },
    },
    containerActive: {
      '& $cardBodyContainer': {
        maxHeight: `calc(${typographyConstants.cardBodyHover.lineHeight} * ${
          cardTypeToMaxLinesMap.subject.default
        } + ${styling.utils.px(cardBodyPaddingTop)})`,
      },
      '&:before': {
        opacity: 1,
      },
    },
    videoContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundSize: 'cover',
    },
    videoPlayerContainer: {
      height: '100%',
      margin: 0,
      '--videoEmbedAspectRatio': '3 / 4',
    },
    mediaPlayer: {
      height: 'inherit',
    },
    actionButtonTitle: {
      zIndex: 1,
      position: 'absolute',
      display: 'inline-flex',
      minWidth: 185,
      alignItems: 'center',
      gap: 25,
      borderRadius: '100rem',
      height: 46,
      textDecoration: 'none',
      cursor: 'pointer',
      outline: 'none',
      overflow: 'hidden',
      transition: styling.transitions.utils.generate(
        ['color', styling.speeds.jog],
        ['border-color', styling.speeds.jog],
        ['background', styling.speeds.jog]
      ),
      color: styling.colors.context.color,
      border: `1px solid ${styling.colors.context.inverted.background}`,
      margin: [20, 40],
      paddingInline: 36,
      fontSize: '1.6rem',
      textAlign: 'center',
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: 'center',
      fontWeight: styling.fonts.theme.atlasGrotesk.weights.bold,
      backgroundColor: styling.colors.theme.primary1000,
    },
    actionButtonTitleHover: {
      backgroundColor: styling.colors.context.inverted.background,
      color: styling.colors.context.inverted.color,
    },
  },
  { name: 'displayCard' }
);
