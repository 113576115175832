import { z } from 'zod';

import {
  ContentBlockCardGridLoadedModelSchema,
  ContentBlockCardGridUnloadedModelSchema,
  ContentBlockTextModelSchema,
  ContentBlockVideoLoadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
} from '../../x-service';

export const AnyKitBlockLoadedModelSchema = z.union([
  ContentBlockCardGridLoadedModelSchema,
  ContentBlockVideoLoadedModelSchema,
  ContentBlockTextModelSchema,
]);

export const AnyKitBlockUnloadedModelSchema = z.union([
  ContentBlockCardGridUnloadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
  ContentBlockTextModelSchema,
]);

export type AnyKitBlockLoadedModel = z.infer<
  typeof AnyKitBlockLoadedModelSchema
>;
export type AnyKitBlockUnloadedModel = z.infer<
  typeof AnyKitBlockUnloadedModelSchema
>;

export type AnyKitBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AnyKitBlockLoadedModel : AnyKitBlockUnloadedModel;
