import { z } from 'zod';

import {
  ContentBlockCardGridLoadedModelSchema,
  ContentBlockCardGridUnloadedModelSchema,
  ContentBlockTextModelSchema,
} from '../../x-service';

export const AnyAuthorBlockLoadedModelSchema = z.union([
  ContentBlockCardGridLoadedModelSchema,
  ContentBlockTextModelSchema,
]);

export const AnyAuthorBlockUnloadedModelSchema = z.union([
  ContentBlockCardGridUnloadedModelSchema,
  ContentBlockTextModelSchema,
]);

export type AnyAuthorBlockLoadedModel = z.infer<
  typeof AnyAuthorBlockLoadedModelSchema
>;
export type AnyAuthorBlockUnloadedModel = z.infer<
  typeof AnyAuthorBlockUnloadedModelSchema
>;

export type AnyAuthorBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyAuthorBlockLoadedModel
    : AnyAuthorBlockUnloadedModel;
