import { memo } from 'react';
import { CardType } from '@kitted/models';

import Typography from '../../Typography';
import useCardSlug from './hooks/useCardSlug';
import { CardSlugProps } from './types';

const cardTypesWithNoSlug = [
  CardType.Display,
  CardType.Resource,
  'add',
  'route',
];

const CardSlug = ({
  className,
  children,
  isFeatured,
  type,
  noPrefix,
}: CardSlugProps) => {
  const { prefix } = useCardSlug(isFeatured);

  if (type && cardTypesWithNoSlug.includes(type)) return null;

  return (
    <Typography
      className={className}
      variant={`cardSlug${isFeatured ? 'Featured' : ''}`}
    >
      {!noPrefix && prefix}
      {children}
    </Typography>
  );
};

export default memo(CardSlug);
