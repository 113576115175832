import { z } from 'zod';

import { KittedModelBaseSchema } from '../../../../base';
import { EmailBlockType } from './Enum/EmailBlockType';

export const EmailTextModelSchema = KittedModelBaseSchema.merge(
  z.object({
    content: z.string(),
    type: z.literal(EmailBlockType.Text),
  })
);

export type EmailTextModel = z.infer<typeof EmailTextModelSchema>;
