import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0023: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 112 118"
		height={118}
		width={112}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M99.2 116.18V96.7" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M8.67 93.99v22.04" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M60.2 57.96c-3.04 2.92-4.11 6.19-4.11 9.34v27.71" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M49.81 21.28c0 10.96-8.89 19.86-19.86 19.86s-19.86-8.89-19.86-19.86S18.98 1.42 29.95 1.42s19.86 8.89 19.86 19.86Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M74.84 62.54c10.36 0 18.75-8.39 18.75-18.75S85.2 25.04 74.84 25.04s-18.75 8.39-18.75 18.75 8.4 18.75 18.75 18.75ZM58.62 116.09c39.2.21 48.12-29.03 48.88-48.6" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M87.25 81.6h0c-2.72 8.59-10.2 20.23-28.13 20.45" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M50.6 48.33c-2.63-.47-6.6-1.01-9.19-.91l-8.51 8.77-11.45-8.78C9.23 46.09 1.11 55.7 1.11 65.97c0 19.56 11.38 48.3 45.64 50.62 4.31 0 7.89-1.62 8.58-5.72.79-4.77-.7-9.5-5.44-9.93C34.3 99.52 25.31 88.7 22.3 79.23h0M93.38 67.65c2.11 1.76 4.71 2.57 7.19 2.57s4.76-.78 6.39-2.21c1.24-1.09 2.01-2.52 2.43-3.18 3.49-5.48.34-7.89-4.21-11.71-2.89-2.43-5.64-4.64-8.15-4.64-.99 0-1.95.35-2.88 1.16-.76.68-.94 1.06-2.1 2.86-3.14 4.9-3.23 11.36 1.34 15.16Z" />
    </g>
  </CardIcon>
);

export default memo(Fr0023);
