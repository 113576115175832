import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0058: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 114 124"
		height={124}
		width={114}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M67.16 99.24a38.254 38.254 0 0 1-10.45 7.68c-18.98 9.51-42.08 1.83-51.59-17.15-9.51-18.98-1.84-42.07 17.14-51.59 18.98-9.51 42.07-1.83 51.59 17.14 3.01 6 4.29 12.43 4.06 18.7M17.54 109.11l-.73 14M65.93 122.37l-49.12.74M65.57 107.15l.36 15.22" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M43.05 25.14c-.58-1.85.46-3.85 2.31-4.43l62.35-19.65c1.85-.59 3.85.45 4.43 2.3l.66 2.11c.58 1.85-.45 3.85-2.31 4.43L48.14 29.56c-1.85.58-3.85-.45-4.43-2.31l-.67-2.11ZM67.52 23.54l-2.79-8.83" /><circle vectorEffect="non-scaling-stroke" cx="83.17" cy="60.55" r=".93" stroke={spot} /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M80.52 26.97v4.54M80.52 36.69v4.55M87.65 34.1h-4.54M77.93 34.1h-4.54" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M77.93 81.74v4.54M77.93 91.46V96M85.06 88.87h-4.54M75.34 88.87H70.8" /></g><circle vectorEffect="non-scaling-stroke" cx="25.69" cy="26.73" r=".93" stroke={spot} />
    </g>
  </CardIcon>
);

export default memo(Sn0058);
