import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0003: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 98 96"
		height={96}
		width={98}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M57.88 94.89H35.57s-.09-.04-.09-.09.04-.09.09-.09h22.31s.09.04.09.09-.04.09-.09.09ZM41.72 83.74H19.41s-.09-.04-.09-.09.04-.09.09-.09h22.31s.09.04.09.09-.04.09-.09.09ZM33.76 72.58H11.45s-.09-.04-.09-.09.04-.09.09-.09h22.31s.09.04.09.09-.04.09-.09.09Z" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M57.88 94.89s-.09-.04-.09-.09.04-.09.09-.09c21.48 0 38.95-17.47 38.95-38.95S79.36 16.81 57.88 16.81 18.93 34.29 18.93 55.76c0 1.85.13 3.72.4 5.56 0 .05-.03.09-.07.1-.05 0-.09-.03-.1-.07-.27-1.85-.4-3.73-.4-5.59 0-21.57 17.55-39.13 39.13-39.13s39.13 17.55 39.13 39.13-17.55 39.13-39.13 39.13Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M87.91 21s-.09-.03-.12 0-.03.09 0 .12l2.31 2.31-4.67 4.67s-.03.09 0 .12c.02.02.04.03.06.03s.04 0 .06-.03l4.67-4.67 2.31 2.31s.04.03.06.03.04 0 .06-.03c.03-.03.03-.09 0-.12l-4.73-4.73ZM27.97 21.12s.03-.09 0-.12-.09-.03-.12 0l-4.73 4.73s-.03.09 0 .12c.02.02.04.03.06.03s.04 0 .06-.03l2.31-2.31 4.67 4.67s.04.03.06.03.04 0 .06-.03c.03-.03.03-.09 0-.12l-4.67-4.67 2.31-2.31Z" /><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M53.42 10.03h8.92v6.69h-8.92z" /><path vectorEffect="non-scaling-stroke" d="M49.95 1.11H65.8c.55 0 1 .45 1 1v6.93c0 .55-.45 1-1 1H49.95c-.55 0-1-.45-1-1V2.1c0-.55.45-1 1-1Z" /></g><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M61.83 59.71a5.577 5.577 0 0 1-7.89 0 5.577 5.577 0 0 1 0-7.89 5.577 5.577 0 0 1 7.89 0 5.577 5.577 0 0 1 0 7.89Z" /><path vectorEffect="non-scaling-stroke" d="M61.83 51.91s-.04 0-.06-.03a.091.091 0 0 1 0-.12l15.77-15.77s.09-.03.12 0 .03.09 0 .12L61.89 51.88s-.04.03-.06.03ZM50.78 62.95s-.04 0-.06-.03a.091.091 0 0 1 0-.12l3.16-3.16s.09-.03.12 0 .03.09 0 .12l-3.15 3.15s-.04.03-.06.03Z" /></g></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M.62 16.72v78" />
    </g>
  </CardIcon>
);

export default memo(Ac0003);
