import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0051: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 132 60"
		height={60}
		width={132}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M102.67 11.52v39.97l13.88-12.55 13.88 12.55V8.65M129.58 8.65H98.44" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M4.43 23.98c3.28 1.25 6.65 3.29 6.11 6.33-1.01 5.73-4.72 8.9-7.7 17.3-2.98 8.4.18 11.25.18 11.25h24.97s-2.55-2.91-1.5-6.47c1.05-3.56 6.87-23.69 6.87-23.69s.99-2.49 2.54-3.32c1.27-.68 4.89-.75 4.89-.75h46.57v-6.6M93.72 4.93H61.59v2.49c0 1.37-1.5 2.49-3.33 2.49h-8.31c-1.83 0-3.33-1.12-3.33-2.49V4.93H11.67S7.73 6.31 5 17.46h87.94l1.2-12.53V1.14" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m54.4 24.88-1.3 6.71c-.66 3.67-2.86 5.99-6.16 5.99h-9.49M36.93 25.6s1.24 3.39 5.19 5.65" />
    </g>
  </CardIcon>
);

export default memo(Sn0051);
