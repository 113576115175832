import { z } from 'zod';

import { makeKittedModelReferenceSchema } from '../../../../base';
import {
  CardLoadedModelSchema,
  CardType,
  CardUnloadedModelSchema,
} from '../Card';

export const AuthorCardBaseModelSchema = z.object({
  cardType: z.literal(CardType.Author),
  firstName: z.string(),
  lastName: z.string(),
  description: z.string(),
  relatedCards: makeKittedModelReferenceSchema(
    CardLoadedModelSchema,
    true
  ).array(),
  website: z.string().url().optional(),
  linkedIn: z.string().optional(),
});

export const AuthorCardLoadedModelSchema = CardLoadedModelSchema.merge(
  AuthorCardBaseModelSchema
);
export const AuthorCardUnloadedModelSchema = CardUnloadedModelSchema.merge(
  AuthorCardBaseModelSchema.merge(
    z.object({
      relatedCards: makeKittedModelReferenceSchema(
        CardUnloadedModelSchema,
        false
      ).array(),
    })
  )
);
export type AuthorCardLoadedModel = z.infer<typeof AuthorCardLoadedModelSchema>;
export type AuthorCardUnloadedModel = z.infer<
  typeof AuthorCardUnloadedModelSchema
>;

export type AuthorCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AuthorCardLoadedModel : AuthorCardUnloadedModel;
