import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0050: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 122"
		height={122}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M45.82 77.36v10.93M88.41 45.47H77.49M2.4 37.44C5.63 19.84 19.3 5.9 36.75 2.26M68.7 26.93 45.92 49.7 31.08 35.66" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M88.72 55.98a44.41 44.41 0 0 0 1.26-10.51c0-24.38-19.76-44.14-44.16-44.14v12.25M13.69 45.47H1.66c0 24.38 19.76 44.14 44.15 44.14 3.79 0 7.47-.48 10.98-1.37" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M100.68 93.75V83.36c0-10.36-7.89-18.83-17.54-18.83S65.6 73.01 65.6 83.36v10.39s.78 7.85-7.65 7.85v9.22h50.39v-9.22c-8.44 0-7.65-7.85-7.65-7.85ZM76.16 118.1c1.88 1.6 4.31 2.57 6.97 2.57h.02c2.66 0 5.1-.97 6.98-2.57" /></g>
    </g>
  </CardIcon>
);

export default memo(In0050);
