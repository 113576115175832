import React, { memo } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import { EMPTY_OBJ } from '@kitted/utils';

import useFade from './hooks/useFade';
import { FadeProps } from './types';

const defaultStyle = (duration: number) => ({
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
});

const transitionStyles: {
  [key in TransitionStatus]: React.CSSProperties;
} = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

const Fade = React.forwardRef(
  (
    {
      className,
      durationIn = 1000,
      durationOut = 1000,
      appear,
      in: inProp,
      children,
      onClick,
      onExited,
      unmountOnExit = true,
      style = EMPTY_OBJ,
    }: FadeProps,
    ref: React.ComponentPropsWithRef<'div'>['ref']
  ) => {
    const { nodeRef, setNodeRef } = useFade(ref);

    return (
      <Transition
        appear={appear}
        in={inProp}
        nodeRef={nodeRef}
        timeout={{
          enter: durationIn,
          exit: durationOut,
        }}
        unmountOnExit={unmountOnExit}
        onExited={onExited}
      >
        {(state) => (
          <div
            ref={setNodeRef}
            className={className}
            onClick={onClick}
            style={{
              ...style,
              ...defaultStyle(inProp ? durationIn : durationOut),
              ...transitionStyles[state],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    );
  }
);

Fade.displayName = 'Fade';

export default memo(Fade);
