import { z } from 'zod';

export const UserForgetPasswordSchema = z.object({
  email: z.string().email(),
});

export type UserForgetPassword = z.infer<typeof UserForgetPasswordSchema>;

export const UserConfirmForgetPasswordSchema = z.object({
  email: z.string().email(),
  code: z.string(),
  password: z.string(),
});

export type UserConfirmForgetPassword = z.infer<
  typeof UserConfirmForgetPasswordSchema
>;
