import { z } from 'zod';

import { KittedModelBaseSchema } from '../../base';
import { UserModelSchema } from '../auth-service';
import { InviteType } from './Enum';

export const InviteModelSchema = KittedModelBaseSchema.merge(
  z.object({
    userId: UserModelSchema.shape.id,
    maxInvites: z.number(),
    inviteCode: z.string(),
    invitesRedeemed: z.number(),
    inviteType: z.nativeEnum(InviteType),
  })
);

export type InviteModel = z.infer<typeof InviteModelSchema>;
