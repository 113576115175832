import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0037: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 126"
		height={126}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M75.15 98.52c16.46-8.44 27.76-25.59 27.76-45.33 0-28.07-22.83-50.9-50.9-50.9S1.1 25.12 1.1 53.19c0 19.83 11.4 37.05 27.99 45.45" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M22.91 98.83h56.42c2.35 0 4.25 1.9 4.25 4.25v8.19H18.66v-8.19c0-2.35 1.9-4.25 4.25-4.25ZM12.83 111.27h76.58c2.35 0 4.25 1.9 4.25 4.25v8.19H8.58v-8.19c0-2.35 1.9-4.25 4.25-4.25Z" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M11.38 43.43c4.39-18.35 20.9-32 40.59-32" /><circle vectorEffect="non-scaling-stroke" cx="10.49" cy="50.07" r=".39" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M69.09 47.29 51.12 31.72 33.15 47.29M33.15 60.97l17.97 15.57 17.97-15.57" />
    </g>
  </CardIcon>
);

export default memo(In0037);
