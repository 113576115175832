import { useMemo } from 'react';
import { CardTypeValues } from '@kitted/models';

import { getMaxLinesByType, getPrefixByType } from '../../logic';

const useCardTitle = (
  type: CardTypeValues,
  isFeatured = false,
  useDefaultMaxLines = false
) => {
  const maxLines = useMemo(
    () => getMaxLinesByType(type, isFeatured, useDefaultMaxLines),
    [type, isFeatured, useDefaultMaxLines]
  );

  const prefix = useMemo(() => getPrefixByType(type), [type]);

  return {
    prefix,
    maxLines,
  };
};

export default useCardTitle;
