import { memo } from 'react';
import clsx from 'clsx';

import useStyles from './styles';
import { CardGridProps } from './types';

const CardGrid = ({
  className,
  children,
  onlyWholeRows,
  needsFixerRow = true,
}: CardGridProps) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        className,
        styles.container,
        needsFixerRow && styles.containerHiddenOverflow,
        onlyWholeRows && styles.onlyWholeRows
      )}
    >
      <div className={clsx(styles.grid, needsFixerRow && styles.chromeFixer)}>
        {needsFixerRow && <div className={styles.fixerRow} />}
        {children}
      </div>
    </div>
  );
};

export default memo(CardGrid);
