import { z } from 'zod';

import { KittedModelBaseSchema } from '../../../base';
import { DateISOSchema } from '../../../Date';
//  import { CustomerModelSchema } from '../CustomerModel/CustomerModel';
//  import { ProductModelSchema } from '../ProductModel';
import { OrderStatus } from './Enum';

export const OrderModelSchema = KittedModelBaseSchema.merge(
  z.object({
    // customerId: CustomerModelSchema.shape.id,

    pricePaid: z.number().positive().optional(),

    products: z.array(
      z.object({
        // productId: ProductModelSchema.shape.id,
        quantity: z.number().positive(),
        sku: z.string(),
        price: z.number(),
        name: z.string(),

        // paidFor: z.boolean().optional(),
        // refunded: z.boolean().optional(),
      })
    ),

    customer: z.object({
      id: z.string(),

      firstName: z.string().optional(),
      lastName: z.string().optional(),
      email: z.string().optional(),
    }),

    dateOfPurchase: DateISOSchema,
    updatedAt: DateISOSchema,

    status: z.nativeEnum(OrderStatus),

    numberOfInvites: z.number().nonnegative(),
  })
);

export type OrderModel = z.infer<typeof OrderModelSchema>;
