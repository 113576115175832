import {
  ArticleCardModel,
  ArticleModel,
  CardType,
  KittedModelTypeEnum,
  KittedVersionStatusTypeEnum,
  PublishType,
} from '../../../../models';

export const convertArticleModelToArticleCardModel = (
  id: ArticleModel['id'],
  article: Omit<ArticleModel, 'id'>,
  oldCard: Partial<ArticleCardModel> = {}
): ArticleCardModel => ({
  ...oldCard,

  id,
  slug: article.slug,

  kittedModelType: KittedModelTypeEnum.CardArticle,

  cardType: CardType.Article,
  createdAt: oldCard?.createdAt || article.createdAt,
  updatedAt: article.updatedAt,
  title: article.title,
  description: article.description,
  keyImageImageAsset: article.keyImageImageAsset,
  createdByAuthorId: article.createdByAuthorId,

  excludeFromDiscovery:
    oldCard?.excludeFromDiscovery ??
    article.cmsSrcMessage?.excludeFromDiscovery ??
    true,
  live: oldCard?.live ?? article.cmsSrcMessage?.live ?? true,

  publishSettings: {
    publishType: PublishType.Private,
  },
  versionStatus: article.versionStatus,
  publishedAt:
    article.versionStatus === KittedVersionStatusTypeEnum.Published
      ? article.updatedAt
      : undefined,
});
