import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const PlayDown: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 18 18"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" fill={color} d="m10.73 15.16 6.14-10.63c.77-1.33-.19-3-1.73-3H2.86c-1.54 0-2.5 1.67-1.73 3l6.14 10.63c.77 1.33 2.69 1.33 3.46 0Z" />
    </g>
  </WebsiteIcon>
);

export default memo(PlayDown);
