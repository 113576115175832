import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Droplet: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m4.94 8.94-1.3 1.66h0a9.531 9.531 0 0 0-2.18 6.08c0 5.28 4.27 9.56 9.54 9.56s9.54-4.28 9.54-9.56c0-2.29-.8-4.39-2.14-6.03h0l-6.6-8.51a.968.968 0 0 0-1.53 0L7.4 5.81m5.58 16.89a6.33 6.33 0 0 0 4.34-6.01m-6.31 6.32h0" />
    </g>
  </WebsiteIcon>
);

export default memo(Droplet);
