import {
  AnyCardModel,
  AnyCardWithRelatedCardsWithoutToolsModel,
  CardType,
} from '../../../models';

const cardTypesWithRelatedCardsWithoutTools = [
  CardType.Kit,
  CardType.Author,
  CardType.Subject,
];

export function isCardWithRelatedCardsWithoutTools(
  card: AnyCardModel
): card is AnyCardWithRelatedCardsWithoutToolsModel {
  return cardTypesWithRelatedCardsWithoutTools.includes(card.cardType);
}
