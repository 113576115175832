import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import { DateISOSchema } from '../../../../Date';
import { ImageAssetModelSchema } from '../../../asset-service';
import { CardLoadedModelSchema } from '../../../cards-service';
import {
  CMSFlowStepDisplayType,
  CMSFlowStepElements,
  CMSFlowStepTextAlignment,
} from './Enum';

export const CMSFlowStepLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    title: z.string(),
    content: z.string().optional(),
    alignment: z.nativeEnum(CMSFlowStepTextAlignment),
    elementVisibility: z.nativeEnum(CMSFlowStepElements).array(),
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    imageIsInset: z.boolean().optional(),
    imageIsFaded: z.boolean().optional(),
    buttonText: z.string().optional(),
    buttonLink: z.string().optional(),
    card: makeKittedModelReferenceSchema(
      CardLoadedModelSchema,
      false
    ).optional(),

    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,

    cmsFlowStepDisplay: z.nativeEnum(CMSFlowStepDisplayType),
  })
);

export const CMSFlowStepUnloadedModelSchema =
  CMSFlowStepLoadedModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
    })
  );

export type CMSFlowStepLoadedModel = z.infer<
  typeof CMSFlowStepLoadedModelSchema
>;
export type CMSFlowStepUnloadedModel = z.infer<
  typeof CMSFlowStepUnloadedModelSchema
>;

export type CMSFlowStepModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? CMSFlowStepLoadedModel : CMSFlowStepUnloadedModel;
