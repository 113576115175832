import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0060: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 104"
		height={104}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M11 40.73c1.55-7 6.56-7.49 8.42-8.27 2.77-1.23 5.58-3.17 5.58-7.75v-4.16C25 9.91 33.63 1.28 44.27 1.28s19.27 8.63 19.27 19.27v5.83c.06 4.08-.78 8.13-2.47 11.85" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M49.82 16.67c.01.77-.61 1.41-1.38 1.42h-.03c-.78 0-1.42-.64-1.42-1.42 0-.78.64-1.42 1.42-1.42.77 0 1.4.62 1.41 1.39v.03ZM68.05 95.23c3.75 3.43 7.88 5.47 12.19 5.39 12.21-.24 22.33-17.39 26.31-34.47l-7.43-7.78-7.65 5-5.86-9.65-5.88 4.69" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M69.13 42.48 58.26 56.03l-9.39-9L31.1 61.46l-8.76-13-9 6.44-10.41-9.67a75.2 75.2 0 0 0-1.47 15.48c.46 24.36 16.43 42.38 35.77 42s34.76-19 34.3-43.34c-.17-5.7-.97-11.37-2.4-16.89Z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m31.33 60.59 1.68 12.59-6.12 4.68" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m63.66 15.06 7.48-3.36M71.87 20.27l-7.64-1.45" />
    </g>
  </CardIcon>
);

export default memo(In0060);
