import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      backgroundColor: styling.colors.context.background,
      padding: styling.sizes.theme.spacing.md,
      borderBottomLeftRadius: styling.sizes.theme.spacing['2xs'],
      borderBottomRightRadius: styling.sizes.theme.spacing['2xs'],
      margin: [
        styling.sizes.theme.spacing.md,
        -1 * styling.sizes.theme.spacing.md,
        -1 * styling.sizes.theme.spacing.md,
      ],
      textAlign: 'left',
    },
  },
  { name: 'panelFooter' }
);
