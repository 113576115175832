import { z } from 'zod';

import {
  ContentBlockCardGridLoadedModelSchema,
  ContentBlockCardGridUnloadedModelSchema,
  ContentBlockCarouselLoadedModelSchema,
  ContentBlockCarouselShallowViewModelSchema,
  ContentBlockCarouselUnloadedModelSchema,
  ContentBlockImageLoadedModelSchema,
  ContentBlockImageUnloadedModelSchema,
  ContentBlockVideoLoadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
} from '../../x-service';

export const AnyPageBlockLoadedModelSchema = z.union([
  ContentBlockCarouselLoadedModelSchema,
  ContentBlockImageLoadedModelSchema,
  ContentBlockVideoLoadedModelSchema,
  ContentBlockCardGridLoadedModelSchema,
]);

export const AnyPageBlockUnloadedModelSchema = z.union([
  ContentBlockCarouselUnloadedModelSchema,
  ContentBlockImageUnloadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
  ContentBlockCardGridUnloadedModelSchema,
]);

export type AnyPageBlockLoadedModel = z.infer<
  typeof AnyPageBlockLoadedModelSchema
>;
export type AnyPageBlockUnloadedModel = z.infer<
  typeof AnyPageBlockUnloadedModelSchema
>;

export type AnyPageBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AnyPageBlockLoadedModel : AnyPageBlockUnloadedModel;

export const AnyPageBlockShallowViewModelSchema = z.union([
  ContentBlockCarouselShallowViewModelSchema,
  ContentBlockImageUnloadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
  ContentBlockCardGridUnloadedModelSchema,
]);

export type AnyPageBlockShallowViewModel = z.infer<
  typeof AnyPageBlockShallowViewModelSchema
>;
