import { z } from 'zod';

import { KittedModelBaseSchema } from '../../base/KittedModelBase';
import { AssetSource, AssetType } from './Enum';

export const AssetBaseSchema = KittedModelBaseSchema.merge(
  z.object({
    assetType: z.nativeEnum(AssetType),
    assetSource: z.nativeEnum(AssetSource),

    filename: z.string(),
    title: z.string(),

    description: z.string(),
    contentType: z.string(),

    userId: z.string(),
    authorId: z.string(),
  })
);

export type AssetBase = z.infer<typeof AssetBaseSchema>;
