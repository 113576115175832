/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  chunk as lodashChunk,
  uniq as lodashUniq,
  uniqBy as lodashUniqBy,
} from 'lodash';

export const replaceAtIndex = (arr: any[], el: any, index: number) => [
  ...arr.slice(0, index),
  el,
  ...arr.slice(index + 1),
];

export function* askReplaceAtIndex(arr: any[], el: any, index: number) {
  return replaceAtIndex(arr, el, index);
}

export const insertAtIndex = (arr: any[], el: any, index: number) => [
  ...arr.slice(0, index),
  el,
  ...arr.slice(index),
];

export function* askInsertAtIndex(arr: any[], el: any, index: number) {
  return insertAtIndex(arr, el, index);
}

export const swap = (array: any[], moveIndex: number, toIndex: number) => {
  /* #swap - Moves an array item from one position in an array to another.

     Note: This is a pure function so a new array will be returned, instead
     of altering the array argument.

    Arguments:
    1. array     (String) : Array in which to move an item.         (required)
    2. moveIndex (Object) : The index of the item to move.          (required)
    3. toIndex   (Object) : The index to move item at moveIndex to. (required)
  */
  const item = array[moveIndex];
  const { length } = array;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  }
  if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
};

export const removeFalseyFromArray = <T = any>(array: any[]): T[] =>
  array.filter((item) => !!item);

export const chunk = lodashChunk;
export const uniq = lodashUniq;
export const uniqBy = lodashUniqBy;
