import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const LetterI: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 16 18"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" fill={color} d="m1.09 16.78.49-2.24h3.91L7.87 3.47H3.94l.47-2.24h10.51l-.49 2.24h-3.89L8.16 14.54h3.91l-.47 2.24H1.09Z" />
    </g>
  </WebsiteIcon>
);

export default memo(LetterI);
