import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0050: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 80 104"
		height={104}
		width={80}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M78.58 39.92C78.58 18.61 61.31 1.3 40 1.24 18.69 1.19 1.42 18.42 1.42 39.72c0 15.05 8.64 28.08 21.22 34.47v5.38c0 2.13 1.73 3.86 3.86 3.87l27 .07c2.13 0 3.86-1.72 3.86-3.85v-5.38c12.58-6.32 21.22-19.31 21.22-34.36Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M53.5 83.5v5.79c0 2.13-1.73 3.85-3.86 3.85l-19.29-.05a3.87 3.87 0 0 1-3.86-3.87v-5.79l27 .07Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M49.64 93.14c0 5.33-4.32 9.63-9.64 9.62-5.33-.01-9.64-4.34-9.64-9.67l19.29.05Z" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M19.21 30.15v20.11M60.79 30.25v20.12" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M40 53.76v29.71" /><ellipse cx="40" cy="40.26" stroke={spot} rx="13.48" ry="13.52" transform="rotate(-44.96 40.005 40.259)" />
    </g>
  </CardIcon>
);

export default memo(Ac0050);
