import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0047: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 116 108"
		height={108}
		width={116}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m91.46 18.78 5.82-8.42H66.44M96.35 101.63c11.04-5.21 18.67-16.44 18.67-29.46 0-14.86-9.95-27.38-23.56-31.29V18.86M73.43 18.08v22.8c-3.15.91-6.11 2.27-8.79 4.03" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M12.27 106.18h61.97c9.85 0 13.55-6.03 9.17-13.01-4.58-7.3-26.97-41.24-26.97-41.24-1.6-2.48-2.91-6.93-2.91-9.89V11.61" /><path vectorEffect="non-scaling-stroke" d="M73.94 106.18H11.97c-9.85 0-13.55-6.03-9.17-13.01 4.58-7.3 26.97-41.24 26.97-41.24 1.6-2.48 2.91-6.93 2.91-9.89V11.61l-6.13-9.79h32.72" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m22.47 92.21 20.91-31.97M64.88 92.21 43.97 60.24" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M30.25 81.56H57.1" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M91.07 80.29c0 .66-.53 1.2-1.2 1.2s-1.2-.53-1.2-1.2.54-1.2 1.2-1.2 1.2.53 1.2 1.2ZM100.01 86.28c0 .66-.53 1.2-1.2 1.2s-1.2-.53-1.2-1.2.54-1.2 1.2-1.2 1.2.53 1.2 1.2ZM77.7 71.09h30.5" />
    </g>
  </CardIcon>
);

export default memo(Ac0047);
