import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0044: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 122 138"
		height={138}
		width={122}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="m76.58 19.41 19.48 11.28M45.43 19.41 25.94 30.69M76.58 118.59l19.48-11.27M45.43 118.59l-19.49-11.27" /></g><g stroke={color}><circle vectorEffect="non-scaling-stroke" cx="61" cy="10.29" r="8.63" /><path vectorEffect="non-scaling-stroke" d="M61 19.88v13.18" /><circle vectorEffect="non-scaling-stroke" cx="61" cy="127.71" r="8.63" /><path vectorEffect="non-scaling-stroke" d="M61 118.12v-13.18" /></g><g stroke={color}><circle vectorEffect="non-scaling-stroke" cx="111.79" cy="39.55" r="8.63" /><path vectorEffect="non-scaling-stroke" d="m103.49 44.36-11.4 6.61" /><circle vectorEffect="non-scaling-stroke" cx="10.21" cy="39.55" r="8.63" /><path vectorEffect="non-scaling-stroke" d="m18.51 44.36 11.4 6.61" /><circle vectorEffect="non-scaling-stroke" cx="10.21" cy="98.45" r="8.63" /><path vectorEffect="non-scaling-stroke" d="m18.51 93.64 11.4-6.61" /><circle vectorEffect="non-scaling-stroke" cx="111.79" cy="98.45" r="8.63" /><path vectorEffect="non-scaling-stroke" d="m103.49 93.64-11.4-6.61" /></g><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M10.31 57.73v22.54M111.69 57.73v22.54" /></g></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m61 46.71 5.53 17.03h17.91L69.95 74.26l5.53 17.03L61 80.76 46.52 91.29l5.53-17.03-14.49-10.52h17.91L61 46.71z" />
    </g>
  </CardIcon>
);

export default memo(In0044);
