import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import { DateISOSchema } from '../../../../Date';
import { ImageAssetModelSchema } from '../../../asset-service';
import { DisplaySlugSchema } from '../../../slug-service';

export const CMSDisplayLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    slug: DisplaySlugSchema,
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ),
    live: z.boolean(),
    createdByAuthorId: z.string(),
    actionButtonTitle: z.string(),
    buttonUrl: z.string(),
    videoUrl: z.string().optional(),
    title: z.string(),
    description: z.string(),
    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,
    autoPlay: z.boolean(),
  })
);

export const CMSDisplayUnloadedModelSchema = CMSDisplayLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ),
  })
);

export type CMSDisplayLoadedModel = z.infer<typeof CMSDisplayLoadedModelSchema>;
export type CMSDisplayUnloadedModel = z.infer<
  typeof CMSDisplayUnloadedModelSchema
>;

export type CMSDisplayModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? CMSDisplayLoadedModel : CMSDisplayUnloadedModel;
