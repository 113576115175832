import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0023: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 72 128"
		height={128}
		width={72}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><circle vectorEffect="non-scaling-stroke" cx="22.96" cy="13.17" r="10.89" /><path vectorEffect="non-scaling-stroke" d="m43.25 64.02-2.22-22.34c-.42-4.21-4.23-7.65-8.46-7.65H13.34c-4.23 0-8.03 3.44-8.46 7.65L1.61 74.11c-.42 4.21 4.36 7.55 8.36 8.99L11 125.72" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m45.62 76.49 5.85 18.01h18.94l-15.32 11.14 5.85 18.01-15.32-11.13-15.32 11.13 5.85-18.01L20.83 94.5h18.93l5.86-18.01z" />
    </g>
  </CardIcon>
);

export default memo(In0023);
