import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0011: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 112"
		height={112}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="m78.51 111.08-3.76-21.89c-.48-4.83-4.86-8.79-9.72-8.79H42.95c-4.86 0-9.23 3.96-9.71 8.79l-3.76 21.89M66.84 54.02c0 7.09-5.75 12.84-12.84 12.84s-12.85-5.75-12.85-12.84S46.9 41.18 54 41.18s12.84 5.75 12.84 12.84Z" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M107.35 54.02H81.29M26.71 54.02H.65" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M33.97 72.15a26.922 26.922 0 0 1-6.99-18.14c0-14.92 12.1-27.02 27.02-27.02s27.02 12.1 27.02 27.02c0 7.01-2.68 13.4-7.06 18.2" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M23.24 83.04a42.108 42.108 0 0 1-11.52-29.02M13 43.62c3.75-14.82 15.32-26.53 30.06-30.47M96.29 54.02c0 11.32-4.45 21.61-11.7 29.2M54 11.73c19.74 0 36.32 13.53 40.98 31.82" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M54 26.61V.54" />
    </g>
  </CardIcon>
);

export default memo(Fr0011);
