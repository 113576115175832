import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0052: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 82 94"
		height={94}
		width={82}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="m54 32.33 3.79-3.85c6.19-6.19 6.29-16.22.22-22.28-6.07-6.07-16.1-5.97-22.29.22l-3.84 3.8-3.8-3.8C21.89.23 11.87.13 5.8 6.2c-6.07 6.06-5.97 16.09.22 22.28L31.9 54.32" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m80.69 86.32-2-23.61c-1.38-8.33-7.94-8.55-7.94-8.55H50.73l-3.16-19.09c-.47-2.8-3.34-4.67-6.14-4.21-2.8.46-4.92 3.16-4.46 5.96l4.13 25 2.01 12.12-10.82-7.74c-2.3-1.66-5.54-1.15-7.2 1.15l-.46.64c-1.66 2.3-1.15 5.54 1.15 7.2L49.62 92.3" />
    </g>
  </CardIcon>
);

export default memo(Fr0052);
