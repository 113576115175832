import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      padding: styling.sizes.theme.spacing.md,
      borderRadius: styling.sizes.theme.spacing['2xs'],
    },
    'theme-primary': {
      backgroundColor: styling.colors.theme.primary900,
      color: styling.colors.theme.secondary,
      border: [1, 'solid', styling.colors.theme.secondary300_20],
    },
    'theme-secondary': {
      backgroundColor: styling.colors.theme.pureWhite,
      color: styling.colors.theme.primary400,
      border: [1, 'solid', styling.colors.theme.primary900_20],
    },
  },
  { name: 'panel' }
);
