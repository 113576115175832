import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CardInfo: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <rect vectorEffect="non-scaling-stroke" width="19.7" height="25.24" x="1.15" y="1.38" stroke={color} rx="2.57" ry="2.57" /><rect vectorEffect="non-scaling-stroke" width="19.7" height="25.24" x="1.15" y="1.38" stroke={color} rx="2.57" ry="2.57" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M8.25 10.54h2.91v9.14M7.43 19.76h7.42M11.3 6.87a.435.435 0 1 1-.87 0c0-.25.2-.44.44-.44s.43.19.43.44Z" /></g>
    </g>
  </WebsiteIcon>
);

export default memo(CardInfo);
