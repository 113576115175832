import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0025: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 109.8 73.12"
		height={73}
		width={109}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M.38 46.55c0 1.23.52 2.4 1.44 3.21 7.74 6.91 28.41 22.98 53.09 22.98h.9c.62 0 1.24-.03 1.85-.07 23.45-1.12 42.89-16.26 50.34-22.91.91-.82 1.44-1.99 1.44-3.21 0-1.23-.52-2.4-1.44-3.21h0c-7.45-6.65-26.88-21.79-50.34-22.91-.61-.04-1.23-.07-1.85-.07h-.9c-24.68 0-45.35 16.07-53.09 22.98-.91.82-1.44 1.99-1.44 3.21Z" /><circle vectorEffect="non-scaling-stroke" cx="54.9" cy="46.51" r="26.16" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="54.9" cy="46.51" r="9.99" stroke={spot} /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M54.9 9.38V0M79.27 13.36l3.59-8.67M98.98 22.32l6.63-6.63M31.51 13.36l-3.59-8.67M11.8 22.32l-6.64-6.63" />
    </g>
  </CardIcon>
);

export default memo(Ac0025);
