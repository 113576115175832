import { z } from 'zod';

import { KittedModelBaseSchema } from '../../../base';
import { UserModelSchema } from '../../auth-service';
import { MembershipLevel } from './Enum';

// Account is for things related to the USER
// ie, what they favourite, what their preferences are
// is it NOT the things they create
export const AccountLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    userId: UserModelSchema.shape.id,
    // visibility/publishSettings
    // isEnterprise
    membershipLevel: z.nativeEnum(MembershipLevel), // <- synced from billing
    invitedWithInviteCode: z.string().optional(),
    orderId: z.string().optional(),

    country: z.string(),
    timeZoneIANAId: z.string(),
  })
);

export const AccountUnloadedModelSchema = AccountLoadedModelSchema;

export type AccountLoadedModel = z.infer<typeof AccountLoadedModelSchema>;
export type AccountUnloadedModel = z.infer<typeof AccountUnloadedModelSchema>;

export type AccountModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AccountLoadedModel : AccountUnloadedModel;
