import { memo } from 'react';
import clsx from 'clsx';
import { Times } from '@kitted/website-icons';

import useStyles from './styles';
import { PillProps } from './types';

const Pill = ({
  className,
  children,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  onClose,
  buttonText,
  onButtonClick,
  iconOnly = false,
  color = 'primary',
  theme = 'secondary',
  size = 'lg',
}: PillProps) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        className,
        styles.container,
        iconOnly && styles.iconOnly,
        styles[`theme-${theme}`],
        styles[`color-${color}`],
        styles[`size-${size}`]
      )}
    >
      {LeftIcon && (
        <span className={styles.icon}>
          <LeftIcon size="inherit" />
        </span>
      )}
      {!iconOnly && (
        <>
          {children}
          {RightIcon && (
            <span className={styles.icon}>
              <RightIcon size="inherit" />
            </span>
          )}
          {buttonText && onButtonClick && (
            <button
              className={styles.button}
              onClick={onButtonClick}
              type="button"
            >
              {buttonText}
            </button>
          )}
          {onClose && (
            <button
              className={styles.close}
              onClick={onClose}
              type="button"
              aria-label="Close"
            >
              <Times size="inherit" />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default memo(Pill);
