import { z } from 'zod';

import { KittedModelBaseSchema } from '../../base';

export const BlobMetadataSchema = KittedModelBaseSchema.merge(
  z.object({
    uploadUserId: z.string(),

    filename: z.string(),
    mineType: z.string(),
    ownedCount: z.number(),

    public: z.boolean(),
  })
);

export type BlobMetadata = z.infer<typeof BlobMetadataSchema>;
