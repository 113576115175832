import { z } from 'zod';

import { CardDownloadTypeEnum } from '../../cards-service';
import { KittedServiceEnum } from '../../x-service';
import { WebsocketClientMessageType } from './enum';
import { WebsocketClientMessageSchema } from './WebsocketClientMessage';

export const WebsocketDownloadFileClientMessageSchema =
  WebsocketClientMessageSchema.merge(
    z.object({
      messageType: z.literal(WebsocketClientMessageType.DownloadFile),
      downloadType: z.enum([
        'unknown',
        ...Object.values(CardDownloadTypeEnum),
      ] as unknown as [string, ...string[]]),
      service: z.nativeEnum(KittedServiceEnum),
    })
  );

export type WebsocketDownloadFileClientMessage = z.infer<
  typeof WebsocketDownloadFileClientMessageSchema
>;
