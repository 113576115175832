/* eslint-disable @typescript-eslint/no-explicit-any */
import { styling } from '@kitted/design-system';
import { CardType } from '@kitted/models';
import { EMPTY_OBJ } from '@kitted/utils';

import CardFooterStack from './CardFooterStack';
import CardFooterStripe from './CardFooterStripe';
import { CardFooterProps } from './types';

export const cardTypeToComponentMap: {
  [key in CardFooterProps['type']]: {
    component: React.ElementType | null;
    featuredComponent: React.ElementType | null;
    componentProps: {
      [key: string]: any;
    };
  };
} = {
  [CardType.Tool]: {
    component: CardFooterStripe,
    featuredComponent: CardFooterStripe,
    componentProps: {
      action: styling.colors.context.card.footerBackgrounds.action,
      insight: styling.colors.context.card.footerBackgrounds.insight,
      frame: styling.colors.context.card.footerBackgrounds.frame,
      snare: styling.colors.context.card.footerBackgrounds.snare,
    },
  },
  [CardType.Kit]: {
    component: CardFooterStack,
    featuredComponent: null,
    componentProps: EMPTY_OBJ,
  },
  [CardType.Resource]: {
    component: null,
    featuredComponent: null,
    componentProps: EMPTY_OBJ,
  },
  [CardType.Flow]: {
    component: CardFooterStripe,
    featuredComponent: CardFooterStripe,
    componentProps: {
      background: styling.colors.context.card.footerBackgrounds.flow,
    },
  },
  [CardType.Author]: {
    component: null,
    featuredComponent: null,
    componentProps: EMPTY_OBJ,
  },
  [CardType.Article]: {
    component: CardFooterStripe,
    featuredComponent: CardFooterStripe,
    componentProps: {
      background: styling.colors.context.card.footerBackgrounds.article,
    },
  },
  [CardType.Subject]: {
    component: null,
    featuredComponent: null,
    componentProps: EMPTY_OBJ,
  },
  [CardType.Deck]: {
    component: null,
    featuredComponent: null,
    componentProps: EMPTY_OBJ,
  },
  [CardType.Display]: {
    component: null,
    featuredComponent: null,
    componentProps: EMPTY_OBJ,
  },

  // CardTypeValuesWithCustomTypes
  add: {
    component: null,
    featuredComponent: null,
    componentProps: EMPTY_OBJ,
  },
  route: {
    component: null,
    featuredComponent: null,
    componentProps: EMPTY_OBJ,
  },
};
