import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ListBullets: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 24 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M8.59 2.75H22.9M8.59 10.89H22.9M8.59 19.25H22.9M2.37 20.98h1.9c.43 0 .78-.35.78-.78v-1.9c0-.43-.35-.78-.78-.78h-1.9c-.43 0-.78.35-.78.78v1.9c0 .43.35.78.78.78Zm0-8.36h1.9c.43 0 .78-.35.78-.78v-1.9c0-.43-.35-.78-.78-.78h-1.9c-.43 0-.78.35-.78.78v1.9c0 .43.35.78.78.78Zm0-8.14h1.9c.43 0 .78-.35.78-.78V1.8c0-.43-.35-.78-.78-.78h-1.9c-.43 0-.78.35-.78.78v1.9c0 .43.35.78.78.78Z" />
    </g>
  </WebsiteIcon>
);

export default memo(ListBullets);
