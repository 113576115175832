import { z } from 'zod';

import { KittedModelBaseSchema } from '../../../base';
import { DateISOSchema } from '../../../Date';
import { AccountLoadedModelSchema } from '../../account-service';
import { MembershipEventType } from './Enum';

// Account is for things related to the USER
// ie, what they favourite, what their preferences are
// is it NOT the things they create
export const MembershipEventModelSchema = KittedModelBaseSchema.merge(
  z.object({
    accountId: AccountLoadedModelSchema.shape.id,

    appliedAt: DateISOSchema,
    createdAt: DateISOSchema,

    membershipType: z.nativeEnum(MembershipEventType),

    correlationId: z.string().optional(),

    reason: z.string().optional(),
  })
);

export type MembershipEventModel = z.infer<typeof MembershipEventModelSchema>;
