import { z } from 'zod';

import { makeKittedModelReferenceSchema } from '../../../../base';
import {
  CardLoadedModelSchema,
  CardType,
  CardUnloadedModelSchema,
} from '../Card';

export const SubjectCardBaseModelSchema = z.object({
  cardType: z.literal(CardType.Subject),
  title: z.string(),
  description: z.string(),

  relatedCards: makeKittedModelReferenceSchema(
    CardLoadedModelSchema,
    true
  ).array(),
});

export const SubjectCardLoadedModelSchema = CardLoadedModelSchema.merge(
  SubjectCardBaseModelSchema
);
export const SubjectCardUnloadedModelSchema = CardUnloadedModelSchema.merge(
  SubjectCardBaseModelSchema.merge(
    z.object({
      relatedCards: makeKittedModelReferenceSchema(
        CardUnloadedModelSchema,
        false
      ).array(),
    })
  )
);

export type SubjectCardLoadedModel = z.infer<
  typeof SubjectCardLoadedModelSchema
>;
export type SubjectCardUnloadedModel = z.infer<
  typeof SubjectCardUnloadedModelSchema
>;

export type SubjectCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? SubjectCardLoadedModel : SubjectCardUnloadedModel;
