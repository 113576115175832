import { GridColumn } from './types';

const columns: GridColumn[] = [1, 2, 3, 5];
const columnW = 300;
const spacing = {
  '3xs': 6,
  '2xs': 12,
  xs: 18,
  sm: 24,
  md: 36,
  lg: 40,
  xl: 72,
  '2xl': 90,
};

const gutteringSmW = spacing.xs;
const gutteringW = spacing.md;

const topBarH = spacing['2xl'];
const bottomBarH = spacing.xl;
const mobileMenuH = spacing.xl;

const cardW = columnW;
const cardH = 400;
const cardRadius = spacing['3xs'];
const cardPaddingInline = '36px 52px';

const cardFeatureW = cardW * 1.2;
const cardFeatureH = cardH * 1.2;
const cardFeatureRadius = spacing['2xs'];
const cardFeaturePaddingInline = 43;

const iconSizeSm = 18;
const iconSizeMd = 22;
const iconSizeLg = 32;

const tileW = 100;
const tileH = 100;

const gridColumns = (
  cols: number,
  gutteringWidth: number,
  includeEdgeGutters = false
) =>
  columnW * cols +
  (cols - 1) * gutteringWidth +
  (includeEdgeGutters ? 2 * gutteringWidth : 0);

const minGridW = gridColumns(columns[0], gutteringSmW);
const maxGridW = gridColumns(columns[columns.length - 1], gutteringW); // 2,988

export default {
  theme: {
    columns,
    spacing,
  },
  context: {
    grid: {
      minWidth: minGridW,
      maxWidth: maxGridW,
    },
    topBar: {
      height: topBarH,
    },
    bottomBar: {
      height: bottomBarH,
    },
    mobileMenu: {
      height: mobileMenuH,
    },
    drawer: {
      openWidth: 250,
      closedWidth: 48,
    },
    columnW,
    gutteringSmW,
    gutteringW,
    icon: {
      sm: iconSizeSm,
      md: iconSizeMd,
      lg: iconSizeLg,
    },
    card: {
      width: cardW,
      height: cardH,
      radius: cardRadius,
      paddingInline: cardPaddingInline,
    },
    cardFeature: {
      width: cardFeatureW,
      height: cardFeatureH,
      radius: cardFeatureRadius,
      paddingInline: cardFeaturePaddingInline,
    },
    tile: {
      width: tileW,
      height: tileH,
    },
  },
  utils: {
    gridColumns,
  },
};
