import * as CardIcons from '@kitted/card-icons';
import { lowerCase, padStart, startCase } from '@kitted/utils';

export const cardSlugToIconMap: {
  [key: string]: React.ComponentType<CardIcons.CardIconProps>;
} = ['ACTION', 'INSIGHT', 'FRAME', 'SNARE'].reduce((acc, cardSubType) => {
  const subTypeIcons = [...Array(60)].reduce((acc2, _, index) => {
    const paddedNumber = padStart(`${index + 1}`, 4, '0'); // 1 -> 0001
    const key = `${cardSubType}-${paddedNumber}`; // ACTION-0001
    const importKey = `${startCase(lowerCase(cardSubType)).slice(
      0,
      2
    )}${paddedNumber}` as keyof typeof CardIcons; // ACTION -> Ac0001

    return {
      ...acc2,
      [key]: CardIcons[importKey],
    };
  }, {});

  return {
    ...acc,
    ...subTypeIcons,
  };
}, {});
