import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0053: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 128"
		height={128}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M16.19 111.73c0 2.57 2.25 4.65 5.01 4.65s5.02-2.08 5.02-4.65-2.68-3.7-5.03-4.88c-2.35-1.19-5.02-2.31-5.02-4.88s2.24-4.65 5.01-4.65 5.01 2.08 5.01 4.65M21.18 97.29v-3.41M21.18 119.82v-3.41" /><path vectorEffect="non-scaling-stroke" d="M8.59 91.19c2.04-1.64 4.42-2.9 7.09-3.66 10.66-3.04 21.78 3.12 24.82 13.76 3.05 10.65-3.13 21.73-13.79 24.77-10.66 3.04-21.77-3.12-24.82-13.76a19.915 19.915 0 0 1 1.58-14.9M77.78 111.73c0 2.57 2.25 4.65 5.01 4.65s5.02-2.08 5.02-4.65-2.68-3.7-5.03-4.88c-2.35-1.19-5.02-2.31-5.02-4.88s2.24-4.65 5.01-4.65 5.01 2.08 5.01 4.65M82.77 97.29v-3.41M82.77 119.82v-3.41" /><path vectorEffect="non-scaling-stroke" d="M70.19 91.19c2.03-1.64 4.42-2.9 7.09-3.66 10.66-3.04 21.78 3.12 24.82 13.76 3.04 10.65-3.13 21.73-13.8 24.77-10.66 3.04-21.78-3.12-24.82-13.76a19.962 19.962 0 0 1 1.58-14.9" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M52 63.86v7.6" /><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M51.4 10.8s12.92-1.83 12.52 9.98c0 0 3.85 6.79-4.65 6.96 0 0-4.21 6.06-10.88.81" /><path vectorEffect="non-scaling-stroke" d="M36.18 13.61c-.27-1.28.05-4.28 7.74-5.17.72-.08 8.4-1.09 7.65 5.6M39.29 21.22s-1 4.25 1.62 6.65M44.95 20.16s4.23 1.03 7.29-1.39M58.53 19.27s2.98-1.11 5.69 2.43" /><path vectorEffect="non-scaling-stroke" d="M44.12 15.18s-4.76-1.75-7.67-1.12l-1.01.25s-11.91 9.68 3.97 11.91c0 0 4.65 6.85 10.28.5 0 0 2.23-2.3 4.58-2.41" /><path vectorEffect="non-scaling-stroke" d="M64.64 61.6c-.51-2.95-1.24-7.75-.95-12.43 0 0 1.7-7.67 5.11-12.57 0 0 10.87-18.53-5.33-30.47 0 0-14.33-10.62-31.66-.79 0 0-9.67 4.18-7.62 17.56 0 0 .41 3.74-.77 5.49l-6.4 7.53s-.85 2.24 1.7 2.45l3.9.8s1.63 3.18 1.8 5.63c.16 2.45-.41 5.47-.41 5.47s-1.39 3.67.79 5.29c.43.11 2.03 1.92 13.43-.53 0 0 1.1.52 1.8 6.48M24.91 44.97l4.9.09" /></g></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M82.59 79.06v-7.19H21.41v7.19" />
    </g>
  </CardIcon>
);

export default memo(Sn0053);
