import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import { DateISOSchema } from '../../../../Date';
import { ImageAssetModelSchema } from '../../../asset-service';
import { AnySlugSchema, Slug } from '../../../slug-service';
import { CardType } from './Enum';

export const CardLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    slug: AnySlugSchema,
    cardType: z.nativeEnum(CardType),
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    excludeFromDiscovery: z.boolean(),
    live: z.boolean(),

    cardRenderAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),

    cardZipBlobId: z.string().optional(),

    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,
    createdByAuthorId: z.string(),
    versionStatus: z.string().optional(),
    publishedAt: DateISOSchema.optional(),
  })
);

export const CardUnloadedModelSchema = CardLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),
    cardRenderAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),
  })
);

// TODO: @haxxxton this is a work around until
// https://github.com/colinhacks/zod/issues/2448 is fixed
export type CardLoadedModel = Omit<
  z.infer<typeof CardLoadedModelSchema>,
  'slug'
> & {
  slug: Slug;
};
export type CardUnloadedModel = Omit<
  z.infer<typeof CardUnloadedModelSchema>,
  'slug'
> & {
  slug: Slug;
};

export type CardModel<IsLoaded extends boolean = false> = IsLoaded extends true
  ? CardLoadedModel
  : CardUnloadedModel;

export type CardModelId = CardModel['id'];
