import React from 'react';
import { CardType } from '@kitted/models';

import AddCard from './AddCard';
import ArticleCard from './ArticleCard';
import AuthorCard from './AuthorCard';
import DisplayCard from './DisplayCard';
import FlowCard from './FlowCard';
import KitCard from './KitCard';
import ResourceCard from './ResourceCard';
import RouteCard from './RouteCard';
import SubjectCard from './SubjectCard';
import ToolCard from './ToolCard';
import { CardProps } from './types';

export const cardTypeToComponentMap: {
  [key in CardProps['type']]: React.ElementType | null;
} = {
  [CardType.Tool]: ToolCard as React.ElementType,
  [CardType.Kit]: KitCard as React.ElementType,
  [CardType.Resource]: ResourceCard as React.ElementType,
  [CardType.Flow]: FlowCard as React.ElementType,
  [CardType.Author]: AuthorCard as React.ElementType,
  [CardType.Article]: ArticleCard as React.ElementType,
  [CardType.Subject]: SubjectCard as React.ElementType,
  [CardType.Deck]: null,
  [CardType.Display]: DisplayCard as React.ElementType,

  // CardTypeValuesWithCustomTypes
  route: RouteCard as React.ElementType,
  add: AddCard as React.ElementType,
};
