import { z } from 'zod';

import { EmailButtonModelSchema } from './EmailButtonModel';
import {
  EmailCardGridLoadedModelSchema,
  EmailCardGridUnloadedModelSchema,
} from './EmailCardGridModel';
import {
  EmailFeaturedCardLoadedModelSchema,
  EmailFeaturedCardUnloadedModelSchema,
} from './EmailFeaturedCardModel';
import {
  EmailImageLoadedModelSchema,
  EmailImageUnloadedModelSchema,
} from './EmailImageModel';
import { EmailTextModelSchema } from './EmailTextModel';

export const AnyEmailBlockLoadedModelSchema = z.union([
  EmailButtonModelSchema,
  EmailFeaturedCardLoadedModelSchema,
  EmailImageLoadedModelSchema,
  EmailTextModelSchema,
  EmailCardGridLoadedModelSchema,
]);

export const AnyEmailBlockUnloadedModelSchema = z.union([
  EmailButtonModelSchema,
  EmailImageUnloadedModelSchema,
  EmailFeaturedCardUnloadedModelSchema,
  EmailTextModelSchema,
  EmailCardGridUnloadedModelSchema,
]);

export type AnyEmailBlockLoadedModel = z.infer<
  typeof AnyEmailBlockLoadedModelSchema
>;
export type AnyEmailBlockUnloadedModel = z.infer<
  typeof AnyEmailBlockUnloadedModelSchema
>;

export type AnyEmailBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AnyEmailBlockLoadedModel : AnyEmailBlockUnloadedModel;
