import { z } from 'zod';

import { KittedModelBaseSchema } from '../../../../base';
import { DateISOSchema } from '../../../../Date';

export const CMSArticleBlockExecutiveBoardModelSchema =
  KittedModelBaseSchema.merge(
    z.object({
      createdByAuthorId: z.string(),
      createdByUserId: z.string(),

      createdAt: DateISOSchema,
      updatedAt: DateISOSchema,
    })
  );
export type CMSArticleBlockExecutiveBoardModel = z.infer<
  typeof CMSArticleBlockExecutiveBoardModelSchema
>;
