import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0036: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 100 82"
		height={82}
		width={100}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M30.3 80.71h39.39M43.16 68.15v12.22M56.84 68.15v12.22M1.57 9.81c0-4.48 3.67-8.15 8.15-8.15h80.56c4.48 0 8.15 3.67 8.15 8.15v49.75c0 4.48-3.67 8.15-8.15 8.15H9.72c-4.48 0-8.15-3.67-8.15-8.15V9.81Z" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m8.78 59.42 82.39-.33" />
    </g>
  </CardIcon>
);

export default memo(Fr0036);
