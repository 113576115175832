import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0040: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 88 120"
		height={120}
		width={88}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M51.43 57.45c0 4.1-3.33 7.43-7.43 7.43s-7.43-3.33-7.43-7.43 3.33-7.43 7.43-7.43 7.43 3.33 7.43 7.43ZM34.28 106.62c-5.43 0-7.29-4.44-7.29-9.87V82.3c0-5.43 4.44-9.87 9.87-9.87h14.29c5.43 0 9.87 4.44 9.87 9.87v14.45c0 5.43-1.87 9.87-7.3 9.87M53.8 85.29v32.62M34.2 85.29v32.62M26.1 57.45c0 4.1-3.33 7.43-7.43 7.43s-7.43-3.33-7.43-7.43 3.33-7.43 7.43-7.43 7.43 3.33 7.43 7.43ZM8.95 106.62c-5.43 0-7.29-4.44-7.29-9.87V82.3c0-5.43 4.44-9.87 9.87-9.87h10.61M8.87 85.29v32.62M61.9 57.45c0 4.1 3.33 7.43 7.43 7.43s7.43-3.33 7.43-7.43-3.33-7.43-7.43-7.43-7.43 3.33-7.43 7.43ZM79.05 106.62c5.42 0 7.29-4.44 7.29-9.87V82.3c0-5.43-4.44-9.87-9.87-9.87H65.86M79.13 85.29v32.62" /></g><g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M64.1 37.58c0 4.1-3.33 7.43-7.43 7.43s-7.43-3.33-7.43-7.43 3.33-7.43 7.43-7.43 7.43 3.33 7.43 7.43ZM52.09 17.71c0 4.1-3.33 7.43-7.43 7.43s-7.43-3.33-7.43-7.43 3.33-7.43 7.43-7.43 7.43 3.33 7.43 7.43ZM38.77 37.58c0 4.1-3.33 7.43-7.43 7.43s-7.43-3.33-7.43-7.43 3.33-7.43 7.43-7.43 7.43 3.33 7.43 7.43Z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M11.24 2.09h64.87" /></g>
    </g>
  </CardIcon>
);

export default memo(In0040);
