import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0054: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 90 88"
		height={88}
		width={90}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M15.09 77.81a38.383 38.383 0 0 0 24.31 9.22c.23 0 .46.02.69.02 21.25 0 38.48-17.23 38.48-38.48H39.4V8.11" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M31.89 10.97C14.58 14.72 1.61 30.12 1.61 48.56c0 9.1 3.16 17.46 8.44 24.05l29.88-24.95" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M52.79.96c-.21 0-.42.01-.64.02v35.58h36.24c0-19.67-15.94-35.6-35.6-35.6Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M8.49 26.42h30.68M2.89 40.57h36.03M2.25 54.72h29.03" />
    </g>
  </CardIcon>
);

export default memo(Fr0054);
