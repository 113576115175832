import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0015: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 82 96"
		height={96}
		width={82}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M10.88 41.89c.29 1.13.55 2.27.79 3.42.89 4.32 1.41 8.75 1.68 13.13.42 6.67-.93 13.22-3.1 19.55-.63 1.85-.24 3.1 1.41 4.02 2.59 1.44 5.12 3.01 7.8 4.25 8.42 3.87 17.2 6.63 26.44 7.77 1.6.2 3.02.46 3.48-2.01.87-4.68 2.23-9.28 3.37-13.91.35-1.42 1.24-1.79 2.66-1.74 3.37.11 6.75.04 10.12.03 4.85-.02 6.75-1.88 6.77-6.66.02-4.15 0-8.31 0-12.91 2.15 0 3.98.07 5.81-.02 2.19-.1 2.55-.78 1.59-2.71-1.76-3.56-3.32-7.17-4.6-10.86-1.64-4.75-2.82-9.65-3.38-14.83a27.51 27.51 0 0 0-2.61-9.08C63.05 6.79 48-.83 33.92 2.72c-9.62 2.43-16.97 8.71-20.91 16.77a31.407 31.407 0 0 0-1.59 3.91" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M73.73 40.3h-62l-7.51 5.91a1.491 1.491 0 0 1-1.57.17c-.51-.25-.84-.77-.84-1.34v-10.4c0-.62.38-1.17.96-1.39l2.44-.93-2.34-.71c-.63-.19-1.06-.77-1.06-1.43V19.04c0-.56.32-1.08.82-1.33.5-.25 1.11-.2 1.56.14l7.52 5.64h59.23" />
    </g>
  </CardIcon>
);

export default memo(Sn0015);
