import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const LetterB: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 14 18"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" fill={color} d="M1.42 16.78V1.22h5.53c1.01 0 1.9.19 2.67.56.79.36 1.4.85 1.84 1.49.44.64.67 1.37.67 2.2 0 .73-.21 1.39-.62 1.98-.41.58-.97 1.04-1.67 1.4.83.33 1.49.81 1.98 1.44.5.62.76 1.33.76 2.13 0 .84-.23 1.6-.69 2.27-.44.65-1.06 1.16-1.84 1.53-.79.37-1.7.56-2.73.56h-5.9Zm3.74-9.29h1.38c.55 0 .99-.15 1.31-.44.34-.31.51-.71.51-1.2s-.16-.9-.49-1.2c-.33-.3-.77-.44-1.33-.44H5.16V7.5Zm0 6.31h1.69c.59 0 1.06-.16 1.4-.47.36-.33.53-.76.53-1.29s-.18-.98-.53-1.29c-.34-.31-.81-.47-1.4-.47H5.16v3.51Z" />
    </g>
  </WebsiteIcon>
);

export default memo(LetterB);
