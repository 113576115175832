import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0009: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 114 106"
		height={106}
		width={114}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m49.85 34.74.3 10.47-7.34 4.99.59 4.7 5.73-.44 8.81 4.55-8.46 15.47-2.99-10.47-4.26-2.49 1.17-6.17-7.46-7.53c4.12-8.91 13.13-15.09 23.59-15.09.81 0 1.61.04 2.4.11" /><path vectorEffect="non-scaling-stroke" d="m71.75 43.6-3.17.24-1.72-1.68 3.43-7.11c8.99 4.09 15.24 13.15 15.24 23.67 0 14.34-11.63 25.98-25.99 25.98S33.56 73.07 33.56 58.72c0-.77.03-1.54.1-2.29" /><path vectorEffect="non-scaling-stroke" d="m84.62 52.33-7.09 13.02-2.81 3.88-2.17-10.76-2.7-.15s-7.2-2.64-1.02-7.78l7.49-.88 2.94-5.82" /></g><circle vectorEffect="non-scaling-stroke" cx="59.54" cy="58.7" r="35.61" stroke={spot} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M41.82 16.77c5.45-2.3 11.43-3.58 17.72-3.58s12.27 1.27 17.72 3.58c5.45 2.3 10.35 5.64 14.47 9.75 4.12 4.12 7.45 9.02 9.75 14.47 2.3 5.45 3.58 11.43 3.58 17.72s-1.27 12.27-3.58 17.72-5.64 10.35-9.75 14.47-9.02 7.45-14.47 9.75-11.43 3.58-17.72 3.58-12.27-1.27-17.72-3.58-10.35-5.64-14.47-9.75c-4.12-4.12-7.45-9.02-9.75-14.47S14.02 65 14.02 58.71s1.27-12.27 3.58-17.72" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M93.76 14.26c-2.2 2.2-2.06 5.91.3 8.27 2.37 2.37 6.07 2.5 8.27.3 2.2-2.2.88-5.46-.11-8.48-.99-3.03-2.31-6.28-.11-8.48s5.91-2.07 8.28.3c2.37 2.37 2.5 6.07.3 8.28M110.4 6.15l2.64-2.64M91.38 25.18l2.64-2.64" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M2.09 2.92a3.869 3.869 0 0 1 5.47 5.47 3.869 3.869 0 0 1-5.47-5.47ZM36.41 31.24l-10.6-11.12c.64-1.37 1-3.41-.16-4.36l-8.97-7.33c-1.16-.95-2.98-.86-4.05.2l-4.82 4.82c-1.06 1.06-1.15 2.88-.2 4.05l7.33 8.97c.95 1.16 2.99.8 4.36.16l11.12 10.6" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0009);
