import { z } from 'zod';

import {
  CMSContentBlockCarouselItemLoadedModelSchema,
  CMSContentBlockCarouselItemUnloadedModelSchema,
} from './CMSContentBlockCarouselItemModel';
import {
  CMSContentBlockImageLoadedModelSchema,
  CMSContentBlockImageUnloadedModelSchema,
} from './CMSContentBlockImageModel';
import {
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
} from './CMSContentBlockVideoModel';

export const AnyCMSContentBlockCarouselItemLoadedModelSchema = z.union([
  CMSContentBlockCarouselItemLoadedModelSchema,
  CMSContentBlockImageLoadedModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
]);

export const AnyCMSContentBlockCarouselItemUnloadedModelSchema = z.union([
  CMSContentBlockCarouselItemUnloadedModelSchema,
  CMSContentBlockImageUnloadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
]);

export type AnyCMSContentBlockCarouselItemLoadedModel = z.infer<
  typeof AnyCMSContentBlockCarouselItemLoadedModelSchema
>;
export type AnyCMSContentBlockCarouselItemUnloadedModel = z.infer<
  typeof AnyCMSContentBlockCarouselItemUnloadedModelSchema
>;

export type AnyCMSContentBlockCarouselItemModel<
  IsLoaded extends boolean = false
> = IsLoaded extends true
  ? AnyCMSContentBlockCarouselItemLoadedModel
  : AnyCMSContentBlockCarouselItemUnloadedModel;
