import { lazy, Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { GlobalStyles } from '@kitted/shared-components';

import { isLanding } from './constants/environment';
import { ErrorCaptureProvider } from './contexts/ErrorCaptureContext';

const LandingPage = lazy(() => import('./routes/landing'));
const App = lazy(() => import('./app'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <GlobalStyles />
    <ErrorCaptureProvider>
      {isLanding && (
        <Suspense>
          <LandingPage />
        </Suspense>
      )}
      {!isLanding && (
        <Suspense>
          <App />
        </Suspense>
      )}
    </ErrorCaptureProvider>
  </>
);
