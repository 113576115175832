import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../base';
import { notBoth } from '../../../zodHelpers';
import { ImageAssetModelSchema } from '../../asset-service';
import { UserModelSchema } from '../../auth-service';
import { AuthorSlugSchema } from '../../slug-service';
import { AnyAuthorBlockLoadedModelSchema } from '../AuthorBlocks';

// Author is for things the USER creates/about them
// ie. it is for the things they CREATE
// it is NOT what they favourite, what their preferences are
export const AuthorLoadedModelSchema = KittedVersionedModelBaseSchema.merge(
  z.object({
    slug: AuthorSlugSchema,

    userId: UserModelSchema.shape.id,
    firstName: z.string(),
    lastName: z.string(),

    description: z.string().optional(),

    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    backgroundImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),

    website: z.string().url().optional(),
    linkedIn: z.string().optional(),
    authorBlocks: makeKittedModelReferenceSchema(
      AnyAuthorBlockLoadedModelSchema,
      true
    ).array(),
  })
);

export const AuthorUnloadedModelSchema = AuthorLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),
    backgroundImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),
    authorBlocks: makeKittedModelReferenceSchema(
      AnyAuthorBlockLoadedModelSchema,
      false
    ).array(),
  })
);

export const AuthorSaveModelSchema = AuthorUnloadedModelSchema.merge(
  KittedSaveModelBaseSchema
)
  .omit({
    slug: true,
    userId: true,
  })
  .merge(
    z.object({
      firstName: z.string().optional(),
      lastName: z.string().optional(),
      authorBlocks: AuthorUnloadedModelSchema.shape.authorBlocks.optional(),
      keyImageBlobId: z.string().optional().nullable(),
      backgroundImageBlobId: z.string().optional().nullable(),
    })
  )
  .superRefine(notBoth('keyImageBlobId', 'keyImageImageAsset'))
  .superRefine(notBoth('backgroundImageBlobId', 'backgroundImageImageAsset'));

export type AuthorLoadedModel = z.infer<typeof AuthorLoadedModelSchema>;
export type AuthorUnloadedModel = z.infer<typeof AuthorUnloadedModelSchema>;
export type AuthorSaveModel = z.infer<typeof AuthorSaveModelSchema>;

export type AuthorModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AuthorLoadedModel : AuthorUnloadedModel;
