import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0057: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 112 116"
		height={116}
		width={112}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M105.17 23.24 94.54 33.87M94.46 33.88 83.83 23.25M79.75 3.76c13.81 5.56 14.94 16.31 14.94 28.48M6.83 92.87l10.63-10.63M17.54 82.23l10.63 10.63M32.25 112.36c-13.8-5.56-14.94-16.31-14.94-28.48" /></g><g ><circle vectorEffect="non-scaling-stroke" cx="75.44" cy="77.5" r="35.44" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M31.73 73.73C14.44 71.37 1.12 56.55 1.12 38.62c0-19.57 15.87-35.44 35.44-35.44 18.24 0 33.27 13.79 35.22 31.51" /><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M65.9 85.54c0 4.19 4.25 7.58 9.48 7.58s9.47-3.39 9.47-7.58-5.05-6.02-9.49-7.96c-4.44-1.94-9.49-3.77-9.49-7.96s4.25-7.58 9.47-7.58 9.48 3.39 9.48 7.58M75.33 62.01v-5.02M75.33 98.18v-5.02" /></g><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M27 46.41c0 4.19 4.25 7.58 9.48 7.58s9.47-3.39 9.47-7.58-5.05-6.02-9.49-7.96c-4.44-1.94-9.49-3.77-9.49-7.96s4.25-7.58 9.47-7.58 9.48 3.39 9.48 7.58M36.43 22.88v-5.02M36.43 59.04v-5.01" /></g></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M39.71 114.25s3.14.88 6.35.66M72.06 1.75s-3.14-.88-6.35-.66" />
    </g>
  </CardIcon>
);

export default memo(In0057);
