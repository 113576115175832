import { z } from 'zod';

import { KittedModelBaseSchema } from '../../base';

export enum UserStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  DELETED = 'deleted',
}

export type UserStatusValues = `${UserStatus}`;

export const UserModelSchema = KittedModelBaseSchema.merge(
  z.object({
    emailVerified: z.boolean(),
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string().optional(),
    status: z.nativeEnum(UserStatus),
    // currently unused
    // middleName: z.string().optional(),
    // birthDate: z.string().optional(),
    // gender: z.string().optional(),
    // picture: z.string().optional(),
    // preferredUsername: z.string().optional(),
    // profile: z.string().optional(),
    // address: z.string().optional(),
    // website: z.string().optional(),
    // locale: z.string().optional(),
    // zoneInfo: z.string().optional(),
    // name: z.string().optional(),
    // nickname: z.string().optional(),
  })
);

export type UserModel = z.infer<typeof UserModelSchema>;
