import { z } from 'zod';

import { RecaptchaResponseSchema } from '../../../google';

export const MMMSubscribeRequestSchema = z
  .object({
    email: z.string().email(),
  })
  .merge(RecaptchaResponseSchema);

export type MMMSubscribeRequest = z.infer<typeof MMMSubscribeRequestSchema>;
