import { memo } from 'react';

import useIsServer from '../../hooks/useIsServer';
import htmlDecode from '../../services/htmlDecode';
import { HTMLDecodeProps } from './types';

const HTMLDecode = ({ content }: HTMLDecodeProps) => {
  const isServer = useIsServer();

  return <>{htmlDecode(content, isServer)}</>;
};

export default memo(HTMLDecode);
