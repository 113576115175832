import {
  AnyCardModel,
  AnyCardWithRelatedCardsModel,
  CardType,
} from '../../../models';

const cardTypesWithRelatedCards = [
  CardType.Kit,
  CardType.Author,
  CardType.Subject,
  CardType.Tool,
];

export function isCardWithRelatedCards(
  card: AnyCardModel
): card is AnyCardWithRelatedCardsModel {
  return cardTypesWithRelatedCards.includes(card.cardType);
}
