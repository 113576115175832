import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import { ImageAssetModelSchema } from '../../../asset-service';
import { ContentBlockTheme, ContentBlockType } from './Enum';

export const ContentBlockImageLoadedModelSchema =
  KittedVersionedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(ContentBlockTheme),
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        true
      ),
      linkUrl: z.string().url().optional(),
      isFullbleed: z.boolean(),
      caption: z.string().optional(),
      type: z.literal(ContentBlockType.Image),
    })
  );

export const ContentBlockImageUnloadedModelSchema =
  ContentBlockImageLoadedModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ),
    })
  );

export const ContentBlockImageSaveModelSchema =
  ContentBlockImageUnloadedModelSchema.merge(KittedSaveModelBaseSchema).merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
      isFullbleed: z.boolean().optional(),
      keyImageBlobId: z.string().optional(),
    })
  );

export type ContentBlockImageLoadedModel = z.infer<
  typeof ContentBlockImageLoadedModelSchema
>;
export type ContentBlockImageUnloadedModel = z.infer<
  typeof ContentBlockImageUnloadedModelSchema
>;
export type ContentBlockImageSaveModel = z.infer<
  typeof ContentBlockImageSaveModelSchema
>;

export type ContentBlockImageModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? ContentBlockImageLoadedModel
    : ContentBlockImageUnloadedModel;
