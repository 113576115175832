/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import Divider from '../../Divider';
import HTMLDecode from '../../HTMLDecode';
import CardContentsForHoverableCard from '../CardContentsForHoverableCard';
import CardImage from '../CardImage';
import CardSlug from '../CardSlug';
import useExtractInteractionProps from '../hooks/useExtractInteractionProps';
import useStyles from './styles';
import { PolymorphicRouteCardProps } from './types';

const RouteCard = <C extends React.ElementType = 'div'>({
  as,
  body,
  children,
  className,
  imageSrc,
  isActive,
  isFeatured,
  isMenuOpen,
  label,
  title,
  ...rest
}: PolymorphicRouteCardProps<C>) => {
  const interactionProps = useExtractInteractionProps(rest);
  const styles = useStyles();
  const Component = as || 'div';

  return (
    <Component
      className={clsx(
        className,
        styles.container,
        isActive && styles.containerActive,
        isFeatured && styles.containerFeatured,
        isMenuOpen && styles.containerMenuOpen
      )}
      {...interactionProps}
    >
      <CardImage imageSrc={imageSrc} isFeatured={isFeatured} />
      <CardContentsForHoverableCard
        isActive={isActive}
        isFeatured={isFeatured}
        isMenuOpen={isMenuOpen}
        title={title}
        body={body}
        type="resource"
      />
      {isFeatured && (
        <Divider className={styles.divider} spacing="md" theme="secondary" />
      )}
      <CardSlug className={styles.cardSlug} isFeatured={isFeatured} noPrefix>
        <HTMLDecode content={label} />
      </CardSlug>
      {children}
    </Component>
  );
};

export default memo(RouteCard);
