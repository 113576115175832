import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import typographyConstants from '../../Typography/constants';
import { cardTypeToMaxLinesMap } from '../CardBody/constants';
import { overlayBorderStyles, overlayStyles } from '../styles';

const cardBodyPaddingTop = 14;

export default createUseStyles(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      '&:before': {
        ...overlayStyles(),
        top: 3,
        right: 3,
        bottom: 3,
        left: 3,
      },
      '&:after': {
        ...overlayBorderStyles(),
      },
    },
    containerActive: {
      '& $cardBodyContainer': {
        maxHeight: `calc(${typographyConstants.cardBodyHover.lineHeight} * ${
          cardTypeToMaxLinesMap.subject.default
        } + ${styling.utils.px(cardBodyPaddingTop)})`,
      },
      '&:before': {
        opacity: 1,
      },
    },
    containerFeatured: {
      '&:before, &:after': {
        content: 'unset',
      },
      '& $divider': {
        left: 0,
        right: 0,
        width:
          'calc(100% - var(--cardPaddingInline) - var(--cardPaddingInline))',
      },
    },
    containerMenuOpen: {
      '&:before, &:after': {
        opacity: 1,
      },
    },
    background: {
      ...styling.utils.absoluteFull(),
      zIndex: 0,
      borderRadius: styling.sizes.context.card.radius,
      backgroundImage: styling.colors.context.card.footerBackgrounds.flow,
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 3,
        top: 3,
        bottom: 3,
        right: 3,
        borderRadius: styling.sizes.context.card.radius - 1,
        backgroundImage: `radial-gradient(circle at 50% 30%, ${styling.colors.theme.primary600} 0%, ${styling.colors.theme.primary900} 40%)`,
      },
    },
    plus: {
      position: 'absolute',
      left: '50%',
      top: '30%',
      transform: 'translate(-50%, -50%)',
      height: 67,
      width: 67,
      zIndex: 3,
      '&:before': {
        ...styling.utils.absoluteFull(),
        content: '""',
        background: styling.colors.context.card.footerBackgrounds.flow,
        clipPath: 'inset(0% 48% 0% 48% round 10px)',
      },
      '&:after': {
        ...styling.utils.absoluteFull(),
        content: '""',
        background: styling.colors.context.card.footerBackgrounds.flow,
        clipPath: 'inset(48% 0% 48% 0% round 10px)',
      },
    },
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      zIndex: 5,
      height: '100%',
      flexGrow: 1,
      transition: styling.speeds.jog,
      paddingInline: 'var(--cardPaddingInline)',
    },
    cardBodyContainer: {
      maxHeight: 0,
      overflow: 'hidden',
      transition: styling.transitions.utils.generate(['max-height']),
    },
    cardBody: {
      paddingTop: cardBodyPaddingTop,
      boxSizing: 'content-box',
    },
    divider: {
      left: 0,
      right: 0,
      zIndex: 7,
      width: 'calc(100% - 72px)',
    },
    cardSlug: {
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: 5,
      bottom: 'var(--cardSlugBottom)',
      paddingInline: 'var(--cardPaddingInline)',
    },
  },
  { name: 'addCard' }
);
