import { z } from 'zod';

import { DateISOSchema } from '../../Date';
import { MmmSubscriptionStatus } from './enum';

export const MmmSubscriptionModelSchema = z.object({
  status: z.nativeEnum(MmmSubscriptionStatus),
  email: z.string().email(),

  accountId: z.string().optional(),

  firstName: z.string(),
  lastName: z.string(),

  ip: z.string(),
  timezone: z.string(),
  country: z.string(),

  // The next email to send. (0 = first email, 1 = second email, etc.)
  emailIndex: z.number(),

  // The lasttime an email was sent.
  lastEmailed: DateISOSchema,

  // The lasttime an email was sent.
  subscibedAt: DateISOSchema,

  // The unsub code for the user to unsub with prevents phishing attacks
  unsubCode: z.string(),
});

export type MmmSubscriptionModel = z.infer<typeof MmmSubscriptionModelSchema>;
