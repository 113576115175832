import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import {
  AnyContentBlockCarouselItemLoadedModelSchema,
  AnyContentBlockCarouselItemUnloadedModelSchema,
} from './AnyContentBlockCarouselItemModel';
import { ContentBlockTheme, ContentBlockType } from './Enum';

export const ContentBlockCarouselLoadedModelSchema =
  KittedVersionedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(ContentBlockTheme),
      type: z.literal(ContentBlockType.Carousel),
      items: makeKittedModelReferenceSchema(
        AnyContentBlockCarouselItemLoadedModelSchema,
        true
      ).array(),
    })
  );

export const ContentBlockCarouselUnloadedModelSchema =
  ContentBlockCarouselLoadedModelSchema.merge(
    z.object({
      items: makeKittedModelReferenceSchema(
        AnyContentBlockCarouselItemUnloadedModelSchema,
        false
      ).array(),
    })
  );

export const ContentBlockCarouselSaveModelSchema =
  ContentBlockCarouselUnloadedModelSchema.merge(KittedSaveModelBaseSchema);

export type ContentBlockCarouselLoadedModel = z.infer<
  typeof ContentBlockCarouselLoadedModelSchema
>;
export type ContentBlockCarouselUnloadedModel = z.infer<
  typeof ContentBlockCarouselUnloadedModelSchema
>;
export type ContentBlockCarouselSaveModel = z.infer<
  typeof ContentBlockCarouselSaveModelSchema
>;

export type ContentBlockCarouselModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? ContentBlockCarouselLoadedModel
    : ContentBlockCarouselUnloadedModel;

export const ContentBlockCarouselShallowViewModelSchema =
  ContentBlockCarouselLoadedModelSchema.merge(
    z.object({
      items: AnyContentBlockCarouselItemUnloadedModelSchema.array(),
    })
  );

export type ContentBlockCarouselShallowViewModel = z.infer<
  typeof ContentBlockCarouselShallowViewModelSchema
>;
