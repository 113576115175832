import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0059: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 90 122"
		height={122}
		width={90}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M79.5 82.52v18.74L45 120.92V94.79M45 120.92l-34.5-19.66V89.98M10.5 64.69 45 82.97l-8.91 12.98-34.5-18.29 8.91-12.97" /><path vectorEffect="non-scaling-stroke" d="M79.5 64.69 45 82.97l8.92 12.98 34.49-18.29-8.91-12.97M29.94 54.83 10.5 64.69M79.5 64.69 59.1 54.34" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M21.42 29.41v3.85M32.96 23.19v-8h5.37c.66 0 .97-.52 1.1-.82.27-.65.18-1.47-.22-1.87L28.35 1.65c-.76-.76-1.99-.76-2.74 0L14.8 12.5c-.42.42-.55 1.23-.29 1.85.23.54.7.84 1.32.84h5.59v9.2M53.35 53.98v2.86M37.84 59.8v2.61M37.84 50.14v4.69M53.35 49.19V37.84h7.22c.89 0 1.31-.69 1.48-1.1.36-.88.24-1.98-.29-2.51L47.17 19.65a2.607 2.607 0 0 0-3.69 0L28.95 34.23c-.56.57-.74 1.65-.39 2.49.3.73.94 1.13 1.78 1.13h7.51v7.7M45.31 43.19v12.9M45.31 61.06v6.43M45.31 72.46v2.04" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0059);
