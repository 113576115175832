import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0018: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 86"
		height={86}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M77.56 1.81c-12.24 0-18.18-.37-23.56 6.16v69.06c5.38-6.53 11.33-6.16 23.56-6.16h20.18V1.81H77.56Z" /><path vectorEffect="non-scaling-stroke" d="M54 77.03c-5.39-6.53-11.33-6.16-23.57-6.16H10.26V1.81h20.18c12.24 0 18.18-.37 23.57 6.16" /><path vectorEffect="non-scaling-stroke" d="M1.63 12.76v66.43h42.56s2.13 5 4.77 5h10.07c2.64 0 4.77-5 4.77-5h42.56V12.76" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M86.66 13.87H69.22M86.66 25.2H69.22M86.66 36.54H69.22M86.66 47.87H69.22M86.66 59.2H69.22M38.78 13.87H21.34M38.78 25.2H21.34M38.78 36.54H21.34M38.78 47.87H21.34M38.78 59.2H21.34" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0018);
