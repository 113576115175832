import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import {
  AnyCardLoadedModel,
  AnyCardUnloadedModelSchema,
} from '../../../cards-service';
import { EmailBlockType } from './Enum/EmailBlockType';

export const EmailFeaturedCardLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    type: z.literal(EmailBlockType.FeaturedCard),
    // getting an error that the inferred type will be too large for
    // serialization.. so we'll add the types back in native TS
    card: z.any(),
    // card: makeKittedModelReferenceSchema(AnyCardLoadedModelSchema, true),
  })
);

export const EmailFeaturedCardUnloadedModelSchema =
  EmailFeaturedCardLoadedModelSchema.merge(
    z.object({
      card: makeKittedModelReferenceSchema(AnyCardUnloadedModelSchema, false),
    })
  );

export type EmailFeaturedCardLoadedModel = Omit<
  z.infer<typeof EmailFeaturedCardLoadedModelSchema>,
  'card'
> & {
  card: AnyCardLoadedModel;
};
export type EmailFeaturedCardUnloadedModel = z.infer<
  typeof EmailFeaturedCardUnloadedModelSchema
>;

export type EmailFeaturedCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? EmailFeaturedCardLoadedModel
    : EmailFeaturedCardUnloadedModel;
