import { CardTypeValues } from '@kitted/models';

import { cardTypeToMaxLinesMap } from '../CardBody/constants';
import { cardTypeToMaxLinesMap as cardTitleTypeToMaxLinesMap } from '../CardTitle/constants';

export const getCardContentsStyle = (
  type: CardTypeValues | undefined,
  isFeatured: boolean
) =>
  ({
    '--cardTitleMaxLines':
      cardTitleTypeToMaxLinesMap[type as CardTypeValues][
        isFeatured ? 'featured' : 'default'
      ],
    '--cardHoverBodyMaxLines':
      cardTypeToMaxLinesMap[type as CardTypeValues][
        isFeatured ? 'featured' : 'default'
      ],
  } as React.CSSProperties);
