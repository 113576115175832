import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0056: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 104"
		height={104}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M91.06 52h-6.61M19.55 52h-6.61M52 92.82V86.2M52 17.8v-6.62" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M52 30.94V52L33.74 70.26M14.04 1.84l-1.41 19.5 19.67-1.23L14.04 1.84z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M23.12 10.81C31.29 5.07 41.25 1.7 52 1.7c27.78 0 50.3 22.52 50.3 50.3S79.78 102.3 52 102.3 1.7 79.78 1.7 52c0-7.31 1.56-14.26 4.37-20.53" />
    </g>
  </CardIcon>
);

export default memo(Ac0056);
