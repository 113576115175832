import { z } from 'zod';

import { UserLoginSchema } from './UserLogin';

export const UserChangePasswordSchema = z.object({
  oldPassword: UserLoginSchema.shape.password,
  newPassword: UserLoginSchema.shape.password,
});

export type UserChangePassword = z.infer<typeof UserChangePasswordSchema>;
