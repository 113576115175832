import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0044: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 126"
		height={126}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><circle vectorEffect="non-scaling-stroke" cx="54" cy="10.71" r="9.27" /><path vectorEffect="non-scaling-stroke" d="M41.88 73.21c-6.77 0-9.1-5.54-9.1-12.31V41.25c0-6.77 5.54-12.31 12.31-12.31h17.83c6.77 0 12.31 5.54 12.31 12.31V60.9c0 6.77-2.33 12.31-9.1 12.31M66.23 44.97v37.89M41.77 44.97v37.89" /><circle vectorEffect="non-scaling-stroke" cx="20.7" cy="18.78" r="8.59" /><path vectorEffect="non-scaling-stroke" d="M9.47 77.63c-6.27 0-8.43-5.14-8.43-11.41V48c0-6.27 5.13-11.41 11.41-11.41h13.02M20.7 75.18v20.59M9.37 51.45v51.63" /><circle vectorEffect="non-scaling-stroke" cx="87.3" cy="18.78" r="8.59" /><path vectorEffect="non-scaling-stroke" d="M98.53 77.63c6.27 0 8.43-5.14 8.43-11.41V48c0-6.27-5.13-11.41-11.41-11.41H82.53M87.3 75.18v20.59M98.63 51.45v51.63" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m54 80.46 5.25 16.16h16.99l-13.75 9.99 5.25 16.15L54 112.78l-13.75 9.98 5.25-16.15-13.74-9.99h16.99L54 80.46zM20.7 101.61l2.85 8.77h9.22l-7.46 5.42 2.85 8.76-7.46-5.42-7.46 5.42 2.85-8.76-7.45-5.42h9.21l2.85-8.77zM87.3 101.61l2.84 8.77h9.22l-7.46 5.42 2.85 8.76-7.45-5.42-7.46 5.42 2.85-8.76-7.46-5.42h9.22l2.85-8.77z" />
    </g>
  </CardIcon>
);

export default memo(Fr0044);
