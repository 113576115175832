import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0020: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 116 100"
		height={100}
		width={116}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M114.93 88.02H12.28V.96M.69 23.82h11.49M.69 49.89h11.49M.69 75.96h11.49M24.4 99.41V87.92M61.57 99.41V87.92M98.75 99.41V87.92" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m85.28 29.13 5.82-7.38a6.35 6.35 0 0 0 4.69-2.21 6.392 6.392 0 0 0-.68-9c-2.67-2.3-6.7-2-9 .68a6.334 6.334 0 0 0-1.49 4.93L72.43 29.21M35.11 41.46c0 11.04 6.5 20.28 15.87 24.68-3.39 1.59-6.4 4.29-8.86 7.04h41.11c-2.46-2.76-5.48-5.45-8.87-7.04 9.38-4.4 15.88-13.63 15.88-24.68M39.88 41.21h-6.29c-2.04 0-3.71-1.52-3.71-3.37s1.67-3.37 3.71-3.37h58.17c2.04 0 3.71 1.52 3.71 3.37s-1.67 3.37-3.71 3.37H73.12" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0020);
