import { useMemo } from 'react';
import {
  ExternalLink,
  getInteractionProps,
  InteractionProps,
  InternalLink,
} from '@kitted/shared-components';

import { ButtonProps } from '../../types';

const useButton = ({
  onClick,
  href,
  to,
  isDisabled,
  type,
  target,
}: {
  onClick: ButtonProps['onClick'];
  href: ButtonProps['href'];
  to: ButtonProps['to'];
  isDisabled: ButtonProps['isDisabled'];
  type: ButtonProps['type'];
  target: ButtonProps['target'];
}) => {
  const { as: ButtonComponent, ...buttonProps } = useMemo(() => {
    const props = getInteractionProps(
      {
        nonInteractive: isDisabled,
        href,
        to,
        onClick,
      } as InteractionProps,
      {
        defaultComponent: 'button',
        internalLinkComponent: InternalLink,
        externalLinkComponent: ExternalLink,
      },
      {
        type: type || 'button',
      },
      {
        disabled: true,
        type: type || 'button',
      }
    );
    return {
      ...props,
      target,
    };
  }, [href, to, onClick, isDisabled, type, target]);

  return {
    ButtonComponent,
    buttonProps,
  };
};

export default useButton;
