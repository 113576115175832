import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../base';
import { notBoth } from '../../../zodHelpers';
import { ImageAssetModelSchema } from '../../asset-service';
import { CMSFlowUnloadedModelSchema } from '../../contentful-service';
import { FlowSlugSchema } from '../../slug-service';
import {
  AnyFlowBlockLoadedModelSchema,
  AnyFlowBlockUnloadedModelSchema,
} from '../FlowBlocks';
import {
  FlowStepLoadedModelSchema,
  FlowStepUnloadedModelSchema,
} from '../FlowStep';

export const FlowLoadedModelSchema = KittedVersionedModelBaseSchema.merge(
  z.object({
    slug: FlowSlugSchema,

    title: z.string(),
    description: z.string(),
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),

    showThumbnails: z.boolean(),
    flowBlocks: makeKittedModelReferenceSchema(
      AnyFlowBlockLoadedModelSchema,
      true
    ).array(),

    flowSteps: makeKittedModelReferenceSchema(
      FlowStepLoadedModelSchema,
      true
    ).array(),

    cmsSrcMessage: CMSFlowUnloadedModelSchema.optional(),
  })
);

export const FlowUnloadedModelSchema = FlowLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),

    flowBlocks: makeKittedModelReferenceSchema(
      AnyFlowBlockUnloadedModelSchema,
      false
    ).array(),

    flowSteps: makeKittedModelReferenceSchema(
      FlowStepUnloadedModelSchema,
      false
    ).array(),
  })
);

export const FlowSaveModelSchema = FlowUnloadedModelSchema.merge(
  KittedSaveModelBaseSchema
)
  .merge(
    z.object({
      slug: FlowUnloadedModelSchema.shape.slug.optional(),

      title: z.string().optional(),
      description: z.string().optional(),
      showThumbnails: z.boolean().optional(),

      flowBlocks: FlowUnloadedModelSchema.shape.flowBlocks.optional(),
      flowSteps: FlowUnloadedModelSchema.shape.flowSteps.optional(),
      keyImageBlobId: z.string().optional().nullable(),
    })
  )
  .superRefine(notBoth('keyImageBlobId', 'keyImageImageAsset'));

export type FlowLoadedModel = z.infer<typeof FlowLoadedModelSchema>;
export type FlowUnloadedModel = z.infer<typeof FlowUnloadedModelSchema>;
export type FlowSaveModel = z.infer<typeof FlowSaveModelSchema>;

export type FlowModel<IsLoaded extends boolean = false> = IsLoaded extends true
  ? FlowLoadedModel
  : FlowUnloadedModel;
