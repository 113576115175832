import { AuthenticateUserChallenge } from 'quidproquo';
import { z } from 'zod';

const UserChallengeResponseBaseSchema = z.object({
  email: z.string().email(),
  session: z.string(),
  challenge: z.nativeEnum(AuthenticateUserChallenge),
});

export const UserChallengeNewPasswordRequiredResponseSchema =
  UserChallengeResponseBaseSchema.merge(
    z.object({
      newPassword: z.string(),
      challenge: z.literal(AuthenticateUserChallenge.NEW_PASSWORD_REQUIRED),
    })
  );

export type UserChallengeNewPasswordRequiredResponse = z.infer<
  typeof UserChallengeNewPasswordRequiredResponseSchema
>;
