import { z } from 'zod';

import {
  ContentBlockCardGridSaveModelSchema,
  ContentBlockTextSaveModelSchema,
  ContentBlockVideoSaveModelSchema,
} from '../../x-service';

export const AnyKitBlockSaveModelSchema = z.union([
  ContentBlockCardGridSaveModelSchema,
  ContentBlockVideoSaveModelSchema,
  ContentBlockTextSaveModelSchema,
]);

export type AnyKitBlockSaveModel = z.infer<typeof AnyKitBlockSaveModelSchema>;
