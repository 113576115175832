import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0016: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 114 94"
		height={94}
		width={114}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M44.36 49.97c0 .72-.58 1.3-1.3 1.3s-1.3-.58-1.3-1.3.58-1.3 1.3-1.3 1.3.58 1.3 1.3Z" /><circle vectorEffect="non-scaling-stroke" cx="70.94" cy="49.97" r="1.3" /></g><g ><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="m45.04 7.13-9.2-5.61v25.34l15.33-8.89M63.04 11.17l15.02-9.16v25.34l-15.49-8.97" /><path vectorEffect="non-scaling-stroke" d="M63.41 14.16c0 4.58-2.92 8.33-6.49 8.33s-6.5-3.75-6.5-8.33v-.17c0-4.58 2.93-8.33 6.5-8.33s6.49 3.75 6.49 8.33v.17Z" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M28.19 23.53c-6.16 4.92-10.76 11.39-13.07 18.74l-.07.12" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M8.17 41.03c-4.01 1.11-6.99 5.35-6.99 10.42 0 5.91 4.06 10.7 9.07 10.7 1.46 0 2.93-.39 4.16-1.12 4.05 17.91 21.64 31.45 42.62 31.45S95.6 78.96 99.61 61c1.23.73 2.66 1.16 4.13 1.16 5.01 0 9.07-4.79 9.07-10.7s-4.06-10.7-9.07-10.7c-1.8 0-3.44.57-4.85 1.64l-.07-.12c-2.32-7.39-6.96-13.9-13.17-18.83" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M70.2 69.98c-2.25 3.1-7.31 5.27-13.18 5.27s-10.97-2.18-13.21-5.31" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0016);
