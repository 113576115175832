import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const VideoBrowser: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m11.27 9.91 5.99 3.46-5.99 3.46V9.91ZM3.26 6.43h21.49M5.04 4.17c0 .17-.14.3-.3.3s-.3-.14-.3-.3.14-.3.3-.3.3.14.3.3ZM7.62 4.47c.17 0 .3-.14.3-.3s-.14-.3-.3-.3-.3.14-.3.3.14.3.3.3ZM10.52 4.47c.17 0 .3-.14.3-.3s-.14-.3-.3-.3-.3.14-.3.3.14.3.3.3Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M25.11 1.84H2.89c-.76 0-1.38.62-1.38 1.38v15.56c0 .76.62 1.38 1.38 1.38h22.22c.76 0 1.38-.62 1.38-1.38V3.22c0-.76-.62-1.38-1.38-1.38Z" />
    </g>
  </WebsiteIcon>
);

export default memo(VideoBrowser);
