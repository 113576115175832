import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0016: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 114 92"
		height={92}
		width={114}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m81.68 56.3 19.97-10.21c3.86-2.07 8.39-1.24 10.25 2.23 1.86 3.47.23 8.01-3.63 10.09L66.84 80.62l-2.19.55-38.64 1.05s-4.98 1.39-10.95 8.17" /><path vectorEffect="non-scaling-stroke" d="m1.33 70.07 17.53-16.13c8.57-5.78 18.72-2.59 18.72-2.59 4.92 1.29 14.05 1.59 14.05 1.59h15.16c4.52 0 8.21 3.23 8.21 7.17s-3.7 7.17-8.21 7.17H45.81" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M50.33 48.84c-.39-1.22-.61-2.51-.61-3.86 0-6.9 5.6-12.5 12.5-12.5s12.5 5.6 12.5 12.5c0 1.73-.35 3.37-.98 4.87" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M90.28 1.61C77.81 4.94 67.95 14.02 63.87 28.85" />
    </g>
  </CardIcon>
);

export default memo(Sn0016);
