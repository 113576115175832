import { styling } from '@kitted/design-system';
import { EMPTY_ARR } from '@kitted/utils';

const generateWholeRowClassNames = (
  className: string,
  column: number
): string[] => {
  let classNames: string[] = EMPTY_ARR;

  // & > :nth-last-child(1):nth-child(3n+2) 1st@fixer+3+1
  // & > :nth-last-child(2):nth-child(3n+2) 1st@fixer+3+2
  // & > :nth-last-child(1):nth-child(3n) 2nd@fixer+3+2

  // & > :nth-last-child(1):nth-child(5n+2) 1st@fixer+5+1
  // & > :nth-last-child(2):nth-child(5n+3) 1st@fixer+5+2
  // & > :nth-last-child(3):nth-child(5n+4) 1st@fixer+5+3
  // & > :nth-last-child(4):nth-child(5n) 1st@fixer+5+4
  // & > :nth-last-child(1):nth-child(5n+3) 2nd@fixer+5+2
  // & > :nth-last-child(2):nth-child(5n+4) 2nd@fixer+5+3
  // & > :nth-last-child(3):nth-child(5n) 2nd@fixer+5+4
  // & > :nth-last-child(1):nth-child(5n+4) 3rd@fixer+5+3
  // & > :nth-last-child(2):nth-child(5n) 3rd@fixer+5+4
  // & > :nth-last-child(1):nth-child(5n) 4th@fixer+5+4
  [...Array(column - 1)].forEach((_, colIndex) => {
    [...Array(column - 1 - colIndex)].forEach((_2, cardIndex) => {
      // add 1 for the fixer row item
      const nPlus = `+${colIndex + 1 + 1}`;
      classNames = [
        ...classNames,
        `${className} > :nth-last-child(${
          cardIndex + 1
        }):nth-child(${column}n${nPlus})`,
      ];
    });
  });

  return classNames;
};

export const generateContainerQueries = (
  className: string,
  columnWidth: string,
  columns: number[]
) =>
  columns.reduce((acc, column) => {
    const calcedWidthWithSmGutters = styling.sizes.utils.gridColumns(
      column,
      styling.sizes.context.gutteringSmW,
      true
    );

    if (calcedWidthWithSmGutters < styling.breakpoints.theme.sm) {
      return {
        ...acc,
        [styling.container.utils.containerQuery(
          'width',
          calcedWidthWithSmGutters
        )]: {
          [className]: {
            '--cardGridTemplateColumns': `repeat(${column}, ${
              column === 1 ? '100%' : columnWidth
            })`,
            '--cardFixerRowWidth': `span ${column}`,
          },
        },
      };
    }
    return {
      ...acc,
      [styling.container.utils.containerQuery(
        'width',
        styling.sizes.utils.gridColumns(
          column,
          styling.sizes.context.gutteringW,
          true
        )
      )]: {
        [className]: {
          '--cardGridTemplateColumns': `repeat(${column}, ${columnWidth})`,
          '--cardFixerRowWidth': `span ${column}`,
        },
      },
    };
  }, {});

export const generateWholeRowsQueries = (
  className: string,
  columns: number[]
) =>
  columns.reduce((acc, column, index) => {
    const rowClasses = generateWholeRowClassNames(className, column).join(',');
    const calcedWidthWithSmGutters = styling.sizes.utils.gridColumns(
      column,
      styling.sizes.context.gutteringSmW,
      true
    );

    if (calcedWidthWithSmGutters < styling.breakpoints.theme.sm) {
      const maxWidthSm =
        styling.sizes.utils.gridColumns(
          columns[index + 1],
          styling.sizes.context.gutteringSmW,
          true
        ) - 1;
      return {
        ...acc,
        [styling.container.utils.containerQuery(
          'width',
          calcedWidthWithSmGutters,
          maxWidthSm
        )]: {
          [rowClasses]: {
            display: 'none',
          },
        },
      };
    }

    const maxWidth =
      index < columns.length - 1
        ? styling.sizes.utils.gridColumns(
            columns[index + 1],
            styling.sizes.context.gutteringW,
            true
          ) - 1
        : undefined;

    return {
      ...acc,
      [styling.container.utils.containerQuery(
        'width',
        styling.sizes.utils.gridColumns(
          column,
          styling.sizes.context.gutteringW,
          true
        ),
        maxWidth
      )]: {
        [rowClasses]: {
          display: 'none',
        },
      },
    };
  }, {});
