import { z } from 'zod';

export const UserRegisterSchema = z.object({
  email: z.string().email(),
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  referralCode: z.string().optional(),
  orderId: z.string().optional(),
});

export type UserRegister = z.infer<typeof UserRegisterSchema>;
