export enum MembershipEventType {
  Pause = 'PAUSE',
  Unpause = 'UNPAUSE',

  Add1Y = 'ADD_1Y',

  Purchase1Y = 'PURCHASE_1Y',
  Purchase6M = 'PURCHASE_06M',
  Purchase3M = 'PURCHASE_03M',
  Purchase1M = 'PURCHASE_01M',

  Refund = 'REFUND',
}

export type MembershipEventTypeValues = `${MembershipEventType}`;
