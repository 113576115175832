import { z } from 'zod';

import { DateISOSchema } from '../../Date';

export const WebInfoLoadedModelSchema = z.object({
  srcUrl: z.string(),
  title: z.string(),
  description: z.string(),
  keyImagesBlobIds: z.string().array(),

  extractTime: DateISOSchema,
});

export const WebInfoUnloadedModelSchema = WebInfoLoadedModelSchema;

export type WebInfoLoadedModel = z.infer<typeof WebInfoLoadedModelSchema>;
export type WebInfoUnloadedModel = z.infer<typeof WebInfoUnloadedModelSchema>;

export type WebInfoModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? WebInfoLoadedModel : WebInfoUnloadedModel;
