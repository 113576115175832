import { z } from 'zod';

import {
  ContentBlockCardGridSaveModelSchema,
  ContentBlockTextSaveModelSchema,
} from '../../x-service';

export const AnyAuthorBlockSaveModelSchema = z.union([
  ContentBlockCardGridSaveModelSchema,
  ContentBlockTextSaveModelSchema,
]);

export type AnyAuthorBlockSaveModel = z.infer<
  typeof AnyAuthorBlockSaveModelSchema
>;
