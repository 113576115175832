import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0030: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 104"
		height={104}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M77.43 95.99A50.613 50.613 0 0 1 52 102.8C23.94 102.81 1.19 80.06 1.19 52S23.94 1.19 52 1.19 102.81 23.94 102.81 52c0 12.99-4.88 24.85-12.9 33.83" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M24.11 33.62c.14.26 2.24 4.22 8.74 1.9 4.72-1.94 10.6-3.35 18.27-3.35h.06c7.67 0 13.55 1.41 18.27 3.35 6.51 2.32 8.61-1.65 8.74-1.9" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M52 66.5V32.71M81.46 54.96c0 3.46-3.91 6.27-8.73 6.27S64 58.42 64 54.96s8.73-17.81 8.73-17.81 8.73 14.35 8.73 17.81ZM64.84 53.5h15.79M39.99 54.96c0 3.46-3.91 6.27-8.73 6.27s-8.73-2.81-8.73-6.27 8.73-17.81 8.73-17.81 8.73 14.35 8.73 17.81ZM23.37 53.5h15.79M52.02 71.44c-6.79 0-12.76 3.56-16.12 8.92l32.21-.06a19.004 19.004 0 0 0-16.09-8.86Z" /><circle vectorEffect="non-scaling-stroke" cx="52" cy="25.73" r="2.09" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0030);
