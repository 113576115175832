import { z } from 'zod';

export const UserVerifyEmailSchema = z.object({
  code: z.string(),
});

export type UserVerifyEmail = z.infer<typeof UserVerifyEmailSchema>;

export type UserVerifyEmailResendResponse = {
  sent: boolean;
};
