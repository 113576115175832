import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import { ImageAssetModelSchema } from '../../../asset-service';
import { EmailBlockType } from './Enum/EmailBlockType';

export const EmailImageLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ),
    linkUrl: z.string().url().optional(),
    type: z.literal(EmailBlockType.Image),
  })
);

export const EmailImageUnloadedModelSchema = EmailImageLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ),
  })
);

export type EmailImageLoadedModel = z.infer<typeof EmailImageLoadedModelSchema>;
export type EmailImageUnloadedModel = z.infer<
  typeof EmailImageUnloadedModelSchema
>;

export type EmailImageModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? EmailImageLoadedModel : EmailImageUnloadedModel;
