import { AuthenticateUserResponse } from 'quidproquo';
import { z } from 'zod';

import { RecaptchaResponseSchema } from '../../../google/recaptcha';
import { UserLoginSchema } from '../UserLogin';

export const UserLoginRequestSchema = UserLoginSchema.merge(
  RecaptchaResponseSchema
);

export type UserLoginRequest = z.infer<typeof UserLoginRequestSchema>;

export type UserLoginResponse = AuthenticateUserResponse;
