import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0022: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 134 92"
		height={92}
		width={134}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M62.1 45.52h39.57c3.81 0 6.93-3.11 6.93-6.92v-.9c0-3.81-3.11-6.92-6.93-6.92H69.85M62.1 60.27h20.29c3.81 0 6.93-3.11 6.93-6.92v-.9c0-3.81-3.11-6.93-6.92-6.93H68.69" /><path vectorEffect="non-scaling-stroke" d="M62.1 75.02h13.54c3.81 0 6.93-3.11 6.93-6.92v-.91c0-3.81-3.11-6.92-6.93-6.92h-4.06" /><path vectorEffect="non-scaling-stroke" d="m45.09 37.77 23.04-20.92c3.38-3.07 4.07-8.32 1.54-11.67C67.14 1.83 62 1.24 58.26 3.87l-34.79 24.6H6.71a4.88 4.88 0 0 0-4.88 4.88v51.54c0 2.7 2.19 4.88 4.88 4.88h66.31c3.28 0 5.97-2.69 5.97-5.97V81c0-3.28-2.69-5.97-5.97-5.97h-1.35" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m115.91 21.81 16.27 16.26M132.18 38.22l-16.27 16.27" /></g>
    </g>
  </CardIcon>
);

export default memo(In0022);
