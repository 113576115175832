import { z } from 'zod';

import {
  CardLoadedModelSchema,
  CardType,
  CardUnloadedModelSchema,
} from '../Card';
import { ResourceCardType } from './Enum';

export const ResourceBaseCardBaseModelSchema = z.object({
  cardType: z.literal(CardType.Resource),
  resourceType: z.nativeEnum(ResourceCardType),
  title: z.string(),
  label: z.string().optional(),
});

export const ResourceBaseCardLoadedModelSchema = CardLoadedModelSchema.merge(
  ResourceBaseCardBaseModelSchema
);
export const ResourceBaseCardUnloadedModelSchema =
  CardUnloadedModelSchema.merge(ResourceBaseCardBaseModelSchema);

export type ResourceBaseCardLoadedModel = z.infer<
  typeof ResourceBaseCardLoadedModelSchema
>;
export type ResourceBaseCardUnloadedModel = z.infer<
  typeof ResourceBaseCardUnloadedModelSchema
>;

export type ResourceBaseCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? ResourceBaseCardLoadedModel
    : ResourceBaseCardUnloadedModel;
