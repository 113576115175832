import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Eye: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 16"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M10.15 1.69A5.93 5.93 0 0 0 14 12.13c3.28 0 5.93-2.65 5.93-5.93 0-1.74-.75-3.31-1.95-4.4" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M14 3.74c-1.36 0-2.46 1.1-2.46 2.46s1.1 2.46 2.46 2.46 2.45-1.1 2.45-2.46" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.38 7.82S7.03 14.96 14 14.96 26.62 8 26.62 8 20.97 1.04 14 1.04 1.38 7.82 1.38 7.82Z" />
    </g>
  </WebsiteIcon>
);

export default memo(Eye);
