import { z } from 'zod';

export const EmailTemplateRenderRequestSchema = z.object({
  slug: z.string().max(128),
  data: z.record(z.any()),
});

export type EmailTemplateRenderRequest = z.infer<
  typeof EmailTemplateRenderRequestSchema
>;
