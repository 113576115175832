import { z } from 'zod';

import { DateISOSchema } from '../../../../../Date';
import { RlnkSlugSchema } from '../../../../slug-service';
import {
  CMSResourceBaseLoadedModelSchema,
  CMSResourceBaseUnloadedModelSchema,
} from '../CMSResourceBaseModel';

export const CMSLinkResourceBaseModelSchema = z.object({
  slug: RlnkSlugSchema,
  linkUrl: z.string().url(),
  description: z.string().optional(),

  createdAt: DateISOSchema,
  updatedAt: DateISOSchema,
});

export const CMSLinkResourceLoadedModelSchema =
  CMSResourceBaseLoadedModelSchema.merge(CMSLinkResourceBaseModelSchema);
export const CMSLinkResourceUnloadedModelSchema =
  CMSResourceBaseUnloadedModelSchema.merge(CMSLinkResourceBaseModelSchema);

export type CMSLinkResourceLoadedModel = z.infer<
  typeof CMSLinkResourceLoadedModelSchema
>;
export type CMSLinkResourceUnloadedModel = z.infer<
  typeof CMSLinkResourceUnloadedModelSchema
>;

export type CMSLinkResourceModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? CMSLinkResourceLoadedModel
    : CMSLinkResourceUnloadedModel;
