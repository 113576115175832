import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0019: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 90 118"
		height={118}
		width={90}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M1.33 117.07V77.3H11.8L1.33 66.92V16L19.13.93h69.53v14.23l-9.69 9.5h9.69V94.9h-8.1l8.1 10.59.01 11.58H1.33z" /><g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M27.98 71.34c.87 4.02 5.01 19.02 17.63 19.02 14.56 0 17.81-20 17.81-20l.12-.56s.54-3.26.66-6.65" /><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M39 67.03a1.23 1.23 0 1 1-2.46 0 1.23 1.23 0 1 1 2.46 0ZM54.42 67.03a1.23 1.23 0 1 1-2.46 0 1.23 1.23 0 1 1 2.46 0Z" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M38.38 88.44V81.4c0-2.21 1.71-4.03 3.8-4.03h6.78c2.09 0 3.8 1.81 3.8 4.03v7.04" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m61.54 50.55-3.62-13.93c-2.4-9.22-4.3-9.14-6.79-8.91-2.59.24-4.11 2.49-5.64 3.72-1.53-1.23-3.05-3.48-5.64-3.72-2.49-.22-4.39-.3-6.79 8.91l-3.63 13.93c-10.16 1.19-17.02 3.47-17.02 6.08 0 3.82 14.76 6.92 32.98 6.97 18.31-.03 33.18-3.13 33.18-6.97 0-2.61-6.86-4.88-17.03-6.08Z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M62.95 50.71c-4.4 1.06-10.53 1.72-17.31 1.72s-13.2-.69-17.61-1.79" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0019);
