import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0051: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 128 94"
		height={94}
		width={128}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M68.87 82.85h4.59s3.04-.31 3.04-3.56v-6.86s-.29-4.04-14.99-6.7c0 0-8.07-9.59-16.57-10.93H27.79s-6.63-.39-10.83 4.86c0 0-3.07 2.83-3.07 7.16v11.22s.32 3.67 6.24 3.67M33.72 82.01h21.53" /><circle vectorEffect="non-scaling-stroke" cx="25.11" cy="81.16" r="5.13" /><path vectorEffect="non-scaling-stroke" d="M69.01 81.16c0 2.84-2.3 5.14-5.14 5.14s-5.13-2.3-5.13-5.14 2.3-5.13 5.13-5.13c2.84 0 5.14 2.3 5.14 5.13Z" /></g><g ><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="m59.04 17.22-15.73-.23M58.81 24.25l-31.71-.53M58.59 31.28l-7.48-.24" /></g><circle vectorEffect="non-scaling-stroke" cx="97.36" cy="25.48" r="6.45" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M127.03 26.42c-1.83-3.28-14.76-22.39-62.07-9.67l-.49 15.38c46.54 15.76 60.59-2.64 62.56-5.71Z" /><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M83.11 7.43C79.17 4.12 71.72.07 58.96 2.15c0 0 4.33 1.77 8.41 8.14M81.98 42.59c-4.14 3.05-11.84 6.61-24.44 3.73 0 0 4.43-1.49 8.92-7.58" /></g></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m.97 92.16 92.93.26" />
    </g>
  </CardIcon>
);

export default memo(In0051);
