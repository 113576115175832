import { z } from 'zod';

import { RecaptchaResponseSchema } from '../../../google';

export const MMMUnsubscribeRequestSchema = z
  .object({
    email: z.string().email(),
    unsubscribeCode: z.string(),
  })
  .merge(RecaptchaResponseSchema);

export type MMMUnsubscribeRequest = z.infer<typeof MMMUnsubscribeRequestSchema>;
