import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0046: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 96"
		height={96}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M92.54 78.12h9.69c2.66 0 4.83 2.18 4.83 4.83v.56c0 2.66-2.18 4.83-4.83 4.83h-9.69M59.6 88.36H5.77c-2.66 0-4.83-2.18-4.83-4.83v-.56c0-2.66 2.18-4.83 4.83-4.83H59.6M56.93 42.88h45.3c2.66 0 4.83 2.18 4.83 4.84v.56c0 2.66-2.18 4.83-4.83 4.83h-45.3M23.1 53.12H5.77c-2.66 0-4.83-2.18-4.83-4.83v-.56c0-2.66 2.18-4.84 4.83-4.84H23.1M77.01 7.64h25.22c2.66 0 4.83 2.18 4.83 4.83v.56c0 2.66-2.18 4.84-4.83 4.84H77.01M44.16 17.88H5.77c-2.66 0-4.83-2.18-4.83-4.84v-.56c0-2.66 2.18-4.83 4.83-4.83h38.39" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M42.95 44.76c0 .36.29.66.66.66s.66-.29.66-.66-.29-.66-.66-.66-.66.29-.66.66ZM35.4 44.76c0 .36.29.66.66.66s.66-.29.66-.66-.29-.66-.66-.66-.66.29-.66.66Z" /><circle vectorEffect="non-scaling-stroke" cx="39.83" cy="48" r="11.56" /><path vectorEffect="non-scaling-stroke" d="M36.05 51.9h7.56" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M79.45 80c0 .36.29.66.66.66s.66-.29.66-.66-.29-.66-.66-.66-.66.29-.66.66ZM71.9 80c0 .36.29.66.66.66s.66-.29.66-.66-.29-.66-.66-.66-.66.29-.66.66Z" /><circle vectorEffect="non-scaling-stroke" cx="76.33" cy="83.24" r="11.56" /><path vectorEffect="non-scaling-stroke" d="M81.27 86.12c-.99 1.68-2.86 2.81-4.94 2.81s-3.95-1.13-4.94-2.81" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M63.66 9.52c0 .36.29.66.66.66s.66-.29.66-.66-.29-.66-.66-.66-.66.29-.66.66ZM56.11 9.52c0 .36.29.66.66.66s.66-.29.66-.66-.29-.66-.66-.66-.66.29-.66.66Z" /><circle vectorEffect="non-scaling-stroke" cx="60.54" cy="12.76" r="11.56" /><path vectorEffect="non-scaling-stroke" d="M65.48 15.64c-.99 1.68-2.86 2.81-4.94 2.81s-3.95-1.13-4.94-2.81" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0046);
