import { z } from 'zod';

import {
  ContentBlockCardGridSaveModelSchema,
  ContentBlockCarouselItemSaveModelSchema,
  ContentBlockCarouselSaveModelSchema,
  ContentBlockImageSaveModelSchema,
  ContentBlockQuoteSaveModelSchema,
  ContentBlockTextSaveModelSchema,
  ContentBlockVideoSaveModelSchema,
} from '../../x-service';
import { ArticleBlockExecutiveBoardSaveModelSchema } from './ArticleBlockExecutiveBoardModel';

export const AnyArticleBlockSaveModelSchema = z.union([
  ArticleBlockExecutiveBoardSaveModelSchema,
  ContentBlockImageSaveModelSchema,
  ContentBlockCardGridSaveModelSchema,
  ContentBlockCarouselSaveModelSchema,
  ContentBlockCarouselItemSaveModelSchema,
  ContentBlockVideoSaveModelSchema,
  ContentBlockQuoteSaveModelSchema,
  ContentBlockTextSaveModelSchema,
]);

export type AnyArticleBlockSaveModel = z.infer<
  typeof AnyArticleBlockSaveModelSchema
>;
