import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0035: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 114 114"
		height={114}
		width={114}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M33.29 20.29c0 2.99-2.42 5.42-5.42 5.42s-5.41-2.42-5.41-5.42 2.42-5.41 5.41-5.41 5.42 2.42 5.42 5.41ZM38.84 41.47v-4.9c0-2.34-1.92-4.25-4.26-4.25H21.53c-2.34 0-4.25 1.93-4.25 4.27v4.9" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M91.15 20.29c0 2.99-2.42 5.42-5.42 5.42s-5.41-2.42-5.41-5.42 2.42-5.41 5.41-5.41 5.42 2.42 5.42 5.41ZM96.71 41.47v-4.9c0-2.34-1.92-4.25-4.26-4.25H79.4c-2.34 0-4.25 1.93-4.25 4.27v4.9" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M33.29 81.65c0 2.99-2.42 5.42-5.42 5.42s-5.41-2.42-5.41-5.42 2.42-5.41 5.41-5.41 5.42 2.42 5.42 5.41ZM38.84 102.82v-4.89c0-2.34-1.92-4.25-4.26-4.25H21.53c-2.34 0-4.25 1.93-4.25 4.27v4.9" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M91.15 81.65c0 2.99-2.42 5.42-5.42 5.42s-5.41-2.42-5.41-5.42 2.42-5.41 5.41-5.41 5.42 2.42 5.42 5.41ZM96.71 102.82v-4.89c0-2.34-1.92-4.25-4.26-4.25H79.4c-2.34 0-4.25 1.93-4.25 4.27v4.9" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M57 1.29v111.42M112.71 57H1.29" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0035);
