import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0010: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 100 78"
		height={78}
		width={100}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M98.18 69.93c0-3.1-2.08-5.81-5.07-6.63l-11.38-3.09A40.636 40.636 0 0 1 60.58 48.2l-42.07-47C9.87 2.33 2.88 9.49 1.94 18.15c-.29 2.67-.03 7.14.67 9.52C6.3 40.1 10.2 60.59 10.2 73.56v3.25h6.28V47.26c0-5.1 6.28-7.52 9.7-3.75L51.41 71.3a16.78 16.78 0 0 0 12.43 5.51h27.45c3.79 0 6.87-3.07 6.87-6.87h0Z" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m66.67 14.86 5.09-5.09M70.24 22.71h7.19M67.21 30.79l5.08 5.08M58.82 11.3V4.1M50.75 14.33l-5.09-5.09" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0010);
