import { z } from 'zod';

import { RecaptchaResponseSchema } from '../../../google/recaptcha';
import { ContactUsSchema } from '../ContactUs';

export const ContactUsRequestSchema = ContactUsSchema.merge(
  RecaptchaResponseSchema
);

export type ContactUsRequest = z.infer<typeof ContactUsRequestSchema>;
