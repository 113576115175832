import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import { DateISOSchema } from '../../../../Date';
import { ImageAssetModelSchema } from '../../../asset-service';
import {
  AnyCMSPageBlockLoadedModelSchema,
  AnyCMSPageBlockUnloadedModelSchema,
} from './AnyCMSPageBlockModel';

export const CMSPageLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    slug: z.string(),

    content: z.string(),
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    pageBlocks: makeKittedModelReferenceSchema(
      AnyCMSPageBlockLoadedModelSchema,
      true
    ).array(),

    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,
  })
);

export const CMSPageUnloadedModelSchema = CMSPageLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),

    pageBlocks: makeKittedModelReferenceSchema(
      AnyCMSPageBlockUnloadedModelSchema,
      false
    ).array(),
  })
);

export type CMSPageLoadedModel = z.infer<typeof CMSPageLoadedModelSchema>;
export type CMSPageUnloadedModel = z.infer<typeof CMSPageUnloadedModelSchema>;

export type CMSPageModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? CMSPageLoadedModel : CMSPageUnloadedModel;
