import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0013: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 108"
		height={108}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m37.44 11.53-4-8.97c-.65-1.46-5.43-.75-10.67 1.58-5.24 2.34-8.96 5.41-8.31 6.87l4 8.97M64.97 52.63c-3.88 9.11-12.38 15.95-22.91 17.24-12.42 1.52-23.96-5.14-29.2-15.76" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M9.96 39.46c.9-13.46 11.25-24.82 25.13-26.52 6.14-.75 12.07.5 17.12 3.23" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M6.91 54.55c-2.12.16-4.54-1.77-5.4-5.16-.87-3.39-.08-5.97 2.26-7.13M21.82 34.01 3.76 42.26M6.91 54.55l19.51-1.43" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M33.82 54.69c-4.24.35-9.52-3.71-11.34-10.83-1.82-7.12.41-12.9 4.73-14.95M78.55 5.45 27.21 28.9M33.82 54.69l56.29-4.12M107.99 21.97c3.24 12.65-3.04 25.18-14.02 27.99-10.99 2.81-22.52-5.15-25.76-17.8-3.24-12.65 3.04-25.18 14.02-27.99 10.98-2.81 22.52 5.15 25.76 17.8ZM31.5 69.32 16.38 106.2M46.32 69.32l15.13 36.88M23.25 90.38h31.33" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M98.21 24.47c1.65 6.43-1.54 12.79-7.13 14.22s-11.44-2.62-13.09-9.04c-1.65-6.43 1.54-12.79 7.13-14.22 5.58-1.43 11.44 2.62 13.09 9.05Z" />
    </g>
  </CardIcon>
);

export default memo(Fr0013);
