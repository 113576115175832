import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../base';
import { ImageAssetModelSchema } from '../asset-service';
import { ResourceType } from './Enum';

export const ResourceBaseLoadedModelSchema =
  KittedVersionedModelBaseSchema.merge(
    z.object({
      resourceType: z.nativeEnum(ResourceType),

      title: z.string(),
      label: z.string().optional(),

      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        true
      ).optional(),
    })
  );

export const ResourceBaseUnloadedModelSchema =
  ResourceBaseLoadedModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
    })
  );

export const ResourceBaseSaveModelSchema = KittedSaveModelBaseSchema.merge(
  z.object({
    // title: ResourceBaseUnloadedModelSchema.shape.title.optional(),
    // label: ResourceBaseUnloadedModelSchema.shape.label.optional(),

    resourceType: ResourceBaseUnloadedModelSchema.shape.resourceType.optional(),

    createdAt: ResourceBaseUnloadedModelSchema.shape.createdAt.optional(),
    updatedAt: ResourceBaseUnloadedModelSchema.shape.updatedAt.optional(),

    createdByAuthorId:
      ResourceBaseUnloadedModelSchema.shape.createdByAuthorId.optional(),
    createdByUserId:
      ResourceBaseUnloadedModelSchema.shape.createdByUserId.optional(),
  })
);

export type ResourceBaseLoadedModel = z.infer<
  typeof ResourceBaseLoadedModelSchema
>;
export type ResourceBaseUnloadedModel = z.infer<
  typeof ResourceBaseUnloadedModelSchema
>;
export type ResourceBaseSaveModel = z.infer<
  typeof ResourceBaseUnloadedModelSchema
>;

export type ResourceBaseModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? ResourceBaseLoadedModel : ResourceBaseUnloadedModel;
