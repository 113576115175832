import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0037: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 86 86"
		height={86}
		width={86}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m29.79 56.21 26.42-26.42M29.97 45.21l10.82 10.82M37.59 37.59l10.82 10.82M45.21 29.97l10.82 10.82" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m9.93 58.15 24.88 24.88M58.15 9.93l24.88 24.88" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M78.78 1.58c2.72.38 5.26 2.92 5.64 5.64 0 0 5.21 37.42-17.28 59.91C44.65 89.63 7.23 84.41 7.23 84.41c-2.72-.38-5.26-2.92-5.64-5.64 0 0-5.21-37.42 17.29-59.91C41.36-3.63 78.78 1.58 78.78 1.58Z" />
    </g>
  </CardIcon>
);

export default memo(Ac0037);
