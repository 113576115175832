import { z } from 'zod';

import { ArticleModel } from '../../article-service';
import { AnyAssetModel } from '../../asset-service';
import { AuthorModel } from '../../author-service';
import { AnyCardUnloadedModel } from '../../cards-service';
import { FlowModel } from '../../flow-service';
import { KitModel } from '../../kit-service';
import { AnyResourceModel } from '../../resource-service';
//  import {
//   ActionToolCardUnloadedModelSchema,
//   ArticleCardUnloadedModelSchema,
//   AuthorCardUnloadedModelSchema,
//   DeckCardUnloadedModelSchema,
//   FlowCardUnloadedModelSchema,
//   FlowStepUnloadedCardModelSchema,
//   FrameToolCardUnloadedModelSchema,
//   ImageAssetModelSchema,
//   ImageResourceCardUnloadedModelSchema,
//   InsightToolCardUnloadedModelSchema,
//   KitCardUnloadedModelSchema,
//   LinkResourceCardUnloadedModelSchema,
//   SnareToolCardUnloadedModelSchema,
//   SubjectCardUnloadedModelSchema,
//   VideoResourceCardUnloadedModelSchema,
// } from '../../cards-service';
import { WebsocketServerMessageType } from './enum';
import { WebsocketServerMessageSchema } from './WebsocketServerMessage';

export const WebsocketModelUpdateServerMessageSchema =
  WebsocketServerMessageSchema.merge(
    z.object({
      messageType: z.literal(WebsocketServerMessageType.ModelUpdate),
      model: z.any(),
      // model: z.union([
      //   ArticleCardUnloadedModelSchema,
      //   ImageAssetModelSchema,
      //   AuthorCardUnloadedModelSchema,
      //   DeckCardUnloadedModelSchema,
      //   FlowCardUnloadedModelSchema,
      //   FlowStepUnloadedCardModelSchema,
      //   KitCardUnloadedModelSchema,
      //   SubjectCardUnloadedModelSchema,
      //   ActionToolCardUnloadedModelSchema,
      //   FrameToolCardUnloadedModelSchema,
      //   InsightToolCardUnloadedModelSchema,
      //   SnareToolCardUnloadedModelSchema,
      //   ImageResourceCardUnloadedModelSchema,
      //   LinkResourceCardUnloadedModelSchema,
      //   VideoResourceCardUnloadedModelSchema,
      //  ]),
    })
  );

// Override the any type due to the schema limitation
export type WebsocketModelUpdateServerMessage = Omit<
  z.infer<typeof WebsocketModelUpdateServerMessageSchema>,
  'model'
> & {
  model:
    | AnyCardUnloadedModel
    | AnyAssetModel
    | ArticleModel
    | AuthorModel
    | KitModel
    | FlowModel
    | AnyResourceModel;
};
