import { z } from 'zod';

import { SlugPrefixEnum } from './enum';

type SlugPlatformPrefix = `${SlugPrefixEnum}`;
type SlugUserPrefix = `${SlugPlatformPrefix}-${string}${string}`;

export type SlugPrefix = SlugPlatformPrefix | SlugUserPrefix;
export type SlugCode = `${number}${number}${number}${number}`;
export type Slug = `${SlugPrefix}-${SlugCode}`;

export interface CompositeSlug {
  // type: Type;
  code: SlugCode;
  prefix: SlugPrefix;
}

// Helper functions to create a regex for a specific prefix
function createSlugRegex(prefix: SlugPrefixEnum): RegExp {
  return new RegExp(`^(${prefix})-([A-Z]{2}-)?([0-9]{4,})$`);
}

function createSlugSchema<T extends SlugPrefixEnum>(prefix: T) {
  const lowerCasePrefix = prefix.toLowerCase();
  const readablePrefix =
    lowerCasePrefix.charAt(0).toUpperCase() +
    lowerCasePrefix.slice(1).toLowerCase();

  return z.custom<`${T}-${SlugCode}`>(
    (val) => createSlugRegex(prefix).test(val as string),
    `Invalid ${readablePrefix} Slug`
  );
}
// ////////////////////////////////////////////////////////

// Schemas for specific Slug Formats
export const ArticleSlugSchema = createSlugSchema(SlugPrefixEnum.Article);
export const AuthorSlugSchema = createSlugSchema(SlugPrefixEnum.Author);
export const FlowSlugSchema = createSlugSchema(SlugPrefixEnum.Flow);
export const KitSlugSchema = createSlugSchema(SlugPrefixEnum.Kit);
export const ResourceSlugSchema = createSlugSchema(SlugPrefixEnum.Resource);
export const DeckSlugSchema = createSlugSchema(SlugPrefixEnum.Deck);
export const ActionSlugSchema = createSlugSchema(SlugPrefixEnum.Action);
export const SnareSlugSchema = createSlugSchema(SlugPrefixEnum.Snare);
export const FrameSlugSchema = createSlugSchema(SlugPrefixEnum.Frame);
export const InsightSlugSchema = createSlugSchema(SlugPrefixEnum.Insight);
export const RlnkSlugSchema = createSlugSchema(SlugPrefixEnum.Rlnk);
export const RimgSlugSchema = createSlugSchema(SlugPrefixEnum.Rimg);
export const RvidSlugSchema = createSlugSchema(SlugPrefixEnum.Rvid);
export const SubjectSlugSchema = createSlugSchema(SlugPrefixEnum.Subject);
export const DisplaySlugSchema = createSlugSchema(SlugPrefixEnum.Display);
export const DevOnlySlugSchema = createSlugSchema(SlugPrefixEnum.DevOnly);

// Types
export type ArticleSlug = z.infer<typeof ArticleSlugSchema>;
export type AuthorSlug = z.infer<typeof AuthorSlugSchema>;
export type FlowSlug = z.infer<typeof FlowSlugSchema>;
export type KitSlug = z.infer<typeof KitSlugSchema>;
export type ResourceSlug = z.infer<typeof ResourceSlugSchema>;
export type DeckSlug = z.infer<typeof DeckSlugSchema>;
export type ActionSlug = z.infer<typeof ActionSlugSchema>;
export type SnareSlug = z.infer<typeof SnareSlugSchema>;
export type FrameSlug = z.infer<typeof FrameSlugSchema>;
export type InsightSlug = z.infer<typeof InsightSlugSchema>;
export type RlnkSlug = z.infer<typeof RlnkSlugSchema>;
export type RimgSlug = z.infer<typeof RimgSlugSchema>;
export type RvidSlug = z.infer<typeof RvidSlugSchema>;
export type SubjectSlug = z.infer<typeof SubjectSlugSchema>;
export type DisplaySlug = z.infer<typeof DisplaySlugSchema>;
export type DevOnlySlug = z.infer<typeof DevOnlySlugSchema>;

// Enum / Type map
export type SlugTypeMap = {
  [SlugPrefixEnum.Article]: ArticleSlug;
  [SlugPrefixEnum.Author]: AuthorSlug;
  [SlugPrefixEnum.Flow]: FlowSlug;
  [SlugPrefixEnum.Kit]: KitSlug;
  [SlugPrefixEnum.Resource]: ResourceSlug;
  [SlugPrefixEnum.Deck]: DeckSlug;
  [SlugPrefixEnum.Action]: ActionSlug;
  [SlugPrefixEnum.Snare]: SnareSlug;
  [SlugPrefixEnum.Frame]: FrameSlug;
  [SlugPrefixEnum.Insight]: InsightSlug;
  [SlugPrefixEnum.Rlnk]: RlnkSlug;
  [SlugPrefixEnum.Rimg]: RimgSlug;
  [SlugPrefixEnum.Rvid]: RvidSlug;
  [SlugPrefixEnum.Subject]: SubjectSlug;
  [SlugPrefixEnum.Display]: DisplaySlug;
  [SlugPrefixEnum.DevOnly]: DevOnlySlug;
};

// ////////////////////////////////////////////////////////

export const slugPlatformPrefixValues: SlugPrefixEnum[] = Object.values(
  SlugPrefixEnum
) as SlugPrefixEnum[];

const AnySlugRegex = new RegExp(
  `^(${slugPlatformPrefixValues.join('|')})-([A-Z]{2}-)?([0-9]{4,})$`
);

export const AnySlugSchema = z.custom<Slug>(
  (val) => AnySlugRegex.test(val as string),
  'Invalid Slug'
);
