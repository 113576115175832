import {
  AuthenticateUserResponse,
  AuthenticationDeliveryDetails,
} from 'quidproquo';
import { z } from 'zod';

import { RecaptchaResponseSchema } from '../../../google/recaptcha';
import {
  UserConfirmForgetPasswordSchema,
  UserForgetPasswordSchema,
} from '../UserForgotPassword';

// Forgot
export const UserForgotPasswordRequestSchema = UserForgetPasswordSchema.merge(
  RecaptchaResponseSchema
);

export type UserForgotPasswordRequest = z.infer<
  typeof UserForgotPasswordRequestSchema
>;

export type UserForgotPasswordResponse = AuthenticationDeliveryDetails;

// Confirm
export type UserConfirmForgotPasswordRequest = z.infer<
  typeof UserConfirmForgetPasswordSchema
>;

export type UserConfirmForgotPasswordResponse = AuthenticateUserResponse;
