import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import {
  CardLoadedModelSchema,
  CardUnloadedModelSchema,
} from '../../../cards-service';
import { ContentBlockTheme, ContentBlockType } from './Enum';

export const ContentBlockCardGridLoadedModelSchema =
  KittedVersionedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(ContentBlockTheme),
      type: z.literal(ContentBlockType.CardGrid),
      cards: makeKittedModelReferenceSchema(
        CardLoadedModelSchema,
        true
      ).array(),
    })
  );

export const ContentBlockCardGridUnloadedModelSchema =
  ContentBlockCardGridLoadedModelSchema.merge(
    z.object({
      cards: makeKittedModelReferenceSchema(
        CardUnloadedModelSchema,
        false
      ).array(),
    })
  );

export const ContentBlockCardGridSaveModelSchema =
  ContentBlockCardGridUnloadedModelSchema.merge(KittedSaveModelBaseSchema);

export type ContentBlockCardGridLoadedModel = z.infer<
  typeof ContentBlockCardGridLoadedModelSchema
>;
export type ContentBlockCardGridUnloadedModel = z.infer<
  typeof ContentBlockCardGridUnloadedModelSchema
>;
export type ContentBlockCardGridSaveModel = z.infer<
  typeof ContentBlockCardGridSaveModelSchema
>;

export type ContentBlockCardGridModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? ContentBlockCardGridLoadedModel
    : ContentBlockCardGridUnloadedModel;
