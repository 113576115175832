import { z } from 'zod';

import { RecaptchaResponseSchema } from '../../../google/recaptcha';
import { UserVerifyEmailSchema } from '../UserVerifyEmail';

export const UserVerifyEmailRequestSchema = UserVerifyEmailSchema.merge(
  RecaptchaResponseSchema
);

export type UserVerifyEmailRequest = z.infer<
  typeof UserVerifyEmailRequestSchema
>;

export type UserVerifyEmailResponse = void;
