import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      lineHeight: '1em',
      verticalAlign: 'middle',
      height: 'var(--websiteIconSize)',
      width: 'var(--websiteIconSize)',
    },
    'size-inherit': {
      '--websiteIconSize': '1em',
    },
    'size-sm': {
      '--websiteIconSize': styling.utils.px(styling.sizes.context.icon.sm),
    },
    'size-md': {
      '--websiteIconSize': styling.utils.px(styling.sizes.context.icon.md),
    },
    'size-lg': {
      '--websiteIconSize': styling.utils.px(styling.sizes.context.icon.lg),
    },
  },
  { name: 'websiteIcon' }
);
