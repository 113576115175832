import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../base';
import { ImageAssetModelSchema } from '../../asset-service';
import {
  EmailPanelLoadedModelSchema,
  EmailPanelUnloadedModelSchema,
} from './EmailPanelModel';
import { EmailTemplateTheme } from './Enum';

export const EmailTemplateLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    slug: z.string().max(128),
    subject: z.string().max(128),
    theme: z.nativeEnum(EmailTemplateTheme),
    content: makeKittedModelReferenceSchema(
      EmailPanelLoadedModelSchema,
      true
    ).array(),
    ipadImage: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    ipadImageLinkUrl: z.string().url().optional(),
  })
);

export const EmailTemplateUnloadedModelSchema =
  EmailTemplateLoadedModelSchema.merge(
    z.object({
      content: makeKittedModelReferenceSchema(
        EmailPanelUnloadedModelSchema,
        false
      ).array(),
      ipadImage: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
    })
  );

export type EmailTemplateLoadedModel = z.infer<
  typeof EmailTemplateLoadedModelSchema
>;
export type EmailTemplateUnloadedModel = z.infer<
  typeof EmailTemplateUnloadedModelSchema
>;

export type EmailTemplateModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? EmailTemplateLoadedModel : EmailTemplateUnloadedModel;
