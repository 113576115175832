import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../base';
import { notBoth } from '../../../zodHelpers';
import { ImageAssetModelSchema } from '../../asset-service';
import { CardLoadedModelSchema } from '../../cards-service';
import {
  FlowStepDisplayType,
  FlowStepElements,
  FlowStepTextAlignment,
} from './Enum';

export const FlowStepLoadedModelSchema = KittedVersionedModelBaseSchema.merge(
  z.object({
    alignment: z.nativeEnum(FlowStepTextAlignment),
    elementVisibility: z.nativeEnum(FlowStepElements).array(),
    imageIsInset: z.boolean().optional(),
    imageIsFaded: z.boolean().optional(),
    content: z.string().optional(),
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    buttonText: z.string().optional(),
    buttonLink: z.string().optional(),
    card: makeKittedModelReferenceSchema(
      CardLoadedModelSchema,
      false
    ).optional(),

    flowStepDisplay: z.nativeEnum(FlowStepDisplayType),
  })
);

export const FlowStepUnloadedModelSchema = FlowStepLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),
  })
);

export const FlowStepSaveModelSchema = FlowStepUnloadedModelSchema.merge(
  KittedSaveModelBaseSchema
)
  .merge(
    z.object({
      flowStepDisplay: z.nativeEnum(FlowStepDisplayType).optional(),
      elementVisibility: z.nativeEnum(FlowStepElements).array().optional(),
      keyImageBlobId: z.string().optional().nullable(),
    })
  )
  .superRefine(notBoth('keyImageBlobId', 'keyImageImageAsset'));

export type FlowStepLoadedModel = z.infer<typeof FlowStepLoadedModelSchema>;
export type FlowStepUnloadedModel = z.infer<typeof FlowStepUnloadedModelSchema>;
export type FlowStepSaveModel = z.infer<typeof FlowStepSaveModelSchema>;

export type FlowStepModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? FlowStepLoadedModel : FlowStepUnloadedModel;
