import { memo } from 'react';
import clsx from 'clsx';

import HTMLDecode from '../../HTMLDecode';
import CardBody from '../CardBody';
import CardTitle from '../CardTitle';
import useCardContents from './hooks/useCardContents';
import useStyles from './styles';
import { CardContentsProps } from './types';

const CardContents = ({
  title,
  body,
  isFeatured,
  isHover,
  type,
  variant = 'belowMiddle',
}: CardContentsProps) => {
  const { style } = useCardContents(type, isFeatured);
  const styles = useStyles();

  return (
    <div
      className={clsx(
        styles.container,
        styles[`variant-${variant}`],
        isFeatured && styles.containerFeatured,
        isHover && styles.containerHover
      )}
      style={style}
    >
      <div className={styles.cardTitleContainer}>
        <CardTitle isFeatured={isFeatured} type={type}>
          {title}
        </CardTitle>
      </div>
      {body && (
        <CardBody
          className={styles.cardBody}
          isFeatured={isFeatured}
          isHover={isHover}
          type={type}
        >
          <HTMLDecode content={body} />
        </CardBody>
      )}
    </div>
  );
};

export default memo(CardContents);
