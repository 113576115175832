import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0023: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 70 102"
		height={102}
		width={70}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <rect vectorEffect="non-scaling-stroke" width="66.36" height="90.65" x="1.82" y="1.75" stroke={color} rx="33.18" ry="33.18" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M35 82.39c-13.01 0-23.59-10.58-23.59-23.59V35.36c0-13.01 10.58-23.59 23.59-23.59s23.59 10.58 23.59 23.59V58.8c0 13.01-10.58 23.59-23.59 23.59ZM7.08 100.25h55.84M48.9 89.35v10.9H21.09V89.32" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m22.21 53.46 25.56-25.55M22.21 66.24l25.56-25.55" />
    </g>
  </CardIcon>
);

export default memo(Sn0023);
