import { z } from 'zod';

import { DateISOSchema } from '../../../Date';
import { OrderModelSchema } from '../OrderModel/OrderModel';

export const RedeemableOrderModelSchema = OrderModelSchema.merge(
  z.object({
    redeemedAt: DateISOSchema.optional(),
    redeemed: z.boolean(),
  })
);

export type RedeemableOrderModel = z.infer<typeof RedeemableOrderModelSchema>;
