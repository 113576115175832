import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0015: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 144"
		height={144}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M107.76 55.15V1.88H30.09L2.24 29.72v112.4h105.52V55.15z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M21.89 82.68h66.22M21.89 100.42h66.22M21.89 118.17h66.22" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M44.4 28.4c-3.5 2.99-5.72 7.44-5.72 12.41 0 9.02 7.31 16.32 16.32 16.32s16.32-7.31 16.32-16.32c0-4.8-2.07-9.12-5.37-12.11" /><path vectorEffect="non-scaling-stroke" d="M55 34.05c-3.74 0-6.76 3.03-6.76 6.76s3.03 6.76 6.76 6.76 6.76-3.03 6.76-6.76" /><path vectorEffect="non-scaling-stroke" d="M20.25 45.28S35.81 64.94 55 64.94s34.75-19.17 34.75-19.17S74.19 26.6 55 26.6 20.25 45.27 20.25 45.27Z" /></g>
    </g>
  </CardIcon>
);

export default memo(In0015);
