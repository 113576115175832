import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0059: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 132 84"
		height={84}
		width={132}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m16.53 33.93 11.72 38.32v10.47h75.5V72.25l15.84-51.8-30.74 22.97L66.03 14.1h-.06L43.15 43.42 12.42 20.45" /><path vectorEffect="non-scaling-stroke" d="m47 25.52-8.58-8.14-5.72 8.69M85 25.52l8.58-8.14 5.72 8.69" /><circle vectorEffect="non-scaling-stroke" cx="66" cy="7.16" r="5.89" /><circle vectorEffect="non-scaling-stroke" cx="7.41" cy="15.08" r="5.89" /><circle vectorEffect="non-scaling-stroke" cx="124.59" cy="15.08" r="5.89" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M78.5 46.57c-2.9-2.9-7.71-2.86-10.67.1L66 48.5l-1.83-1.83c-2.96-2.97-7.76-3.01-10.67-.1-2.91 2.91-2.86 7.7.1 10.67L66 69.61l12.39-12.37c2.97-2.97 3.01-7.76.11-10.67Z" />
    </g>
  </CardIcon>
);

export default memo(In0059);
