import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0055: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 92 106"
		height={106}
		width={92}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="m16.14 56.51 25.64-32.88a2.998 2.998 0 0 1 4.19-.52l7.97 6.22a2.998 2.998 0 0 1 .52 4.19L25.08 71.2c-3.03 3.88-8.68 4.58-12.56 1.55l-7.84-6.12C.8 63.6.1 57.95 3.13 54.07l31.99-41.05c4.73-6.06 6.9-8.47 10.97-8.98l16.72-2.88c4.07-.5 7.81 2.42 8.32 6.49l1.28 16.92c.5 4.07-1.31 6.66-6.07 12.76" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m54.25 41.62 28.6 37.56-18.15 25.11-28.6-37.56" /><path vectorEffect="non-scaling-stroke" d="m58.38 36.4 32.39 42.51-18.78 25.98M64.55 79.99c.24-.33.63-.32.89.02.25.34.27.87.03 1.2s-.64.32-.89-.02c-.26-.34-.27-.87-.03-1.2ZM48.43 58.68c.24-.33.63-.32.89.02.25.34.27.87.03 1.2s-.64.32-.89-.02-.27-.87-.03-1.2ZM58.62 56.96 50.9 46.73" /><path vectorEffect="non-scaling-stroke" d="m54.91 82.21 7.18 9.61c1.24-1.71 3.32-1.67 4.66.09l6.68-9.24c-1.33-1.76-1.41-4.58-.17-6.3l-5.68-7.53M39.73 62.17l5.74 7.51" /><ellipse cx="56.62" cy="69.69" rx="7.56" ry="5.4" transform="rotate(-57.01 56.62 69.7)" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m36.1 73.94-6.19 7.51" />
    </g>
  </CardIcon>
);

export default memo(In0055);
