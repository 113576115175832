import { z } from 'zod';

import {
  ArticleCardLoadedModelSchema,
  ArticleCardUnloadedModelSchema,
} from '../Article';
import {
  AuthorCardLoadedModelSchema,
  AuthorCardUnloadedModelSchema,
} from '../Author';
import {
  DeckCardLoadedModelSchema,
  DeckCardUnloadedModelSchema,
} from '../Deck';
import {
  DisplayLoadedModelSchema,
  DisplayUnloadedModelSchema,
} from '../Display';
import {
  FlowCardLoadedModelSchema,
  FlowCardUnloadedModelSchema,
} from '../Flow';
import { KitCardLoadedModelSchema, KitCardUnloadedModelSchema } from '../Kit';
import {
  AnyResourceCardLoadedModelSchema,
  AnyResourceCardUnloadedModelSchema,
} from '../Resource';
import {
  SubjectCardLoadedModelSchema,
  SubjectCardUnloadedModelSchema,
} from '../Subject';
import {
  AnyToolCardLoadedModelSchema,
  AnyToolCardUnloadedModelSchema,
} from '../Tool';

export const AnyCardLoadedModelSchema = z.union([
  ArticleCardLoadedModelSchema,
  AuthorCardLoadedModelSchema,
  DeckCardLoadedModelSchema,
  FlowCardLoadedModelSchema,
  KitCardLoadedModelSchema,
  AnyResourceCardLoadedModelSchema,
  SubjectCardLoadedModelSchema,
  AnyToolCardLoadedModelSchema,
  DisplayLoadedModelSchema,
]);

export const AnyCardUnloadedModelSchema = z.union([
  ArticleCardUnloadedModelSchema,
  AuthorCardUnloadedModelSchema,
  DeckCardUnloadedModelSchema,
  FlowCardUnloadedModelSchema,
  KitCardUnloadedModelSchema,
  AnyResourceCardUnloadedModelSchema,
  SubjectCardUnloadedModelSchema,
  AnyToolCardUnloadedModelSchema,
  DisplayUnloadedModelSchema,
]);

export type AnyCardLoadedModel = z.infer<typeof AnyCardLoadedModelSchema>;
export type AnyCardUnloadedModel = z.infer<typeof AnyCardUnloadedModelSchema>;

export type AnyCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AnyCardLoadedModel : AnyCardUnloadedModel;
