import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../../base';
import { ImageAssetModelSchema } from '../../../asset-service';
import {
  ContentBlockCarouselItemMediaSide,
  ContentBlockCarouselItemMediaType,
  ContentBlockTheme,
  ContentBlockType,
} from './Enum';

export const ContentBlockCarouselItemLoadedModelSchema =
  KittedVersionedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(ContentBlockTheme),
      label: z.string().optional(),
      content: z.string().optional(),
      buttonText: z.string().optional(),
      buttonLink: z.string().optional(),

      mediaType: z.nativeEnum(ContentBlockCarouselItemMediaType),
      mediaSide: z.nativeEnum(ContentBlockCarouselItemMediaSide),
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        true
      ),
      splayImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        true
      ).optional(),
      type: z.literal(ContentBlockType.CarouselItem),
    })
  );

export const ContentBlockCarouselItemUnloadedModelSchema =
  ContentBlockCarouselItemLoadedModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ),
      splayImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
    })
  );

export const ContentBlockCarouselItemSaveModelSchema =
  ContentBlockCarouselItemUnloadedModelSchema.merge(
    KittedSaveModelBaseSchema
  ).merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
      splayImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
      keyImageBlobId: z.string().optional(),
      splayImageBlobId: z.string().optional(),
    })
  );

export type ContentBlockCarouselItemLoadedModel = z.infer<
  typeof ContentBlockCarouselItemLoadedModelSchema
>;
export type ContentBlockCarouselItemUnloadedModel = z.infer<
  typeof ContentBlockCarouselItemUnloadedModelSchema
>;
export type ContentBlockCarouselItemSaveModel = z.infer<
  typeof ContentBlockCarouselItemSaveModelSchema
>;

export type ContentBlockCarouselItemModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? ContentBlockCarouselItemLoadedModel
    : ContentBlockCarouselItemUnloadedModel;
