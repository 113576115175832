import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0011: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 90"
		height={90}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M65.52 33.3c0 6-4.86 10.86-10.86 10.86S43.8 39.3 43.8 33.3s4.86-10.86 10.86-10.86S65.52 27.3 65.52 33.3ZM61.41 49.15l-6.76 5.99-6.76-5.99c-6.74 1.71-11.51 6.1-11.51 12.62v3.18h36.53v-3.18c0-6.52-4.77-10.9-11.51-12.62ZM23.58 35.61c0 4.67-3.78 8.45-8.45 8.45s-8.45-3.78-8.45-8.45 3.78-8.45 8.45-8.45 8.45 3.78 8.45 8.45ZM20.39 47.94l-5.26 4.66-5.26-4.66C4.62 49.27.91 52.69.91 57.76v2.47h28.43v-2.47c0-5.07-3.71-8.49-8.95-9.82ZM101.33 35.61c0 4.67-3.78 8.45-8.45 8.45s-8.45-3.78-8.45-8.45 3.78-8.45 8.45-8.45 8.45 3.78 8.45 8.45ZM98.13 47.94l-5.26 4.66-5.26-4.66c-5.25 1.33-8.96 4.75-8.96 9.82v2.47h28.43v-2.47c0-5.07-3.71-8.49-8.95-9.82ZM88.2 4.76 74.33 17.12l13.94 1.81-.07-14.17zM81.43 10.77C73.47 4.13 63.25.58 52.84.97c-13.41.5-25.48 7.13-33.11 18.18" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m19.8 85.24 13.87-12.36-13.94-1.81.07 14.17zM26.56 79.22c7.95 6.65 18.19 10.2 28.61 9.81 13.41-.5 25.47-7.13 33.1-18.18" />
    </g>
  </CardIcon>
);

export default memo(Ac0011);
