import { AuthenticateUserResponse } from 'quidproquo';
import { z } from 'zod';

import { RecaptchaResponseSchema } from '../../../google/recaptcha';
import { UserRegisterSchema } from '../UserRegister';

export const UserRegisterRequestSchema = UserRegisterSchema.merge(
  RecaptchaResponseSchema
);

export type UserRegisterRequest = z.infer<typeof UserRegisterRequestSchema>;

export type UserRegisterResponse = AuthenticateUserResponse;
