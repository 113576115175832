import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const HeartPlus: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M24.41 6.52a5.654 5.654 0 0 0-1.63-3.35c-2.26-2.26-5.99-2.22-8.29.08l-1.43 1.42-1.42-1.42C9.34.95 5.61.91 3.35 3.17c-2.26 2.26-2.22 5.99.08 8.29l8.66 8.65c.53.53 1.39.53 1.92 0l2.88-2.87" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M26.3 12.53a4.801 4.801 0 0 1-9.6 0 4.801 4.801 0 0 1 9.6 0ZM23.77 12.53h-4.52M21.51 14.79v-4.52" />
    </g>
  </WebsiteIcon>
);

export default memo(HeartPlus);
