import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0020: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 142 102"
		height={102}
		width={142}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M81.38 20.23c-9.08-1.22-17.57-1.57-21.51-1.68M28.97 14.78l-17.31-4.69a7.632 7.632 0 0 0-6.88 1.01c-2.02 1.45-3.18 3.7-3.18 6.18 0 2.62 1.39 5.11 3.62 6.48l30.74 18.9 19.08 21.02-.77 21.14c-.1 2.79 1.08 5.4 3.24 7.16a8.739 8.739 0 0 0 7.66 1.72l33.44-8.21 20.27 14.25c1.23.9 2.65 1.36 4.11 1.36 1.12 0 2.28-.27 3.4-.81 3.11-1.49 5.3-4.76 5.44-8.13.1-2.28-.78-4.51-2.41-6.11l-13.48-13.3" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M115.96 72.74c22.3-9.98 24.37-41.24 24.44-42.63.04-.79-.3-1.55-.93-2.04-.63-.48-1.45-.63-2.2-.39-.17.05-16.92 5.26-34.18-2.17-6.08-2.62-14.1-4.25-21.71-5.28" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m81.78 72.43-9.15 1.81 1.54-7.42 44.71 32.91M96.53 55.6 87.8 27.96c-2.58-7.26-8.53-8-8.53-8-8.26-1.01-15.77-1.31-19.4-1.41" /><circle vectorEffect="non-scaling-stroke" cx="44.37" cy="15.61" r="14.7" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M69.39 31.62s13.21 11.71 46.57 41.11" />
    </g>
  </CardIcon>
);

export default memo(Fr0020);
