/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef, useState } from 'react';
import { EMPTY_OBJ } from '@kitted/utils';

const useTextSkeleton = () => {
  const textSkeletonRef = useRef<HTMLDivElement | null>(null);
  const [style, setStyle] = useState<React.CSSProperties>(EMPTY_OBJ);

  const onSetRef = useCallback((ref: HTMLDivElement | null) => {
    textSkeletonRef.current = ref;
    if (ref) {
      setStyle({
        '--textSkeletonFontSize': (textSkeletonRef?.current as any)
          ?.computedStyleMap?.()
          .get('font-size')
          ?.toString(),
      } as React.CSSProperties);
    }
  }, []);

  return {
    onSetRef,
    style,
  };
};

export default useTextSkeleton;
