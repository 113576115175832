import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0053: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 116 102"
		height={102}
		width={116}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M93.13 16.01c.07-.4-.28 5.58-8.95 4.54l-1.28-.13c-5.95-1-12.45-1.03-21.3.97l-.07.02c-8.85 2-14.7 4.83-19.64 8.3l-1.1.67c-7.37 4.67-10.27-.57-10.03-.24M61.57 73.63V21.39" /><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M96.4 41.88c0 4.09-4.62 7.41-10.32 7.41s-10.32-3.32-10.32-7.41 10.32-21.06 10.32-21.06S96.4 37.78 96.4 41.88ZM76.75 40.16h18.67" /></g><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M47.38 53.38c0 4.09-4.62 7.41-10.32 7.41s-10.32-3.32-10.32-7.41 10.32-21.06 10.32-21.06 10.32 16.96 10.32 21.06ZM27.72 51.65H46.4" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M62.57 13.13c0 .55-.44.99-.99.99s-1-.44-1-.99.45-1 1-1 .99.44.99 1Z" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M114.93 88.86H12.28V1.8M.69 24.66h11.49M.69 50.73h11.49M.69 76.79h11.49M24.4 100.58V89.09M61.57 100.58V89.09M98.75 100.58V89.09" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0053);
