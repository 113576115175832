import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
} from '../../../base';
import { ContentBlockTheme } from '../../x-service';
import { ArticleBlockType } from './Enum';

export const ArticleBlockExecutiveBoardModelSchema =
  KittedVersionedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(ContentBlockTheme),
      type: z.literal(ArticleBlockType.ExecutiveBoard),
    })
  );

export const ArticleBlockExecutiveBoardSaveModelSchema =
  ArticleBlockExecutiveBoardModelSchema.merge(KittedSaveModelBaseSchema);

export type ArticleBlockExecutiveBoardModel = z.infer<
  typeof ArticleBlockExecutiveBoardModelSchema
>;
export type ArticleBlockExecutiveBoardSaveModel = z.infer<
  typeof ArticleBlockExecutiveBoardSaveModelSchema
>;
