import {
  CardType,
  KitCardModel,
  KitModel,
  KittedModelTypeEnum,
  KittedVersionStatusTypeEnum,
  PublishType,
} from '../../../../models';

export const convertKitModelToKitCardModel = (
  id: KitModel['id'],
  kit: Omit<KitModel, 'id'>,
  oldCard: Partial<KitCardModel> = {}
): KitCardModel => ({
  ...oldCard,

  id,
  slug: kit.slug,

  kittedModelType: KittedModelTypeEnum.CardKit,

  cardType: CardType.Kit,
  createdAt: oldCard?.createdAt || kit.createdAt,
  updatedAt: kit.updatedAt,

  relatedCards: oldCard?.relatedCards || [],

  title: kit.title,
  description: kit.description,
  keyImageImageAsset: kit.keyImageImageAsset,

  createdByAuthorId: kit.createdByAuthorId,

  excludeFromDiscovery:
    oldCard?.excludeFromDiscovery ??
    kit.cmsSrcMessage?.excludeFromDiscovery ??
    true,
  live: oldCard?.live ?? kit.cmsSrcMessage?.live ?? true,

  publishSettings: {
    publishType: PublishType.Private,
  },
  versionStatus: kit.versionStatus,
  publishedAt:
    kit.versionStatus === KittedVersionStatusTypeEnum.Published
      ? kit.updatedAt
      : undefined,
});
