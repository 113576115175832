/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import { ExternalLinkProps } from './types';

const ExternalLink = ({
  children,
  type,
  href,
  ...props
}: ExternalLinkProps) => (
  <a {...props} href={href.toLowerCase()}>
    {children}
  </a>
);

export default memo(ExternalLink);
