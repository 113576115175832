import { z } from 'zod';

import { DateISOSchema } from '../../../../../Date';
import { RvidSlugSchema } from '../../../../slug-service';
import {
  CMSResourceBaseLoadedModelSchema,
  CMSResourceBaseUnloadedModelSchema,
} from '../CMSResourceBaseModel';

export const CMSVideoResourceBaseModelSchema = z.object({
  slug: RvidSlugSchema,
  videoUrl: z.string().url(),
  description: z.string().optional(),

  createdAt: DateISOSchema,
  updatedAt: DateISOSchema,
});

export const CMSVideoResourceLoadedModelSchema =
  CMSResourceBaseLoadedModelSchema.merge(CMSVideoResourceBaseModelSchema);
export const CMSVideoResourceUnloadedModelSchema =
  CMSResourceBaseUnloadedModelSchema.merge(CMSVideoResourceBaseModelSchema);

export type CMSVideoResourceLoadedModel = z.infer<
  typeof CMSVideoResourceLoadedModelSchema
>;
export type CMSVideoResourceUnloadedModel = z.infer<
  typeof CMSVideoResourceUnloadedModelSchema
>;

export type CMSVideoResourceModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? CMSVideoResourceLoadedModel
    : CMSVideoResourceUnloadedModel;
