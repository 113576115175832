import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0021: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 156 102"
		height={102}
		width={156}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M83.96 100.69V82.2h1.27c2.63 0 4.78-2.15 4.78-4.78V59.65c0-2.63-2.15-4.78-4.78-4.78H70.77c-2.63 0-4.78 2.15-4.78 4.78v17.77c0 2.63 2.15 4.78 4.78 4.78h1.27v18.49M85.52 40.22c0 4.15-3.36 7.51-7.52 7.51s-7.51-3.36-7.51-7.51 3.36-7.51 7.51-7.51 7.52 3.36 7.52 7.51Z" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.02 100.69c14.75 0 24.27-.46 32.1-6.8 7.75-6.28 13.82-18.16 21.65-42.4l.93-2.89C66.17 16.13 70.95 1.31 78 1.31s11.83 14.82 22.3 47.3l.93 2.89c7.82 24.24 13.89 36.13 21.65 42.4 7.83 6.34 17.35 6.8 32.1 6.8" />
    </g>
  </CardIcon>
);

export default memo(Fr0021);
