import { z } from 'zod';

import { InviteModelSchema } from '../InviteModel';

export const InviteResponseSchema = InviteModelSchema.omit({
  userId: true,
}).merge(
  z.object({
    remainingInvites: z.number(),
    invitee: z.string(),
  })
);

export type InviteResponse = z.infer<typeof InviteResponseSchema>;
