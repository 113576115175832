import { z } from 'zod';

import {
  ContentBlockCardGridSaveModelSchema,
  ContentBlockCarouselSaveModelSchema,
  ContentBlockImageSaveModelSchema,
  ContentBlockVideoSaveModelSchema,
} from '../../x-service';

export const AnyPageBlockSaveModelSchema = z.union([
  ContentBlockCarouselSaveModelSchema,
  ContentBlockImageSaveModelSchema,
  ContentBlockVideoSaveModelSchema,
  ContentBlockCardGridSaveModelSchema,
]);

export type AnyPageBlockSaveModel = z.infer<typeof AnyPageBlockSaveModelSchema>;
