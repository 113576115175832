import { z } from 'zod';

import { KittedModelBaseSchema } from '../../../base';
import { CustomerModelSchema } from '../CustomerModel/CustomerModel';
//  import { OrderStatus } from './Enum';

export const ProductModelSchema = KittedModelBaseSchema.merge(
  z.object({
    customerId: CustomerModelSchema.shape.id,
    // type: z.nativeEnum(OrderStatus),

    title: z.string(),
    productSku: z.string(),

    pricePaid: z.number().positive().optional(),

    bundledProductIds: z.array(z.string()),
  })
);

export type ProductModel = z.infer<typeof ProductModelSchema>;
