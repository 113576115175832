import { memo } from 'react';
import clsx from 'clsx';

import Divider from '../../Divider';
import HTMLDecode from '../../HTMLDecode';
import CardBody from '../CardBody';
import CardTitle from '../CardTitle';
import useCardContentsForHoverableCard from './hooks/useCardContentsForHoverableCard';
import useStyles from './styles';
import { CardContentsForHoverableCardProps } from './types';

const CardContentsForHoverableCard = ({
  className,
  body,
  isActive,
  isFeatured,
  isMenuOpen,
  title,
  type,
}: CardContentsForHoverableCardProps) => {
  const { style } = useCardContentsForHoverableCard(type, isFeatured);
  const styles = useStyles();

  return (
    <>
      <div
        className={clsx(
          className,
          styles.content,
          isActive && styles.contentActive,
          isMenuOpen && styles.contentMenuOpen
        )}
        style={style}
      >
        <CardTitle className={styles.title} type={type}>
          {title}
        </CardTitle>
        <div className={styles.menuSpacing} />
        {body && (
          <div className={styles.cardBodyContainer}>
            <CardBody
              className={styles.cardBody}
              isHover={!isFeatured}
              type={type}
            >
              <HTMLDecode content={body} />
            </CardBody>
          </div>
        )}
      </div>
      {!isFeatured && (
        <Divider
          className={clsx(className, styles.divider)}
          spacing="sm"
          theme="secondary"
        />
      )}
    </>
  );
};

export default memo(CardContentsForHoverableCard);
