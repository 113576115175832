import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
} from '../../../../base';
import {
  ContentBlockTextAlignment,
  ContentBlockTheme,
  ContentBlockType,
} from './Enum';

export const ContentBlockTextModelSchema = KittedVersionedModelBaseSchema.merge(
  z.object({
    theme: z.nativeEnum(ContentBlockTheme),
    content: z.string(),
    type: z.literal(ContentBlockType.Text),
    alignment: z.nativeEnum(ContentBlockTextAlignment),
  })
);

export const ContentBlockTextSaveModelSchema =
  ContentBlockTextModelSchema.merge(KittedSaveModelBaseSchema);

export type ContentBlockTextSaveModel = z.infer<
  typeof ContentBlockTextSaveModelSchema
>;

export type ContentBlockTextModel = z.infer<typeof ContentBlockTextModelSchema>;
