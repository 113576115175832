import { z } from 'zod';

import {
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../../../base';
import {
  AnyEmailBlockLoadedModelSchema,
  AnyEmailBlockUnloadedModelSchema,
} from './EmailBlocks/AnyEmailBlockModel';
import { EmailPanelTheme } from './Enum/EmailPanelTheme';

export const EmailPanelLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    theme: z.nativeEnum(EmailPanelTheme),
    content: makeKittedModelReferenceSchema(
      AnyEmailBlockLoadedModelSchema,
      true
    ).array(),
  })
);

export const EmailPanelUnloadedModelSchema = EmailPanelLoadedModelSchema.merge(
  z.object({
    content: makeKittedModelReferenceSchema(
      AnyEmailBlockUnloadedModelSchema,
      false
    ).array(),
  })
);

export type EmailPanelLoadedModel = z.infer<typeof EmailPanelLoadedModelSchema>;
export type EmailPanelUnloadedModel = z.infer<
  typeof EmailPanelUnloadedModelSchema
>;

export type EmailPanelModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? EmailPanelLoadedModel : EmailPanelUnloadedModel;
