import { z } from 'zod';

import { DateISOSchema } from '../Date';
import { KittedVersionStatusTypeEnum } from './enum';
import { KittedModelBaseSchema } from './KittedModelBase';

export const KittedVersionedModelBaseSchema = KittedModelBaseSchema.merge(
  z.object({
    version: z.number().int(),
    versionStatus: z.nativeEnum(KittedVersionStatusTypeEnum),

    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,
  })
);

export type KittedVersionedModelBase = z.infer<
  typeof KittedVersionedModelBaseSchema
>;
