import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0013: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 120 102.54"
		height={102}
		width={120}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M47.25 42.09c0-5.89 4.77-10.66 10.66-10.66s10.66 4.77 10.66 10.66c0 9.06-10.66 8.11-10.66 19.68M59.16 70.46a1.24 1.24 0 1 1-2.48 0 1.24 1.24 0 0 1 2.48 0Z" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M60 17.95 89.38.38l-.53 34.23 29.92 16.66-29.92 16.66.53 34.23L60 84.58l-29.38 17.58.53-34.23L1.23 51.27l29.92-16.66L30.62.38 60 17.95z" />
    </g>
  </CardIcon>
);

export default memo(Ac0013);
