import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0032: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 92 112"
		height={112}
		width={92}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m1.18 82.15 10.44 28.97L27.32 68h63.5" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M69.12 12.51c0 6.42-5.21 11.63-11.63 11.63s-11.63-5.21-11.63-11.63S51.07.88 57.49.88s11.63 5.21 11.63 11.63ZM75.96 58.73l-.03-14.77c0-5.03-4.12-9.13-9.15-9.13l-18.67.02c-5.03 0-9.13 4.12-9.13 9.15l.03 14.77" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M43.79 83.09 71.2 110.5M71.2 83.09 43.79 110.5" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0032);
