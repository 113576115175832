import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0056: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 120 122"
		height={122}
		width={120}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M10.19 60.96h50.13M55.83 120.1c-5.6-4.68-61.44-54.18.44-118.31" /><path vectorEffect="non-scaling-stroke" d="M23.15 27.42c9.64 4.67 22.16 8.66 36.83 8.62M15.34 98.7c10.18-6.2 25.61-12.92 44.63-12.88" /><path vectorEffect="non-scaling-stroke" d="M60.32 120.43C27.5 120.43.9 93.82.9 61S27.5 1.57 60.32 1.57v118.86Z" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M117.06 46.13c-7.18 12.62-13.18 26.62-22.58 38.52-1.19-5.9 2.06-2.84 0 0-1.6-7.9-3.6-16.9-4.6-24.9-4 0-9 1-12-3-2-3-3-7-1-11 5-7 14-4 21-6 2-5 5.39-9.96 6.91-12.99-8.91-1.01-17.91 3.99-25.91-.01-3-2 0-7 1-10 2-3 3.03-6.52 4.5-10.01 19.15 8.71 32.56 27.48 34.48 48.44 1.83 19.89-6.69 39.72-22.39 52.06a59.284 59.284 0 0 1-30.27 12.31" />
    </g>
  </CardIcon>
);

export default memo(In0056);
